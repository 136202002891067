import { Company } from "./types/Company";
import { NotificationType, NotificationsData } from "./types/Notifications";
import { TypeUserData } from "./types/TypeUsers";
import { Dispatch, SetStateAction, createContext } from "react";

export type TypeContextProvider = {
  isAuth: boolean;
  setIsAuth: Dispatch<SetStateAction<boolean>>;
  userData: TypeUserData | null | undefined;
  setUserData: Dispatch<SetStateAction<TypeUserData | null | undefined>>;
  isSetupProfileInfo: boolean;
  setIsSetupProfileInfo: Dispatch<SetStateAction<boolean>>;
  isSpecialist: boolean;
  setIsSpecialist: Dispatch<SetStateAction<boolean>>;
  notifiesData: NotificationsData[];
  setNotifiesData: Dispatch<SetStateAction<NotificationsData[]>>;
  userCompany: Company[] | null;
  setSelectLang: Dispatch<SetStateAction<string | null>>;
  selectLang: string | null;
};

export const ContextProvider = createContext<TypeContextProvider>(
  {} as TypeContextProvider
);
