import React, { Dispatch, SetStateAction } from "react";
import s from "../CreatingProfile.module.css";
import DropdownSelect from "../../../../components/DropdownSelect/DropdownSelect";
import RadioButtonsGroup from "../../../../components/RadioGroup/RadioGroup";
import {
  daysList,
  generateNumbersArray,
  yearsList,
} from "../../../../utils/setupProfileInfo";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import CityPicker, {
  PlaceType,
} from "../../../../components/CitySelect/CitySelect";
import ReactFlagsSelect from "react-flags-select";
import { popularLanguages } from "../../../../constans/languagesList";
import strings from "../../../../localization";
import Input from "../../../../components/Input/Input";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type TextInputValues = {
  name: string;
  surname: string;
  practics: string;
  experience: string;
};

type SubStep1Props = {
  textInputValues: TextInputValues;
  setTextInputValues: Dispatch<SetStateAction<TextInputValues>>;
  dateOfBith: { day: number; month: number; year: number };
  setDateOfBith: Dispatch<
    SetStateAction<{ day: number; month: number; year: number }>
  >;
  genderValue: "male" | "female" | string;
  setGenderValue: Dispatch<SetStateAction<"male" | "female" | string>>;
  selectedLangs: string | string[];
  setSelectedLangs: Dispatch<SetStateAction<string | string[]>>;
  selectedCountry: { code: string; title: string };
  setSelectedCountry: Dispatch<SetStateAction<{ code: string; title: string }>>;
  selectedCity: PlaceType | null;
  setSelectedCity: Dispatch<SetStateAction<PlaceType | null>>;
};

const SubStep1 = ({
  textInputValues,
  setDateOfBith,
  dateOfBith,
  setTextInputValues,
  genderValue,
  setGenderValue,
  setSelectedLangs,
  selectedCountry,
  setSelectedCountry,
  selectedLangs,
  setSelectedCity,
  selectedCity,
}: SubStep1Props) => {
  const dateBirthUpdate = (
    value: string,
    objKey: keyof { day: number; month: number; year: number }
  ) => {
    setDateOfBith((prev) => ({ ...prev, [objKey]: Number(value) }));
  };

  const monthNames = [
    strings.jan,
    strings.feb,
    strings.mar,
    strings.apr,
    strings.may,
    strings.jun,
    strings.jul,
    strings.aug,
    strings.sep,
    strings.oct,
    strings.nov,
    strings.dec,
  ];
  const monthList = generateNumbersArray(1, 12).map((item, index) => {
    return {
      value: item,
      lable: monthNames[index],
    };
  });
  const languagesList = popularLanguages.map((item) => ({
    value: item,
    lable: item,
  }));
  const handleSelectLang = (value: string | string[]) => {
    setSelectedLangs(value);
  };

  const denderRadioFroupData = [
    {
      value: "male",
      lable: strings.genderLableMale,
    },
    {
      value: "female",
      lable: strings.genderLableFemale,
    },
  ];
  return (
    <div className={s.subStepBlock}>
      <div className={s.substepContent1}>
        <p className={s.lableTitle}>{strings.setupNameLable}</p>
        <Input
          inputValue={textInputValues.name}
          onChangeInput={(value) => {
            setTextInputValues((prev) => ({ ...prev, name: value }));
          }}
          isVisible
          required
          padding="18px 20px"
          fontSize="16px"
          margin="0 0 20px 0"
        />

        <p className={s.lableTitle}>{strings.setupSurnameLable}</p>
        <Input
          inputValue={textInputValues.surname}
          onChangeInput={(value) => {
            setTextInputValues((prev) => ({
              ...prev,
              surname: value,
            }));
          }}
          isVisible
          required
          padding="18px 20px"
          fontSize="16px"
          margin="0 0 20px 0"
        />
        <p className={s.lableTitle} style={{ marginBottom: "20px" }}>
          {strings.setupDateOfBirthLable}
        </p>
        <div className={s.dateOfBirthBlock}>
          <DropdownSelect
            data={daysList}
            value={dateOfBith.day.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.setupDayLable}
            width="30%"
            objKey="day"
          />
          <DropdownSelect
            data={monthList}
            value={dateOfBith.month.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.setupmonthLable}
            width="30%"
            objKey="month"
          />
          <DropdownSelect
            data={yearsList}
            value={dateOfBith.year.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.setupYearLable}
            width="30%"
            objKey="year"
          />
        </div>
        <div className={s.countryLangSelectBlock}>
          <p className={s.lableTitle} style={{ marginBottom: "20px" }}>
            {strings.setupChooseContryLable}
          </p>

          <div className={s.countyBlock}>
            <ReactFlagsSelect
              blacklistCountries
              countries={["RU"]}
              className={s.countrySelect}
              selected={selectedCountry.code}
              onSelect={(code) =>
                setSelectedCountry({
                  code: code,
                  title: countries.getName(code, "en") as string,
                })
              }
              placeholder={strings.setupCountryPlaceholder}
              searchable
            />

            <div className={s.cityPickerBlock}>
              <CityPicker
                selectedCity={selectedCity}
                selectedCountry={selectedCountry.code}
                onCitySelect={setSelectedCity}
                placeHolder={strings.setupCityPlaceHolder}
              />
            </div>
            <div className={s.languageBlock}>
              <MultipleSelect
                data={languagesList}
                setValue={handleSelectLang}
                value={selectedLangs}
                multiplie={true}
                lable={strings.setupLanguagePlaceHodler}
              />
            </div>
          </div>
        </div>
        <div className={s.experienceBlock}>
          <div className={s.experienceElement}>
            <p className={s.lableTitle}>{strings.setupPracticalHours}</p>
            <Input
              inputValue={textInputValues.practics}
              onChangeInput={(value) => {
                setTextInputValues((prev) => ({
                  ...prev,
                  practics: value,
                }));
              }}
              isVisible
              required
              padding="18px 20px"
              fontSize="16px"
              margin="0 0 10px 0"
              type="number"
            />

            <p className={s.lableTitle}>{strings.setupExample1}</p>
          </div>
          <div className={s.experienceElement}>
            <p className={s.lableTitle}>{strings.setupExperienceTitle}</p>
            <Input
              inputValue={textInputValues.experience}
              onChangeInput={(value) => {
                setTextInputValues((prev) => ({
                  ...prev,
                  experience: value,
                }));
              }}
              isVisible
              required
              padding="18px 20px"
              fontSize="16px"
              margin="0 0 10px 0"
              type="number"
            />

            <p className={s.lableTitle}>{strings.setupExample2}</p>
          </div>
        </div>
        <div className={s.genderBlock}>
          <p className={s.lableTitle}>{strings.whatGender}</p>
          <RadioButtonsGroup
            data={denderRadioFroupData}
            defaultValue="male"
            value={genderValue}
            setValue={setGenderValue}
          />
        </div>
      </div>
    </div>
  );
};

export default SubStep1;
