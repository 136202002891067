import React, { useState } from "react";
import s from "./StandartButton.module.css";
import { Tooltip } from "@mui/material";

type TypeStandartButton = {
  buttonTitle: string;
  action: () => void;
  width?: string;
  fontSize?: string;
  height?: string;
  bgColor?: string;
  borderColor?: string;
  userButton?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
};

const StandartButton = ({
  buttonTitle,
  action,
  width,
  fontSize,
  height,
  bgColor,
  borderColor,
  userButton,
  disabled,
  tooltipTitle,
}: TypeStandartButton) => {
  const [isFocused, setFocus] = useState(false);

  return (
    <Tooltip title={disabled ? tooltipTitle : ""} placement="right" arrow>
      <button
        onClick={action}
        className={
          disabled
            ? !userButton
              ? s.btnDisabled
              : s.userBtnDisabled
            : !userButton
            ? s.btn
            : s.userBtn
        }
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        disabled={disabled ? true : false}
        style={{
          border: "none",
          backgroundColor: bgColor ? bgColor : "var(--primary-color)",
          height: height ? height : "65px",
          width: width ? width : "auto",
          fontSize: fontSize ? fontSize : "16px",
        }}
      >
        {buttonTitle}
      </button>
    </Tooltip>
  );
};

export default StandartButton;
