import React, { ReactNode, useState } from "react";
import s from "./StandartBlock.module.css";
import ChevronUpIcon from "../../../assets/HomePage/chevron-up.svg";
import CloseIcon from "../../../assets/HomePage/close.svg";
import AnimateHeight from "react-animate-height";

type StandartBlockProps = {
  title: string;
  children: ReactNode;
};

const StandartBlock = ({ title, children }: StandartBlockProps) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={`${s.container} ${collapsed ? s.containerHoddien : ""}`}>
      <div className={s.blockHeader}>
        <span className={s.title}>{title}</span>
        <div className={s.buttonBlock}>
          <img
            className={collapsed ? s.chevronDown : s.chevronUp}
            src={ChevronUpIcon}
            alt=""
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      </div>

      <AnimateHeight height={collapsed ? 0 : "100%"} className={s.animHeight}>
        <div className={s.contentBlock}>{children}</div>
      </AnimateHeight>
    </div>
  );
};

export default StandartBlock;
