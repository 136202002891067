import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextProvider } from "../../contextProvider";
import UsersApi from "../../api/usersApi";
import Loader from "../Loader/Loader";

type NavigateToProfileProps = {
  children: ReactNode;
  userId: string;
};

const NavigateToProfile = ({ children, userId }: NavigateToProfileProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [username, setUsername] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!token) return;
    const makeAsync = async () => {
      setUsername(null);
      const userDataResponse = await UsersApi.getUserAvatarAndUsername(
        token,
        userId
      );

      if (userDataResponse.username) {
        setUsername(userDataResponse.username);
      }
    };
    makeAsync();
  }, [token, userId]);

  const navigateToUserPage = () => {
    if (!username) return;
    window.open(`/${username}`, "_blank");
  };

  return <div onClick={navigateToUserPage}>{children}</div>;
};

export default NavigateToProfile;
