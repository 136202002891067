import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./SpecialistCard.module.css";
import UsersApi from "../../api/usersApi";
import StandartButton from "../StandartButton/StandartButton";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import ModalWindow from "../ModalWindow/ModalWindow";
import SelectSessionDate from "../SelectSessionDate/SelectSessionDate";
import { OverLaps } from "../../types/TypeSpecialist";
import { ContextProvider } from "../../contextProvider";
import SessionsApi from "../../api/sessionApi";
import { createChat } from "../../utils/general";
import Notify, { SuccesNotify } from "../../utils/toaster";
import SpecialistApi from "../../api/specialistApi";
import RealTimeSessionModalContent from "../RealTimeSessionModalContent/RealTimeSessionModalContent";
import strings from "../../localization";
import { ReactComponent as TokenIcon } from "../../assets/Header/heart-tick.svg";
import { useNavigate } from "react-router-dom";

type SpecialistCardProps = {
  userId: string;
  fullName: string;
  rating: number | undefined;
  yearExp: number;
  mainSpeciality: string;
  languages: string[];
  location: string;
  price: string | number;
  discountPrice?: number;
  overlapDays: OverLaps[];
  isRecommendetionCard?: boolean;
  realTimeSessions: boolean;
  setUpdateOverlaps?: Dispatch<SetStateAction<number>>;
  isRecommendationCard?: boolean;
  avatarHeight?: string;
  avatarBlockBorderRadius?: string;
  mainInfoBorderRadius?: string;
  projectId?: string;
};

const SpecialistCard = ({
  userId,
  fullName,
  rating,
  yearExp,
  mainSpeciality,
  languages,
  location,
  price,
  overlapDays,
  isRecommendetionCard,
  realTimeSessions,
  setUpdateOverlaps,
  discountPrice,
  isRecommendationCard,
  avatarHeight,
  avatarBlockBorderRadius,
  mainInfoBorderRadius,
  projectId,
}: SpecialistCardProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const [avatar, setAvatar] = useState("");
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  const [overlapTime, setOverlapTime] = useState<{ start: Date; end: Date }[]>([
    { start: new Date(), end: new Date() },
  ]);
  const [isLoader, setIsLoader] = useState(false);
  const [realTimeSessionModal, setRealTimeSessionModal] = useState(false);
  const [realTimeError, setRealTimeError] = useState("");

  useEffect(() => {
    const allOverlaps: { start: Date; end: Date }[] = overlapDays.flatMap(
      (overlap) =>
        overlap.overlaps.flatMap((dayOverlap) => dayOverlap.overlapTime)
    );
    setOverlapTime(allOverlaps);
  }, [overlapDays]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userId) {
        const responseAvatar = await UsersApi.getUserAvatarAndUsername(
          token,
          userId
        );

        if (responseAvatar.avatar) setAvatar(responseAvatar.avatar);
      }
    };
    makeAsync();
  }, [userId]);

  const bookingHandler = async (
    sessionBookingData: { date: Date | string; duration: number }[]
  ) => {
    if (
      token &&
      userData &&
      userData._id &&
      userId &&
      sessionBookingData.length
    ) {
      const response = await SessionsApi.bookSession(
        {
          datesAndTime: sessionBookingData,
          specialistUserId: userId,
          userId: userData._id,
          projectId: projectId,
        },
        token
      );

      if (!response.status && !response.notEnoughBalance) {
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (!response.status && response.notEnoughBalance && response.message) {
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (response.status) {
        createChat(userData, userId);
        setIsLoader(true);
        setBookingModalIsOpen(false);
        setUpdateOverlaps && setUpdateOverlaps((prev) => prev + 1);
        SuccesNotify(strings.bookingSucces);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
    }
    return { status: false, notEnoughBalance: false };
  };

  const sendRealTimeSessionRequest = async (
    sessionSubject: string,
    setSubjectValue: Dispatch<SetStateAction<string>>
  ) => {
    if (!token || !userData) return { status: false };
    const payload = {
      specialistUserId: userId,
      userId: userData?._id,
      subject: sessionSubject,
    };
    const response = await SpecialistApi.sendRealTimeSessionRequest(
      token,
      payload
    );

    if (!response.status && !response.notEnoughBalance) {
      setRealTimeError(response.message!);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    if (!response.status && response.notEnoughBalance && response.message) {
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }

    if (response.status) {
      navigate("/global-chat", {
        state: {
          userId: userId,
        },
      });
      setSubjectValue("");
      setRealTimeError("");
      setRealTimeSessionModal(false);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    return {
      status: response.status,
    };
  };

  return (
    <div
      className={window.innerWidth > 600 ? s.container : s.mobileContainer}
      style={{ flexDirection: isRecommendationCard ? "row" : "column" }}
    >
      <NavigateToProfile userId={userId}>
        <div
          className={s.avatarBlock}
          style={{ borderRadius: avatarBlockBorderRadius ?? "20px 20px 0 0" }}
        >
          {!avatar || avatar === "" ? (
            <div
              className={s.noAvatarBlock}
              style={{ width: isRecommendationCard ? "200px" : "100%" }}
            >
              {fullName.split(" ")[0][0]}
              {fullName.split(" ")[1][0]}
            </div>
          ) : (
            <>
              <div className={s.blurAvatar}>
                <img
                  src={avatar}
                  alt=""
                  className={s.avatarBlur}
                  height={avatarHeight ?? "250px"}
                  width={isRecommendationCard ? "200px" : "100%"}
                />
              </div>
              <img src={avatar} alt="" className={s.avatar} />
            </>
          )}
        </div>
      </NavigateToProfile>
      <div
        className={
          window.innerWidth > 600 ? s.mainInfoBlock : s.mobileMainInfoBlock
        }
        style={{ borderRadius: mainInfoBorderRadius ?? "0 0 20px 20px" }}
      >
        <div className={s.locationRatingBlock}>
          <span className={s.locationValue}>{location}</span>
          <span className={s.ratingValue}>
            {strings.ratingSpecProfile + " " + rating?.toFixed(1)}
          </span>
        </div>
        <div className={s.fullNameValue}>{fullName}</div>
        <div className={s.specializationValue}>{mainSpeciality}</div>
        <div className={s.professionalInfoBlock}>
          <span className={s.expTitle}>
            <strong>{yearExp}+</strong> {strings.specCardExp}
          </span>
          {languages.map((item, index) => (
            <span className={s.langValue} key={index}>
              {item + " "}
            </span>
          ))}
        </div>
        <div className={s.priceActionBlock}>
          <div className={s.priceBlock}>
            {discountPrice ? (
              <div>
                <span className={s.priceValue}>
                  {discountPrice}
                  <TokenIcon className={s.tokenIcon} />
                  {"/" + strings.hr}
                </span>
                <span className={s.oldPrice}>{price}</span>
              </div>
            ) : (
              <span className={s.priceValue}>
                {price}
                <TokenIcon className={s.tokenIcon} />
                {"/" + strings.hr}
              </span>
            )}
          </div>
          <div className={s.actionBlock}>
            {realTimeSessions ? (
              <StandartButton
                action={() => setRealTimeSessionModal(true)}
                buttonTitle={strings.specCardStratNowBtn}
                fontSize="14px"
                height="35px"
                width="120px"
              />
            ) : (
              <span></span>
            )}
            <StandartButton
              action={() => setBookingModalIsOpen(true)}
              buttonTitle={strings.specCardBookingBtn}
              fontSize="14px"
              height="35px"
              width="120px"
            />
          </div>
        </div>
      </div>
      <ModalWindow
        isOpen={realTimeSessionModal}
        setIsOpen={setRealTimeSessionModal}
        width="fit-content"
        padding="20px"
      >
        <RealTimeSessionModalContent
          price={price}
          sendRequestHandler={sendRealTimeSessionRequest}
          fullName={fullName}
          realTimeError={realTimeError}
          specialistUserId={userId}
          setRealTimeSessionModal={setRealTimeSessionModal}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={bookingModalIsOpen}
        setIsOpen={setBookingModalIsOpen}
        width="fit-content"
        padding="10px"
      >
        <SelectSessionDate
          avatar={avatar}
          fullname={fullName}
          specialization={mainSpeciality}
          showDateInputs={false}
          bookingHandler={bookingHandler}
          overlapTime={overlapTime}
          price={Number(price) * 100}
          userId={userId}
          setBookingModal={setBookingModalIsOpen}
        />
      </ModalWindow>
    </div>
  );
};
export default SpecialistCard;
