import { TypeUserData } from "./TypeUsers";

export type UploadIntroVideoPayload = {
  file: FormData;
  specialistId: string;
};
export type UploadCertificatesPayload = {
  files: FormData;
  specialistId: string;
};
export type SubSpecializations = {
  _id: string;
  specialization: string;
  relatedMainSpec: MainSpecializations[];
  labels: { text: string; language: string }[];
};

export type MainSpecializations = {
  _id: string;
  specialization: string;
  labels: { text: string; language: string }[];
};

type VideoMetadata = {
  _id: string;
  length: number;
  chunkSize: number;
  uploadDate: string;
  filename: string;
};

export type SpecialistData = {
  _id: string;
  mainSpecializations: MainSpecializations[];
  subSpecializations: SubSpecializations[];
  receiveRealTimeOffers: boolean;
  practiceHours?: number;
  yearsOfExperience?: number;
  introductionVideo?: VideoMetadata;
  certificates?: string[];
  aboutMyself?: string;
  aboutTherapy?: string;
  briefDescription?: string;
  price?: number;
  clients?: string[];
  totalRating?: number;
  rating: number;
  isVerified: boolean;
  isVerifiedChanges: boolean;
  discountPrice?: number;
  tags: SpecialistTag[];
};
export type OverLaps = {
  specialist: string;
  overlaps: {
    day: string;
    overlapTime: {
      start: Date;
      end: Date;
    }[];
  }[];
};
export type Certificates = {
  _id: string;
  filename: string;
  size: number;
  type: string;
  link: string;
};

type Range = {
  min: number;
  max: number;
};
export type ParamsAvailable = {
  genders: string[];
  locations: string[];
  totalAmount: number;
  preferredLanguages: string[];
  mainSpecializations: string[];
  subSpecializations: string[];
  priceRange: Range;
  ageRange: Range;
  practiceHoursRange: Range;
  yearsOfExperienceRange: Range;
};

export type TypeSpecialistFilter = {
  gender?: string | string[];
  age?: { from: number; to: number };
  preferredLanguages?: string | string[];
  specialistId?: {
    subSpecializations?: string | string[];
    mainSpecializations?: string | string[];
    practiceHours?: { from: number; to: number };
    yearsOfExperience?: { from: number; to: number };
    price?: { from: number; to: number };
  };
  page?: number;
  limit?: number;
};

export interface TimeRange {
  start: Date;
  end: Date;
}

export type SpecialistFreeTimeType = {
  specialist: string;
  overlaps: { day: string; overlapTime: TimeRange[] }[];
};

export type RealTimeBooking = {
  from: TypeUserData;
  for: TypeUserData;
  isAccepted: boolean;
};
export type Specializations = {
  specialization: string;
  labels: { text: string; language: string }[];
};

export enum SpecialistTagsDirections {
  COACHING = "Coaching",
}

export type SpecialistTag = {
  _id: string;
  title: string;
  labels: { text: string; language: string }[];
  parentTag?: string;
  level: number;
  direction: SpecialistTagsDirections;
};
