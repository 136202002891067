import React from "react";
import s from "./Input.module.css";

type AuthInputProps = {
  inputValue: string;
  onChangeInput: (value: string) => void;
  placeholder?: string;
  isVisible: boolean;
  required: boolean;
  lable?: string;
  bgColor?: string;
  padding?: string;
  fontSize?: string;
  margin?: string;
  type?: string;
  border?: string;
};

const Input = ({
  inputValue,
  onChangeInput,
  placeholder,
  isVisible,
  required,
  lable,
  bgColor,
  padding,
  fontSize,
  margin,
  type,
  border,
}: AuthInputProps) => {
  return (
    <div className={s.container}>
      <span className={s.lable}>{lable}</span>
      <input
        value={inputValue}
        type={isVisible ? type ?? "text" : "password"}
        onChange={(e) => onChangeInput(e.target.value)}
        className={s.input}
        placeholder={placeholder ? placeholder : ""}
        required={required}
        style={{
          letterSpacing: isVisible ? "1px" : "5px",
          backgroundColor: bgColor ?? "#f0f0f0",
          padding: padding ?? "14px 20px",
          fontSize: fontSize ?? "14px",
          margin: margin ?? "0",
          border: border ?? "none",
        }}
      />
    </div>
  );
};

export default Input;
