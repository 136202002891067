import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  GetPostFilesResposne,
  Post,
  PostComments,
  PostFileTypeToUpdate,
} from "../types/Posts";

const { posts } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "posts/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class PostApi {
  static async createPost(
    token: string,
    formData: FormData
  ): Promise<TypeDefaultResponse & { post?: Post }> {
    return await ky
      .post(`${baseURL}${posts.createPost}`, {
        headers: getAuthHeader(token),
        body: formData,
        timeout: 30000,
      })
      .then((res) => res.json());
  }

  static async deletePost(
    token: string,
    postId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${posts.deletePost}/${postId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async updatePost(
    token: string,
    updateData: Partial<
      Omit<
        Post,
        "createdBy" | "createdAt" | "updatedAt" | "images" | "videos" | "likes"
      >
    >
  ): Promise<TypeDefaultResponse & { post?: Post }> {
    return await ky
      .post(`${baseURL}${posts.updatePost}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async updatePostFiles(
    token: string,
    postId: string,
    formData: FormData
  ): Promise<TypeDefaultResponse & { post?: Post }> {
    return await ky
      .post(`${baseURL}${posts.updatePostFiles}/${postId}`, {
        headers: getAuthHeader(token),
        body: formData,
      })
      .then((res) => res.json());
  }

  static async removePostFile(
    token: string,
    postId: string,
    payload: {
      fileIds: string[];
      fileType: PostFileTypeToUpdate;
    }
  ): Promise<TypeDefaultResponse & { post?: Post }> {
    return await ky
      .post(`${baseURL}${posts.removePostFile}/${postId}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getUserPosts(
    token: string,
    userId: string,
    limit: number,
    page: number
  ): Promise<TypeDefaultResponse & { posts?: Post[] }> {
    return await ky
      .get(`${baseURL}${posts.getUserPosts}/${userId}/${limit}/${page}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getFollowingPosts(
    token: string,
    userId: string,
    limit: number,
    page: number
  ): Promise<TypeDefaultResponse & { posts?: Post[] }> {
    return await ky
      .get(`${baseURL}${posts.getFollowingPosts}/${userId}/${limit}/${page}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async likePost(
    token: string,
    payload: {
      userId: string;
      postId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${posts.likePost}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async unlikePost(
    token: string,
    payload: {
      userId: string;
      postId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${posts.unlikePost}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async commentPost(
    token: string,
    payload: {
      postId: string;
      comment: string;
    }
  ): Promise<TypeDefaultResponse & { comment: PostComments }> {
    return await ky
      .post(`${baseURL}${posts.commentPost}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async updateComment(
    token: string,
    payload: {
      commentId: string;
      newComment: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      comment?: PostComments;
    }
  > {
    return await ky
      .post(`${baseURL}${posts.updateComment}/${payload.commentId}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async removeComment(
    token: string,
    commentId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${posts.removeComment}/${commentId}`, {
        headers: getAuthHeader(token),
        json: { commentId },
      })
      .then((res) => res.json());
  }

  static async likeComment(
    token: string,
    payload: { userId: string; commentId: string }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${posts.likeComment}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }
  static async unlikeComment(
    token: string,
    payload: { userId: string; commentId: string }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${posts.unlikeComment}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getPostFiles(
    token: string,
    postId: string
  ): Promise<GetPostFilesResposne> {
    return await ky
      .get(`${baseURL}${posts.getPostFiles}/${postId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
