import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";

import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";
import {
  Certificates,
  MainSpecializations,
  OverLaps,
  ParamsAvailable,
  RealTimeBooking,
  SpecialistData,
  SpecialistFreeTimeType,
  SpecialistTag,
  SpecialistTagsDirections,
  SubSpecializations,
  TypeSpecialistFilter,
  UploadCertificatesPayload,
  UploadIntroVideoPayload,
} from "../types/TypeSpecialist";
import { Sessions, Specializations } from "../types/TypeSession";
import { TypeGoogleEvent } from "../types/General";

const { specialists } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "specialists/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SpecialistApi {
  static async update(
    updateData: TypeUpdatedUserInfo,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      specialistData: SpecialistData;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.update}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async uploadIntroVideo(
    specialistId: string,
    formData: FormData,
    token: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${specialists.uploadIntroVideo}/${specialistId}`, {
        body: formData,
        headers: getAuthHeader(token),
        timeout: 30000,
      })
      .then((res) => res.json());
  }

  static async uploadCertificates(
    specialistId: string,
    formData: FormData,
    token: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${specialists.uploadCertificates}/${specialistId}`, {
        body: formData,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async getRecommendedSpecialists(
    userId: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      overlapDays: OverLaps[];
      recommendations: TypeUserData[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getRecommendedSpecialists}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSpecialistIntroVideo(
    token: string,
    id: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .get(`${baseURL}${specialists.getSpecialistIntroVideo}/${id}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSpecialistCertificates(
    token: string,
    id: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: Certificates[];
      total: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getSpecialistCertificates}/${id}/${page}/${amount}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getSpecialistPublicCertificates(
    id: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: Certificates[];
      total: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getSpecialistPublicCertificates}/${id}/${page}/${amount}`
      )
      .then((res) => res.json());
  }

  static async getMainSpecializations(token: string): Promise<
    TypeDefaultResponse & {
      mainSpecializations?: MainSpecializations[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getMainSpecializations}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSubSpecializations(
    token: string,
    relatedMainSpec: string[]
  ): Promise<
    TypeDefaultResponse & {
      subSpecializations?: SubSpecializations[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getSubSpecializations}/${relatedMainSpec}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getFilteredSpecialists(
    token: string,
    payload: {
      userId: string;
      filters: TypeSpecialistFilter;
    }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
      users?: TypeUserData[];
      overlapDays: OverLaps[];
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.getFilteredSpecialists}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getFilterAvailableParams(
    token: string,
    payload: { filters: TypeSpecialistFilter }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.getFilterAvailableParams}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAvailableParams(
    token: string,
    payload: { param: string; filters: TypeSpecialistFilter }
  ): Promise<string[]> {
    return await ky
      .post(`${baseURL}getAvailableParams`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async removeCertificate(
    token: string,
    payload: {
      certificateId: string;
      specialistId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(
        `${baseURL}${specialists.removeCertificate}/${payload.specialistId}`,
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async getSpecialistFreeTime(
    token: string,
    specialistUserId: string
  ): Promise<TypeDefaultResponse & { overlapDays: SpecialistFreeTimeType[] }> {
    return await ky
      .get(
        `${baseURL}${specialists.getSpecialistFreeTime}/${specialistUserId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async sendRealTimeSessionRequest(
    token: string,
    payload: {
      specialistUserId: string;
      userId: string;
      subject: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      booking?: RealTimeBooking | null;
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
      notEnoughBalance: boolean;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.sendRealTimeSessionRequest}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async acceptRealTimeSessionRequest(
    token: string,
    userId: string,
    bookingId: string
  ): Promise<
    TypeDefaultResponse & {
      session?: Sessions | null;
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
      googleEventsData?: {
        userId: string;
        eventInfo: TypeGoogleEvent;
      }[];
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.acceptRealTimeSessionRequest}`, {
        headers: getAuthHeader(token),
        json: { userId, bookingId },
      })
      .then((res) => res.json());
  }

  static async cancelRealTimeSessionRequest(
    token: string,
    userId: string,
    bookingId: string,
    reason: string
  ): Promise<
    TypeDefaultResponse & {
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.cancelRealTimeSessionRequest}`, {
        headers: getAuthHeader(token),
        json: { userId, bookingId, reason },
      })
      .then((res) => res.json());
  }

  static async getAllSpecialzations(token: string): Promise<
    TypeDefaultResponse & {
      specializations?: Specializations[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getAllSpecialzations}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async getSpecialistTags(
    token: string,
    payload?: {
      parentTagsIds?: string[];
      direction?: SpecialistTagsDirections;
      level?: number;
    }
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await ky
      .post(`${baseURL}${specialists.getSpecialistTags}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async addTagsToSpecialist(
    token: string,
    userId: string,
    tagIds: string[]
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await ky
      .post(`${baseURL}${specialists.addTagsToSpecialist}/${userId}`, {
        headers: getAuthHeader(token),
        json: { userId, tagIds },
      })
      .then((res) => res.json());
  }

  static async removeTagsFromSpecialist(
    token: string,
    userId: string,
    tagIds: string[]
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await ky
      .post(`${baseURL}${specialists.removeTagsFromSpecialist}/${userId}`, {
        headers: getAuthHeader(token),
        json: { userId, tagIds },
      })
      .then((res) => res.json());
  }
}
