import React, { useContext, useEffect, useState } from "react";
import s from "./Packages.module.css";
import { ContextProvider } from "../../../../contextProvider";
import { SessionPackage } from "../../../../types/TypeSession";
import Loader from "../../../../components/Loader/Loader";
import strings from "../../../../localization";
import { TypeUserData } from "../../../../types/TypeUsers";
import SessionsApi from "../../../../api/sessionApi";
import Notify, { SuccesNotify } from "../../../../utils/toaster";
import PackageCard from "./PackageCard/PackageCard";

type PackagesProps = {
  profileUserData: TypeUserData | null | undefined;
};

const Packages = ({ profileUserData }: PackagesProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [packagesData, setPackagesData] = useState<{
    packagesData: SessionPackage[];
    overlaps: {
      day: string;
      overlapTime: {
        start: Date;
        end: Date;
      }[];
    }[];
  } | null>(null);
  const [actionLoaders, setActionLoaders] = useState({ mainLoader: true });

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !profileUserData) {
        return setActionLoaders((prev) => ({ ...prev, mainLoader: false }));
      }
      setActionLoaders((prev) => ({ ...prev, mainLoader: true }));
      const response = await SessionsApi.getSpecialistSessionPackages(
        token,
        profileUserData._id
      );

      if (response.status && response.packages && response.overlapDays) {
        const responseOverlaps = response.overlapDays[0].overlaps.map(
          (item) => ({ day: item.day, overlapTime: item.overlapTime })
        );
        setPackagesData({
          packagesData: response.packages,
          overlaps: responseOverlaps,
        });
      }
      setActionLoaders((prev) => ({ ...prev, mainLoader: false }));
      if (!response.status && response.message) {
        return Notify(response.message);
      }
    };
    makeAsync();
  }, [profileUserData]);

  if (actionLoaders.mainLoader) {
    return (
      <div className={s.laoderContainer}>
        <Loader size={100} />
      </div>
    );
  }
  if (
    !actionLoaders.mainLoader &&
    (!packagesData || !packagesData.packagesData.length)
  ) {
    return (
      <div className={s.laoderContainer}>
        <h2>{strings.noPackages}</h2>
      </div>
    );
  }
  return (
    <div className={s.container}>
      {packagesData &&
        packagesData.packagesData.length &&
        packagesData.packagesData.map((item, index) => (
          <PackageCard
            profileUserData={profileUserData}
            sessionPackage={item}
            overlaps={packagesData.overlaps}
            setPackagesData={setPackagesData}
            key={index}
          />
        ))}
    </div>
  );
};

export default Packages;
