import { configureStore } from "@reduxjs/toolkit";
import videoChatReducer from "./slices/videoChatSlice";
import userReducer from "./slices/userSlice";

const store = configureStore({
  reducer: {
    video: videoChatReducer,
    user: userReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
