import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import s from "./MuiCalendar.module.css";
import "./MuiCalendar.css";
import { ContextProvider } from "../../contextProvider";
import "dayjs/locale/en";
import "dayjs/locale/uk";

type MuiCalendarProps = {
  setSelectedDate: Dispatch<SetStateAction<Dayjs | null>>;
  isSelectedMode: boolean;
  selectedDate?: Dayjs | null;
  dateSlots?: string[];
  fontSize?: string;
  disableAutoSelectDay?: boolean;
};

function isDateInSlots(date: Dayjs, dateSlots?: string[]) {
  if (!dateSlots) return false;
  return dateSlots.some((slot) => dayjs(slot).isSame(date, "day"));
}

function ServerDay(
  props: PickersDayProps<Dayjs> & {
    highlightedDays?: number[];
    onDateClick?: (date: Dayjs) => void;
    selectedDay?: Dayjs;
    isSelectedMode: boolean;
    dateSlots?: string[];
  }
) {
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    onDateClick,
    selectedDay,
    isSelectedMode,
    dateSlots,
    ...other
  } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  const isDaySelected = selectedDay?.isSame(day, "day");

  const handleDateClick = () => {
    if (dateSlots) {
      if (onDateClick && isDateInSlots(day, dateSlots)) {
        onDateClick(day);
      }
    } else {
      if (!onDateClick) return;
      onDateClick(day);
    }
  };

  return (
    <Badge
      className={s.badgeContainer}
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isSelected ? <span className={s.freeDayMarker}></span> : undefined
      }
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        onClick={handleDateClick}
        className={
          isDaySelected && isSelectedMode ? s.selectedDay : s.notSelectedDay
        }
      />
    </Badge>
  );
}

export default function MuiCalendar({
  setSelectedDate,
  dateSlots,
  isSelectedMode,
  selectedDate,
  fontSize = "16px",
  disableAutoSelectDay,
}: MuiCalendarProps) {
  const { userData } = useContext(ContextProvider);
  const [highlightedDays, setHighlightedDays] = useState<
    Record<number, number[]>
  >({});
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(
    selectedDate ? selectedDate : null
  );

  useEffect(() => {
    const months: Record<number, number[]> = {};
    if (!dateSlots) return;
    // Group dates by month
    dateSlots.forEach((date) => {
      const month = dayjs(date).month();
      if (!months[month]) {
        months[month] = [];
      }
      months[month].push(dayjs(date).date());
    });

    setHighlightedDays(months);
  }, [dateSlots]);

  useEffect(() => {
    if (!dateSlots || disableAutoSelectDay) return;
    const dateObject: Dayjs = dayjs(dateSlots[0]);
    setSelectedDay(dateObject);
    setSelectedDate(dateObject);
    //@ts-expect-error
  }, [dateSlots.length]);

  const handleMonthChange = (date: Dayjs) => {
    // Logic here if needed
  };

  const handleDateClick = (date: Dayjs) => {
    setSelectedDay(date);
    setSelectedDate(date);
  };

  const locale = userData?.selectedLanguage || "en";
  dayjs.locale(locale);

  const dayOfWeekFormatter = (day: string, date: Dayjs) => {
    if (!userData) return dayjs(date).locale(locale).format("ddd");
    const formatDay = userData.selectedLanguage === "en" ? "ddd" : "dd";
    return dayjs(date).locale(locale).format(formatDay);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DateCalendar
        className="calendar"
        slots={{
          day: (props) => (
            <ServerDay
              {...props}
              highlightedDays={highlightedDays[props.day.month()]}
              onDateClick={handleDateClick}
              selectedDay={selectedDay ? selectedDay : undefined}
              isSelectedMode={isSelectedMode}
              dateSlots={dateSlots}
              style={{ fontSize: fontSize }}
            />
          ),
        }}
        dayOfWeekFormatter={dayOfWeekFormatter}
        onMonthChange={handleMonthChange}
      />
    </LocalizationProvider>
  );
}
