import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./PersonInfo.module.css";
import DropdownSelect from "../../../components/DropdownSelect/DropdownSelect";
import {
  daysList,
  generateNumbersArray,
  updateUserAvatar,
  yearsList,
} from "../../../utils/setupProfileInfo";
import EditIcon from "../../../assets/SpecialistSetupInfo/edit.svg";
import ImageIcon from "../../../assets/SpecialistSetupInfo/photo.svg";
import RadioButtonsGroup from "../../../components/RadioGroup/RadioGroup";
import StandartButton from "../../../components/StandartButton/StandartButton";
import UsersApi from "../../../api/usersApi";
import { ContextProvider } from "../../../contextProvider";
import ReactFlagsSelect from "react-flags-select";
import CityPicker, {
  PlaceType,
} from "../../../components/CitySelect/CitySelect";
import "react-country-state-city/dist/react-country-state-city.css";
import { popularLanguages } from "../../../constans/languagesList";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import { ToastContainer } from "react-toastify";
import Notify from "../../../utils/toaster";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import strings from "../../../localization";
import Input from "../../../components/Input/Input";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

type TextInputValues = {
  name: string;
  surname: string;
};

type TypePersonInfoProps = {
  avatar: File | null;
  setAvatar: Dispatch<SetStateAction<File | null>>;
  textInputValues: TextInputValues;
  setTextInputValues: Dispatch<SetStateAction<TextInputValues>>;
  dateOfBith: { day: number; month: number; year: number };
  setDateOfBith: Dispatch<
    SetStateAction<{ day: number; month: number; year: number }>
  >;
  genderValue: "male" | "female" | string;
  setGenderValue: Dispatch<SetStateAction<"male" | "female" | string>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
};

const genderDataStatic = [
  {
    value: "male",
    lable: strings.clientSetupMale,
  },
  {
    value: "female",
    lable: strings.clientSetupFemale,
  },
  {
    value: "non-binary",
    lable: strings.clientSetupNonBinary,
  },
  {
    value: "not-specify",
    lable: strings.clientSetupDontWant,
  },
];

const PersonInfo = ({
  textInputValues,
  setDateOfBith,
  dateOfBith,
  setTextInputValues,
  genderValue,
  setGenderValue,
  avatar,
  setAvatar,
  setActiveStep,
}: TypePersonInfoProps) => {
  const token = localStorage.getItem("token");
  const { userData, setUserData } = useContext(ContextProvider);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState<PlaceType | null>(null);
  const [selectedLangs, setSelectedLangs] = useState<string | string[]>([]);
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [gendersData, setGendersData] = useState<
    {
      lable: string;
      value: string;
    }[]
  >([]);
  const [timeZone, setTimeZone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [previewAvatar, setPreviewAvatar] = useState<string | null>(null);

  const monthNames = [
    strings.jan,
    strings.feb,
    strings.mar,
    strings.apr,
    strings.may,
    strings.jun,
    strings.jul,
    strings.aug,
    strings.sep,
    strings.oct,
    strings.nov,
    strings.dec,
  ];
  const monthList = generateNumbersArray(1, 12).map((item, index) => {
    return {
      value: item,
      lable: monthNames[index],
    };
  });

  const handleSelectLang = (value: string | string[]) => {
    setSelectedLangs(value);
  };
  const handleTimeZoneChange = (value: any) => {
    setTimeZone(value);
  };
  const dateBirthUpdate = (
    value: string,
    objKey: keyof { day: number; month: number; year: number }
  ) => {
    setDateOfBith((prev) => ({ ...prev, [objKey]: Number(value) }));
  };
  const languagesList = popularLanguages.map((item) => ({
    value: item,
    lable: item,
  }));
  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        setAvatar(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) setPreviewAvatar(e.target.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const validatePersonInfo = (
    textInputValues: TextInputValues,
    selectedCity: PlaceType | null,
    selectedLangs: string | string[]
  ) => {
    const notFielded: string[] = [];

    if (!textInputValues.name || textInputValues.name === "") {
      notFielded.push("name");
    }
    if (!textInputValues.surname || textInputValues.surname === "") {
      notFielded.push("surname");
    }
    if (!selectedCity) {
      notFielded.push("location");
    }
    if (
      !selectedLangs ||
      (Array.isArray(selectedLangs) && selectedLangs.length === 0)
    ) {
      notFielded.push("languages");
    }

    return {
      notFielded,
      status: notFielded.length === 0,
    };
  };

  const arrayToString = (arr: string[]) => {
    if (arr.length === 0) return "";
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return arr.join(" and ");
    return arr.slice(0, -1).join(", ") + ", and " + arr.slice(-1);
  };

  const sendPersonInfo = async () => {
    const token = localStorage.getItem("token");

    if (
      !validatePersonInfo(textInputValues, selectedCity, selectedLangs).status
    ) {
      const notFieldedArr = validatePersonInfo(
        textInputValues,
        selectedCity,
        selectedLangs
      ).notFielded;

      const notFielde = arrayToString(notFieldedArr);

      return Notify(`Enter your ${notFielde}`);
    }
    if (token && userData && userData?._id) {
      const userDate = new Date(
        dateOfBith.year,
        dateOfBith.month - 1,
        dateOfBith.day
      );
      if (avatar) {
        await updateUserAvatar(avatar, userData._id, token);
      }
      const response = await UsersApi.updateUserData(token, {
        _id: userData?._id,
        name: textInputValues.name,
        surname: textInputValues.surname,
        dateOfBirth: userDate,
        gender: genderValue,
        preferredLanguages: selectedLangs,
        location:
          selectedCity?.terms[0].value +
          ", " +
          selectedCity?.terms[selectedCity?.terms.length - 1].value,
        timezone: timeZone,
      });
      setUserData(response.userData);
      setActiveStep(2);
    }
  };

  useEffect(() => {
    if (!token || !userData) return;
    const makeAsync = async () => {
      const gendersResponse = await UsersApi.getAllGenders(token);
      if (gendersResponse.status && gendersResponse.genders) {
        const allGenders = gendersResponse.genders.map((item) => ({
          lable: item.labels.find(
            (el) => el.language === userData.selectedLanguage
          )?.text!,
          value: item.gender,
        }));

        setGendersData(allGenders);
      }
    };
    makeAsync();
  }, [token, userData]);

  return (
    <div className={s.container}>
      <div className={s.avatarBlock}>
        <div className={s.avatar}>
          <p
            className={s.lableTitle}
            style={{ textAlign: "center", fontSize: "22px" }}
          >
            {strings.clientSetupAvatarTitle}
          </p>
          {avatar ? (
            <div style={{ position: "relative" }}>
              <img
                src={previewAvatar ?? ""}
                alt="Avatar"
                className={s.avatarImage}
              />
              {avatar && (
                <label className={s.avatarInputBlock}>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleAvatarChange(e)}
                  />
                  <div className={s.editAvatarIcon} role="button">
                    <img src={EditIcon} alt="" />
                  </div>
                </label>
              )}
            </div>
          ) : (
            <label className={s.avatarInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => handleAvatarChange(e)}
              />
              <div className={s.avatarLable}>
                <img src={ImageIcon} alt="" />
              </div>
            </label>
          )}
        </div>
        <div className={s.nameSurnameBlock}>
          <p className={s.lableTitle}>{strings.clientSetupNameLable}</p>
          <Input
            inputValue={textInputValues.name}
            onChangeInput={(value) => {
              setTextInputValues((prev) => ({ ...prev, name: value }));
            }}
            isVisible
            required
            padding="18px 20px"
            fontSize="16px"
            margin="0 0 20px 0"
          />

          <p className={s.lableTitle}>{strings.clientSetupSurnameLable}</p>
          <Input
            inputValue={textInputValues.surname}
            onChangeInput={(value) => {
              setTextInputValues((prev) => ({ ...prev, surname: value }));
            }}
            isVisible
            required
            padding="18px 20px"
            fontSize="16px"
            margin="0 0 20px 0"
          />
        </div>
      </div>
      <div className={s.personInfoBlock}>
        <p className={s.lableTitle}>{strings.clientSetupDateOfBirthLable}</p>
        <div className={s.dateOfBirthBlock}>
          <DropdownSelect
            data={daysList}
            value={dateOfBith.day.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.clientSetupDayLable}
            width="30%"
            objKey="day"
            lableColor="#FF6A69"
          />
          <DropdownSelect
            data={monthList}
            value={dateOfBith.month.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.clientSetupMonthLable}
            width="30%"
            objKey="month"
            lableColor="#FF6A69"
          />
          <DropdownSelect
            data={yearsList}
            value={dateOfBith.year.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.clientSetupYearLable}
            width="30%"
            objKey="year"
            lableColor="#FF6A69"
          />
        </div>
        <div className={s.countryLangSelectBlock}>
          <p className={s.lableTitle}>{strings.clientSetupChoosePlaceLable}</p>
          <div className={s.countyBlock}>
            <ReactFlagsSelect
              blacklistCountries
              countries={["RU"]}
              className={s.countrySelect}
              selected={selectedCountry}
              onSelect={(code) => setSelectedCountry(code)}
              searchable
              placeholder={
                selectedCountry.length
                  ? ""
                  : strings.clientSetupSelectCountryPlaceHodler
              }
            />
            <div className={s.cityPickerBlock}>
              <CityPicker
                selectedCity={selectedCity}
                selectedCountry={selectedCountry}
                onCitySelect={setSelectedCity}
                placeHolder={strings.clientSetupCityPlaceHodler}
              />
            </div>
          </div>
        </div>
        <div className={s.languageBlock}>
          <div style={{ width: "48%" }}>
            <p className={s.lableTitle}>{strings.clientSetupLangLable}</p>
            <MultipleSelect
              data={languagesList}
              setValue={handleSelectLang}
              value={selectedLangs}
              multiplie={true}
              lable={strings.clientSetupLangPlaceHodler}
            />
          </div>
          <div style={{ width: "48%" }}>
            <MultipleSelect
              data={options.map((option) => ({
                value: option.value,
                lable: option.label,
              }))}
              multiplie={false}
              setValue={handleTimeZoneChange}
              value={timeZone}
              placeholder={strings.timeZonePlaceHolder}
            />
          </div>
        </div>
        <p className={s.lableTitle}>{strings.clientSetupGenderLable}</p>
        <div className={s.genderBlock}>
          <RadioButtonsGroup
            data={gendersData ? gendersData : genderDataStatic}
            defaultValue="male"
            value={genderValue}
            setValue={setGenderValue}
            width={window.innerWidth > 600 ? "105%" : "95%"}
            color="#FF6A69"
          />
        </div>
        <div className={s.buttonBlock}>
          <StandartButton
            action={sendPersonInfo}
            buttonTitle={strings.continueBtnSetupProfile}
            width="310px"
            height="59px"
            bgColor="var(--user-primary-color)"
            borderColor="var(--user-primary-color)"
            userButton={true}
          />
        </div>
      </div>
      {/*   <ToastContainer /> */}
    </div>
  );
};

export default PersonInfo;
