import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./Quieze.module.css";
import RadioButtonsGroup from "../../../components/RadioGroup/RadioGroup";
import { ReactComponent as NextStepIcon } from "../../../assets/SpecialistSetupInfo/next-slide.svg";
import MentorImage from "../../../assets/SpecialistSetupInfo/mentor.jpg";
import QuizeApi from "../../../api/quiezeApi";
import { ContextProvider } from "../../../contextProvider";
import { TypeQuestionWithId } from "../UserQuize";
import { useNavigate } from "react-router-dom";
import Checkicon from "../../../assets/SpecialistSetupInfo/checked.svg";
import Checkbox from "react-custom-checkbox";
import { Question } from "../../../types/TypeQuieze";
import strings from "../../../localization";

type QuiezeProps = {
  totalSteps: number;
  quizeData: Question;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  currentStep: number;
  setStep: Dispatch<SetStateAction<number>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  activeStep: number;
  questionId: string;
  quizId: string | undefined;
  quizResults: { questionId: string; answers: string[] }[];
  setQuizResults: Dispatch<
    SetStateAction<{ questionId: string; answers: string[] }[]>
  >;
  multiselect: boolean | undefined;
  setMainQuizResult: Dispatch<SetStateAction<string | null>>;
};

const Quieze = ({
  quizeData,
  value,
  setValue,
  setStep,
  currentStep,
  totalSteps,
  setActiveStep,
  activeStep,
  questionId,
  quizId,
  quizResults,
  setQuizResults,
  multiselect,
  setMainQuizResult,
}: QuiezeProps) => {
  const { userData, setIsSetupProfileInfo } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [isSelesctedAnswer, setIsSelesctedAnswer] = useState(false);
  const [error, setError] = useState("");

  const answersData = quizeData.answers.map((item, index) => {
    const initialAnswerItem = quizeData.answerLabels.find(
      (el) => el.initialAnswer === item
    );
    return {
      value: item,
      lable: initialAnswerItem
        ? initialAnswerItem.labels.find(
            (item) => item.language === userData?.selectedLanguage
          )?.text ?? item
        : item,
    };
  });

  const getAnswerStatus = (value: string): boolean => {
    const existingIndex = quizResults.findIndex(
      (result) => result.questionId === questionId
    );
    if (existingIndex === -1) return false;
    return quizResults[existingIndex].answers.includes(value);
  };

  const onChangeMultiselect = (value: string) => {
    const existingIndex = quizResults.findIndex(
      (result) => result.questionId === questionId
    );

    if (existingIndex === -1) {
      const newResult = { questionId: questionId, answers: [value] };
      setQuizResults((prevState) => [...prevState, newResult]);
      return;
    }

    setQuizResults((prevState) => {
      const newState = [...prevState];
      const answers = [...newState[existingIndex].answers];

      const answerIndex = answers.indexOf(value);
      if (answerIndex === -1) {
        answers.push(value); // Добавить значение, если его нет в массиве
      } else {
        answers.splice(answerIndex, 1); // Удалить значение, если оно уже существует
      }

      newState[existingIndex] = { ...newState[existingIndex], answers };
      return newState;
    });
  };

  useEffect(() => {
    setIsSelesctedAnswer(value === "" ? false : true);
    if (value === "") return;
    const existingIndex = quizResults.findIndex(
      (result) => result.questionId === questionId
    );
    if (existingIndex !== -1) {
      setQuizResults((prev) => {
        const updatedResults = [...prev];
        updatedResults[existingIndex] = {
          ...updatedResults[existingIndex],
          answers: [value],
        };
        return updatedResults;
      });
    } else {
      setQuizResults((prev) => [
        ...prev,
        { questionId: questionId, answers: [value] },
      ]);
    }
  }, [value]);

  const handleNextMainStep = async () => {
    if (activeStep === 3) {
      setMainQuizResult(quizResults[0].answers[0]);
    }
    setActiveStep((prev) => prev + 1);

    if (userData && userData._id && quizId && questionId) {
      await QuizeApi.saveQuizResult({
        userId: userData?._id,
        quizId: quizId,
        results: quizResults,
      });
      setQuizResults([]);
    }
  };

  const finishQuize = () => {
    handleNextMainStep();
    navigate("/recommendation");
    /*     setIsSetupProfileInfo(true); */
  };
  const handleNextStep = () => {
    activeStep === 4 && currentStep + 1 === totalSteps
      ? finishQuize()
      : currentStep + 1 === totalSteps
      ? handleNextMainStep()
      : setStep((prev) => prev + 1);
    setValue("");
  };

  return (
    <div className={s.container}>
      <>
        <div className={s.imageBlock}>
          <img src={MentorImage} alt="" />
        </div>
        <div className={s.quiezeBlock}>
          <div className={s.titleBlock}>
            <p className={s.mainTitle}>
              {
                quizeData.labels.find(
                  (item) => item.language === userData?.selectedLanguage
                )?.text
              }
            </p>
            {/* <p className={s.secondaryTitle}>{quizeData.description}</p> */}
          </div>
          <div className={s.answersBlock}>
            {multiselect ? (
              answersData &&
              answersData.map((item, index) => (
                <Checkbox
                  key={index}
                  containerStyle={{
                    marginBottom: "30px",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                  checked={getAnswerStatus(item.value)}
                  icon={
                    <img
                      src={Checkicon}
                      style={{
                        width: 25,
                        marginBottom: "10px",
                        marginLeft: "8px",
                      }}
                      alt=""
                    />
                  }
                  borderColor={() =>
                    getAnswerStatus(item.value) ? "#3FB083" : "black"
                  }
                  borderRadius={5}
                  size={20}
                  label={item.lable}
                  labelStyle={{ fontSize: "18px", marginLeft: "15px" }}
                  onChange={(checked: boolean, event: any) =>
                    onChangeMultiselect(answersData[index].value!)
                  }
                />
              ))
            ) : (
              <RadioButtonsGroup
                data={answersData}
                defaultValue=""
                value={value}
                setValue={setValue}
                width="95%"
                flexDirection="column"
                color="var(--user-primary-color)"
              />
            )}
          </div>
        </div>
      </>
      <div
        role="button"
        className={
          isSelesctedAnswer ||
          quizResults.findIndex(
            (result) => result.questionId === questionId
          ) !== -1
            ? s.nextButtonBlock
            : s.notActiveNextButtonBlock
        }
        onClick={
          isSelesctedAnswer ||
          quizResults.findIndex(
            (result) => result.questionId === questionId
          ) !== -1
            ? handleNextStep
            : () => setError("Select an answer")
        }
      >
        <NextStepIcon className={s.nextStepIcon} />
        {(isSelesctedAnswer ||
          quizResults.findIndex(
            (result) => result.questionId === questionId
          ) !== -1) && (
          <button className={s.nextStepButton}>{strings.continueBtnMob}</button>
        )}
      </div>
    </div>
  );
};

export default Quieze;
