import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./RealTimeSessionModalContent.module.css";
import StandartButton from "../StandartButton/StandartButton";
import strings from "../../localization";
import { ContextProvider } from "../../contextProvider";
import ModalWindow from "../ModalWindow/ModalWindow";
import { PaymentFor, PaymentForType } from "../../types/Balance";
import PaymentSession from "../PaymentSession/PaymentSession";
import { SuccesNotify } from "../../utils/toaster";
import { useNavigate } from "react-router-dom";

type RealTimeSessionModalContentProps = {
  fullName: string;
  sendRequestHandler: (
    sessionSubject: string,
    setSubjectValue: Dispatch<SetStateAction<string>>
  ) => Promise<{ status: boolean; notEnoughBalance?: boolean }>;
  price: string | number;
  realTimeError: string;
  specialistUserId: string;
  setRealTimeSessionModal: Dispatch<SetStateAction<boolean>>;
};

const RealTimeSessionModalContent = ({
  fullName,
  sendRequestHandler,
  price,
  realTimeError,
  specialistUserId,
  setRealTimeSessionModal,
}: RealTimeSessionModalContentProps) => {
  const navigate = useNavigate();
  const { setUserData } = useContext(ContextProvider);
  const [subjectValue, setSubjectValue] = useState("");
  const [error, setError] = useState("");
  const [noBalanceModal, setNoBalanceModal] = useState(false);
  const [paymentFor, setPaymentFor] = useState<PaymentFor | null>(null);

  useEffect(() => {
    const currentPaymentFor = {
      type: PaymentForType.REAL_TIME_SESSION,
      bookingFor: specialistUserId,
      subject: subjectValue,
      realTimeSessionDuration: 60,
    };
    setPaymentFor(currentPaymentFor);
  }, [subjectValue]);

  const sendRequest = async () => {
    const response = await sendRequestHandler(subjectValue, setSubjectValue);
    if (!response.status && response.notEnoughBalance) {
      setNoBalanceModal(true);
    }
    if (response.status) {
      navigate("/global-chat", {
        state: {
          userId: specialistUserId,
        },
      });
      SuccesNotify(strings.bookingSucces);
      setUserData((prev) => ({
        ...prev!,
        balance: prev!.balance - Number(price) * 100,
      }));
    }
  };

  return (
    <div className={s.container}>
      <h3 className={s.headTitle}>{strings.realTimeSessionTitle}</h3>
      <div className={s.sessionDetailsBlock}>
        <h4>{strings.sessionDetails}</h4>
        <div className={s.sessionDetailsElement}>
          <span>{strings.specialistsrealTime} </span>
          <span>{fullName}</span>
        </div>
        {/* <div className={s.sessionDetailsElement}>
          <span>Price: </span>
          <span>{price}$</span>
        </div> */}
        <div className={s.sessionDetailsElement}>
          <span>{strings.time} </span>
          <span>{strings.now}</span>
        </div>
      </div>
      <div className={s.subjectBlock}>
        <div className={s.subjectTitleBlock}>
          <h4>
            {strings.subjectSession}
            <span className={s.maxLength}>
              ({subjectValue.length + "/" + "500"})
            </span>
          </h4>
          {error.length ? <span>{error}</span> : ""}
        </div>
        <textarea
          name=""
          id=""
          cols={30}
          rows={3}
          value={subjectValue}
          className={s.textAreaNewNote}
          onChange={(e) => setSubjectValue(e.target.value)}
          maxLength={500}
        ></textarea>
      </div>
      <div className={s.errorTitle}>{realTimeError}</div>
      <div className={s.actionBlock}>
        <StandartButton
          action={sendRequest}
          buttonTitle={strings.sendRequestRealTimeBtn}
          height="40px"
          disabled={subjectValue.length ? false : true}
          tooltipTitle={subjectValue.length ? "" : strings.subjectError}
        />
      </div>
      {paymentFor && (
        <ModalWindow
          isOpen={noBalanceModal}
          setIsOpen={setNoBalanceModal}
          width="fit-content"
        >
          <PaymentSession
            hidePromocode
            paymentFor={paymentFor}
            setReplenishModal={setNoBalanceModal}
            setPrevModalVisible={setRealTimeSessionModal}
          />
        </ModalWindow>
      )}
    </div>
  );
};

export default RealTimeSessionModalContent;
