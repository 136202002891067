import React, { useEffect, useState } from "react";
import s from "./SubscribeBtn.module.css";
import { ReactComponent as SubscribeIcon } from "../../../assets/Profile/profile-add.svg";
import { ReactComponent as UnsubscribeIcon } from "../../../assets/Profile/unsubscribe.svg";
import { ReactComponent as BellIcon } from "../../../assets/Profile/Bell.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/Profile/Arrow-Down.svg";
import strings from "../../../localization";
import AnimateHeight from "react-animate-height";
import SocialApi from "../../../api/socialApi";
import Notify from "../../../utils/toaster";

type SubscribeBtn = {
  userId: string;
};

const SubscribeBtn = ({ userId }: SubscribeBtn) => {
  const token = localStorage.getItem("token");
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [unsubscribeCollapsed, setUnsubscribeCollapsed] = useState(true);

  useEffect(() => {
    const maleAsync = async () => {
      if (!token) return;
      const response = await SocialApi.checkIfFollowed(token, userId);
      if (response.status) {
        setIsSubscribe(response.followed);
      }
    };
    maleAsync();
  }, [userId, token]);

  const followUser = async () => {
    if (!token) return;
    setIsSubscribe(true);
    const response = await SocialApi.followUser(token, {
      userIdToFollow: userId,
    });

    if (!response.status && response.message) {
      Notify(response.message);
      setIsSubscribe(false);
    }
  };

  const unfollowUser = async () => {
    if (!token) return;
    setIsSubscribe(false);
    const response = await SocialApi.unfollowUser(token, {
      userIdToUnfollow: userId,
    });

    if (!response.status && response.message) {
      Notify(response.message);

      setIsSubscribe(true);
    }
  };

  return (
    <div className={s.container}>
      {isSubscribe ? (
        <div className={s.unsubContainer}>
          <div role="button" className={s.unsubBtn} onClick={unfollowUser}>
            <UnsubscribeIcon className={s.unsubBtnIcon} />
            <span>{strings.unsubscribeBtn}</span>
          </div>
        </div>
      ) : (
        <div className={s.subscribeBtn} role="button" onClick={followUser}>
          <SubscribeIcon />
          <span>{strings.subscribeBtn}</span>
        </div>
      )}
    </div>
  );
};

export default SubscribeBtn;
