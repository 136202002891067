import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "../CreatingProfile.module.css";
import EditIcon from "../../../../assets/SpecialistSetupInfo/edit.svg";
import MovieIcon from "../../../../assets/SpecialistSetupInfo/movie.svg";
import ImageIcon from "../../../../assets/SpecialistSetupInfo/photo.svg";
import { SpecialistData } from "../../../../types/TypeSpecialist";
import UsersApi from "../../../../api/usersApi";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";

type TypeSubSteps2 = {
  avatar: File | null;
  setAvatar: Dispatch<SetStateAction<File | null>>;
  video: File | null;
  setVideo: Dispatch<SetStateAction<File | null>>;
  specialistInfo: SpecialistData | null;
};

const SubSteps2 = ({
  avatar,
  setAvatar,
  video,
  setVideo,
  specialistInfo,
}: TypeSubSteps2) => {
  const { userData } = useContext(ContextProvider);
  const [previewAvatar, setPreviewAvatar] = useState<string | null>(null);

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setVideo(file || null);
  };
  const [fullName, setFullName] = useState("");
  const [specializations, setSpecializations] = useState<
    string[] | undefined
  >();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const makeAsync = async () => {
      if (token) {
        const response = await UsersApi.getUserByToken(token);
        if (
          response &&
          response.specialistId &&
          response.specialistId.subSpecializations
        ) {
          const subSpecializationsTitle =
            response.specialistId.subSpecializations.map(
              (item) =>
                item.labels.find(
                  (item) => item.language === userData?.selectedLanguage
                )?.text!
            );
          setSpecializations(subSpecializationsTitle);
        }
        if (response && response.name && response.surname)
          setFullName(response.name + " " + response.surname);
      }
    };
    makeAsync();
  }, [userData]);

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        setAvatar(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) setPreviewAvatar(e.target.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div className={s.subStepBlock}>
      <p className={s.stepTitle}>{strings.setupAvatarAndVideoTitle}</p>
      <div className={s.avatarBlock}>
        <div className={s.avatar}>
          {avatar ? (
            <div style={{ position: "relative" }}>
              <img
                src={previewAvatar ?? ""}
                alt="Avatar"
                className={s.avatarImage}
              />
              {avatar && (
                <label className={s.avatarInputBlock}>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleAvatarChange(e)}
                  />
                  <div className={s.editAvatarIcon} role="button">
                    <img src={EditIcon} alt="" />
                  </div>
                </label>
              )}
            </div>
          ) : (
            <label className={s.avatarInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => handleAvatarChange(e)}
              />
              <div className={s.avatarLable}>
                <img src={ImageIcon} alt="" />
              </div>
            </label>
          )}
        </div>
        <div className={s.personInfoBlock}>
          <div className={s.nameTitle}>
            <p>{fullName}</p>
          </div>
          <div className={s.experienceTitle}>
            <p>
              {strings.setupAvatarPracticalHours}{" "}
              {specialistInfo?.practiceHours} {strings.setupHours}
            </p>
            <p>
              {specialistInfo?.yearsOfExperience} {strings.setupAvatarYearsExp}
            </p>
          </div>
          <div className={s.specializationTitle}>
            {specializations &&
              specializations.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
          </div>
        </div>
      </div>
      <div className={s.videoContainer}>
        <div className={s.videoBlcok}>
          {video ? (
            <div style={{ position: "relative" }}>
              <video
                controls
                className={s.video}
                key={URL.createObjectURL(video)}
              >
                <source src={URL.createObjectURL(video)} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <label className={s.fileInputBlock}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleVideoChange(e)}
                />
                <div className={s.editMovieIcon} role="button">
                  <img src={EditIcon} alt="" />
                </div>
              </label>
            </div>
          ) : (
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept="video/*"
                onChange={(e) => handleVideoChange(e)}
              />
              <div className={s.videoInputPlaceholder}>
                <img src={MovieIcon} alt="" />
              </div>
            </label>
          )}
        </div>
        <div className={s.aboutVideoTitleBlock}>
          {strings.setupUploadVideoDescription}
        </div>
      </div>
    </div>
  );
};

export default SubSteps2;
