import TypeDefaultResponse from "./TypeDefaultResponse";
import { TypeUserData } from "./TypeUsers";

export enum PostFileTypeToUpdate {
  IMAGES = "IMAGES",
  VIDEOS = "VIDEOS",
}

export type PostComments = {
  _id: string;
  user: { _id: string; name: string; surname: string };
  comment: string;
  likes: { _id: string; user: string }[];
  createdDate: string;
};
export type Post = {
  _id: string;
  createdBy: { _id: string; name: string; surname: string };
  createdAt: string;
  updatedAt: string;
  content: string;
  images: string[];
  videos: string[];
  likes: { _id: string; user: string }[];
  comments: PostComments[];
  isAdminPost: boolean;
};
export type GetPostFilesResposne = TypeDefaultResponse & {
  images?: {
    _id: string;
    link: string;
    type: string;
    filename: string;
    size: number;
  }[];
  videos?: {
    _id: string;
    link: string;
    type: string;
    filename: string;
    size: number;
  }[];
};
