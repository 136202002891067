import React, { useContext } from "react";
import s from "./MyProgramCard.module.css";
import UserAvatarList from "../../../components/UserAvatarList/UserAvatarList";
import CalendarIcon from "../../../assets/Header/calendar.svg";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../../utils/theme";
import strings from "../../../localization";

type MyProgramCardProps = {
  projectId: string;
  clientIds: string[];
  programTitle: string;
  programStartDate: string;

  joinProjectHandler: (
    userId: string | undefined,
    projectId: string | undefined
  ) => void;
  joinLoader: boolean;
  isSelected: boolean;
  discount: number;
};

const MyProgramCard = ({
  clientIds,
  programTitle,
  programStartDate,
  discount,
  joinProjectHandler,
  projectId,
  joinLoader,
  isSelected,
}: MyProgramCardProps) => {
  const { userData } = useContext(ContextProvider);

  return (
    <div className={`${s.container} ${isSelected ? s.slectedCard : ""}`}>
      <div className={s.userListBlock}>
        <UserAvatarList
          userIds={clientIds}
          showLimit={4}
          avatarHeight="30px"
          avatarWidth="30px"
          fontSize="14px"
        />
      </div>
      <div className={s.programInfoBlock}>
        <span className={s.programTitle}>{programTitle}</span>
        {discount > 0 && (
          <div className={s.priceBlock}>
            <span>{strings.myProgramDiscountTitle}</span>
            <span>-{discount}% </span>
          </div>
        )}
      </div>
      <div className={s.joinBlock}>
        <div className={s.dateBlock}>
          <img src={CalendarIcon} alt="" />
          <span>{programStartDate}</span>
        </div>
      </div>
    </div>
  );
};

export default MyProgramCard;
