import React, { useEffect, useState } from "react";
import s from "./MessageComponent.module.css";
import { TypeMessage } from "../../../../types/TypeMessageChat";
import MessageChatApi from "../../../../api/messageChatApi";
import {
  downloadFile,
  formatFileSize,
  formatedFiles,
} from "../../../../utils/messageChat";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import { ReactComponent as DownloadIcon } from "../../../../assets/VideoSession/download.svg";
import { ReactComponent as FileMessageIcon } from "../../../../assets/VideoSession/file-message.svg";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../../../utils/theme";
import RealTimeSessionMessageCard from "./RealTimeSessionMessageCard/RealTimeSessionMessageCard";
import { RealTimeBooking } from "../../../../types/TypeSession";

interface Message {
  messageId: string;
  userId: string;
  text: string;
  sender: string;
  files: any[];
  date?: Date;
  realTimeBooking?: RealTimeBooking;
}

type MessageComponentProps = {
  message: Message;
  name?: string;
  surname?: string;
};

const MessageComponent = ({
  message,
  name,
  surname,
}: MessageComponentProps) => {
  const imageTypesArr = ["jpeg", "png", "jpg", "gif", "avif", "webp"];
  const [messageFiles, setMessageFiles] = useState<
    { type: string; image: string; filename: string; size: string }[]
  >([]);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [selectImage, setSelectImage] = useState("");

  const openImageHandler = (index: number) => {
    setSelectImage(messageFiles[index].image);
    setImageModalIsOpen(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const makeAsync = async () => {
      if (token) {
        const response = await MessageChatApi.getMessageFiles(
          token,
          message.messageId
        );
        if (response.status) {
          const files = formatedFiles(response.files);
          const messageFiles = files.map((item, index) => ({
            type: response.files[index].type,
            image: item,
            filename: response.files[index].filename,
            size: formatFileSize(response.files[index].size),
          }));
          setMessageFiles(messageFiles);
        }
      }
    };
    makeAsync();
  }, []);

  const formateDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };
  function formatTime(date: Date) {
    const hours = new Date(date).getHours().toString().padStart(2, "0");
    const minutes = new Date(date).getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
  const todayDate = formateDate(new Date());

  return (
    <div
      className={
        message.realTimeBooking
          ? s.sessionMessage
          : message.userId === "System"
          ? s.systemMessage
          : message.sender === "user"
          ? s.userMessage
          : s.otherMessage
      }
    >
      {message.realTimeBooking ? (
        <RealTimeSessionMessageCard
          message={message}
          name={name}
          surname={surname}
        />
      ) : (
        <div
          className={s.messageContainer}
          style={{ marginBottom: message.userId === "System" ? 0 : "10px" }}
        >
          {(messageFiles && messageFiles.length) || !message.files.length ? (
            messageFiles.map((item, index) =>
              imageTypesArr.some((type) => type === item.type) ? (
                <img
                  src={item.image}
                  alt=""
                  key={index}
                  className={s.file}
                  onClick={() => openImageHandler(index)}
                />
              ) : (
                <div
                  key={index}
                  className={s.fileBlock}
                  onClick={() =>
                    downloadFile(
                      item.image,
                      item.filename.split(".")[0],
                      item.type === "plain" ? "txt" : item.type
                    )
                  }
                >
                  <FileMessageIcon
                    className={
                      message.sender === "user"
                        ? s.fileMessage
                        : s.fileMessageOther
                    }
                  />
                  <div
                    className={
                      message.sender === "user"
                        ? s.fileInfoBlock
                        : s.fileInfoBlockOther
                    }
                  >
                    <span>{item.filename}</span>
                    <span>{item.size}</span>
                  </div>
                </div>
              )
            )
          ) : (
            <div className={s.loaderBlock}>
              <ThemeProvider theme={theme}>
                <CircularProgress size={100} color="secondary" />
              </ThemeProvider>
            </div>
          )}
          <p className={s.messageText}>{message.text}</p>
          <ModalWindow
            isOpen={imageModalIsOpen}
            setIsOpen={setImageModalIsOpen}
            height="fit-content"
            width="fit-content"
            bgColor="transparent"
            padding="0"
          >
            <div className={s.modalImageBlock}>
              <img src={selectImage} alt="" className={s.modalImage} />
            </div>
          </ModalWindow>
        </div>
      )}
      {message.date && message.userId !== "System" && (
        <div
          className={s.messageTimeTitle}
          style={{
            justifyContent:
              message.sender === "user" ? "flex-end" : "flex-start",
          }}
        >
          {formatTime(message.date)}
        </div>
      )}
    </div>
  );
};

export default MessageComponent;
