import { ReactComponent as ChatIcon } from "../assets/Header/chatIcon.svg";
import { ReactComponent as EnvelopeIcon } from "../assets/Header/evelope.svg";
import { ReactComponent as CheckboxIcon } from "../assets/Header/checkbox.svg";
import { ReactComponent as CalendarIcon } from "../assets/Header/calendar.svg";
import { ReactComponent as StarIcon } from "../assets/Header/star.svg";
import { ReactComponent as BellIcon } from "../assets/Header/bell.svg";
import { ReactComponent as UserIcon } from "../assets/Header/user.svg";
import { ReactComponent as SettingsIcon } from "../assets/Header/settings.svg";
import path from "path";

export const leftHeaderMenu = [
  {
    icon: CalendarIcon,
    title: "calendar",
    path: "/calendar",
  },
  {
    icon: ChatIcon,
    title: "global-chat",
    path: "/global-chat",
  },
  {
    icon: BellIcon,
    title: "notifies",
  },
];
