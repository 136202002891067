import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import s from "./NewsfeedCard.module.css";
import CircleAvatar from "../../CircleAvatar/CircleAvatar";
import { Post, PostFileTypeToUpdate } from "../../../types/Posts";
import { ReactComponent as LikeIcon } from "../../../assets/Social/heart.svg";
import { ReactComponent as CommentIcon } from "../../../assets/Social/comment.svg";
import { ReactComponent as EditIcon } from "../../../assets/Notes/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Notes/delete.svg";
import { ContextProvider } from "../../../contextProvider";
import PostApi from "../../../api/postsApi";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import strings from "../../../localization";
import { ReactComponent as TripleDotIcon } from "../../../assets/Social/triple-dots.svg";
import { ReactComponent as VerifyIcon } from "../../../assets/Social/verify.svg";
import { ReactComponent as ImageIcon } from "../../../assets/Social/image-post.svg";
import { ReactComponent as VideoIcon } from "../../../assets/Social/video-post.svg";
import ModalWindow from "../../ModalWindow/ModalWindow";
import CommentsModalContent from "../CommentsModalContent/CommentsModalContent";
import { formatNumber } from "../../../utils/Numbers";
import TalziLogo from "../../../assets/Social/postAdmin.png";
import { formatDateTimeAgo } from "../../../utils/dateActions";
import NavigateToProfile from "../../NavigateToProfile/NavigateToProfile";
import FileGrid from "../FileGrid/FileGrid";
import UsersApi from "../../../api/usersApi";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";
import UpdateFileElement from "./UpdateFileElement/UpdateFileElement";

import { compressFiles } from "../../../utils/filesAction";

type NewsfeedCardProps = {
  post: Post;
  allPosts: Post[];
  setAllPosts: Dispatch<SetStateAction<Post[]>>;
  hiddenActionsPost?: boolean;
  mainPadding?: string;
  showMedia: boolean;
};

const NewsfeedCard = ({
  post,
  allPosts,
  setAllPosts,
  hiddenActionsPost,
  mainPadding,
  showMedia,
}: NewsfeedCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const postActionMenuRef = useRef<HTMLDivElement | null>(null);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [updatePostData, setUpdatePostData] = useState({
    content: "",
  });
  const [commentsModalVisible, setCommentsModalVisible] = useState(false);
  const [postFilesLinks, setPostFilesLinks] = useState<{
    images: { link: string; _id: string; filename: string }[];
    videos: { link: string; _id: string; filename: string }[];
  }>({ images: [], videos: [] });
  const [postActionMenuIsOpen, setPostActionMenuIsOpen] = useState(false);
  const [specPostData, setSpecPostData] = useState<{
    mainSpecialization: string;
    isVerified: boolean;
  } | null>(null);
  const [filesToRemove, setFilesToRemove] = useState<{
    idToRemove: string[];
    imageremoveLength: number;
    videosRemoveLength: number;
  }>({ idToRemove: [], imageremoveLength: 0, videosRemoveLength: 0 });
  const [updatePostVideo, setUpdatePostVideo] = useState<File[]>([]);
  const [updatePostImages, setUpdatetPostImages] = useState<File[]>([]);
  const [postIsEdit, setPostIsEdit] = useState(false);
  const [seletedImgIndex, setSeletedImgIndex] = useState(0);

  useAutosizeTextArea(textAreaRef.current, updatePostData.content);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        postActionMenuRef.current &&
        !postActionMenuRef.current.contains(event.target as Node)
      ) {
        setPostActionMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [postActionMenuRef]);

  const getPostFiles = async () => {
    if (!token) return;
    const response = await PostApi.getPostFiles(token, post._id);

    if (response.status) {
      if (response.images) {
        const imagesLinks = response.images.map((item) => ({
          link: item.link,
          _id: item._id,
          filename: item.filename,
        }));
        setPostFilesLinks((prev) => ({ ...prev, images: imagesLinks }));
      }
      if (response.videos) {
        const videosLinks = response.videos.map((item) => ({
          link: item.link,
          _id: item._id,
          filename: item.filename,
        }));
        setPostFilesLinks((prev) => ({ ...prev, videos: videosLinks }));
      }
    }
  };

  useEffect(() => {
    getPostFiles();
  }, [token, post]);

  const checkMyselfLike = () => {
    if (!userData) return;
    if (post.likes.length === 0) return false;
    const arrLikeUsers = post.likes.map((item) => item.user);
    return arrLikeUsers.includes(userData?._id);
  };

  const toggleLike = (posts: Post[]) => {
    if (!userData) return;
    return posts.map((el) => {
      if (el._id === post._id) {
        const likeIndex = post.likes.findIndex(
          (like) => like.user === userData._id
        );

        if (likeIndex !== -1) {
          // Удаляем лайк
          el.likes.splice(likeIndex, 1);
        } else {
          // Добавляем лайк
          el.likes.push({ _id: userData?._id, user: userData?._id });
        }
      }
      return el;
    });
  };

  const updatePostContent = (postId: string, newContent: string) => {
    setAllPosts((prevPosts) =>
      prevPosts.map((post) =>
        post._id === postId ? { ...post, content: newContent } : post
      )
    );
  };

  const likePost = async () => {
    if (!token || !userData) return;
    const payload = {
      userId: userData._id,
      postId: post._id,
    };

    const response = await PostApi.likePost(token, payload);

    if (response.status) {
      const updatePosts = toggleLike(allPosts);
      if (updatePosts) setAllPosts(updatePosts);
    }
  };

  const unlikePost = async () => {
    if (!token || !userData) return;
    const payload = {
      userId: userData._id,
      postId: post._id,
    };

    const response = await PostApi.unlikePost(token, payload);
    if (response.status) {
      const updatePosts = toggleLike(allPosts);
      if (updatePosts) setAllPosts(updatePosts);
    }
  };

  const deletePost = async () => {
    if (!token) return;
    const response = await PostApi.deletePost(token, post._id);
    if (response.status) {
      SuccesNotify(strings.postDeleted);
      setAllPosts((prevPosts) => prevPosts.filter((el) => el._id !== post._id));
      setConfirmDeleteModalOpen(false);
    }
  };

  const updatePost = async () => {
    if (!token) return;

    if (
      !updatePostData.content.replace(/\s+/g, "").length &&
      !updatePostImages.length &&
      !updatePostVideo.length &&
      filesToRemove.idToRemove.length ===
        post.images.length + post.videos.length
    ) {
      return Notify(strings.emptyPostNotify);
    }
    if (filesToRemove.idToRemove.length) {
      const fileType = filesToRemove.imageremoveLength
        ? PostFileTypeToUpdate.IMAGES
        : PostFileTypeToUpdate.VIDEOS;

      removePostFile(filesToRemove.idToRemove, fileType);
    }

    if (updatePostImages.length) {
      updatePostFiles(PostFileTypeToUpdate.IMAGES);
    }
    if (updatePostVideo.length) {
      updatePostFiles(PostFileTypeToUpdate.VIDEOS);
    }
    const payload = {
      _id: post._id,
      content: updatePostData.content,
    };
    const response = await PostApi.updatePost(token, payload);
    if (response.status) {
      updatePostContent(post._id, updatePostData.content);
      SuccesNotify(strings.updatePost);
      setPostIsEdit(false);
      setUpdatePostData((prev) => ({ ...prev, content: "" }));
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFiles: Dispatch<SetStateAction<File[]>>,
    typeFiles: "video" | "image"
  ) => {
    const newFiles = event.target.files;

    if (!newFiles) {
      return;
    }

    const files = Array.from(newFiles);
    const hasGif = files.some((file) => file.type === "image/gif");

    if (hasGif) {
      Notify(strings.gifAllowed);
      return;
    }

    if (newFiles) {
      const totalImages =
        postFilesLinks.images.length +
        newFiles.length +
        updatePostImages.length -
        filesToRemove.imageremoveLength;

      const maxFiles = typeFiles === "image" ? 5 : 1;
      const totalFiles = typeFiles === "image" ? totalImages : 0;
      if (totalFiles <= maxFiles) {
        if (typeFiles === "image") {
          return compressFiles(files, setFiles);
        }
        if (typeFiles === "video") {
          return setFiles((prevFiles) => [
            ...prevFiles,
            ...Array.from(newFiles),
          ]);
        }
      } else {
        Notify(strings.toManyPostFile);
      }
    }
  };

  useEffect(() => {
    const maleAsync = async () => {
      if (!token) return;
      const selectUserFields = "specialistId";
      const selectSpecFields = "isVerified mainSpecializations";
      const response = await UsersApi.getUserSelectedFields(
        token,
        post.createdBy._id,
        selectUserFields,
        selectSpecFields
      );
      if (response.status && !response.userData?.specialistId) {
        setSpecPostData(null);
      }
      if (response.status && response.userData?.specialistId) {
        setSpecPostData({
          isVerified: response.userData.specialistId.isVerified,
          mainSpecialization:
            response.userData.specialistId.mainSpecializations[0].labels.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
        });
      }
    };
    maleAsync();
  }, [token, post, allPosts]);

  const updatePostFiles = async (fileType: PostFileTypeToUpdate) => {
    if (!token || !userData) return;
    const formData = new FormData();
    if (updatePostImages.length) {
      for (let i = 0; i < updatePostImages.length; i++) {
        formData.append("files", updatePostImages[i]);
      }
    }
    if (updatePostVideo.length) {
      for (let i = 0; i < updatePostVideo.length; i++) {
        formData.append("files", updatePostVideo[i]);
      }
    }
    formData.append("fileType", fileType);

    const response = await PostApi.updatePostFiles(token, post._id, formData);

    if (response.status) {
      getPostFiles();
      setUpdatePostVideo([]);
      setUpdatetPostImages([]);
    }
    return response.status;
  };

  const updatedAllPosts = (posts: Post[], updatedPost: Post) => {
    return posts.map((post) =>
      post._id === updatedPost._id ? updatedPost : post
    );
  };

  const removePostFile = async (
    fileId: string[],
    fileType: PostFileTypeToUpdate
  ) => {
    if (!token || !userData) return;

    const payload = {
      fileIds: fileId,
      fileType: fileType,
    };

    setPostFilesLinks((prevState) => ({
      images: prevState.images.filter((image) => !fileId.includes(image._id)),
      videos: prevState.videos.filter((video) => !fileId.includes(video._id)),
    }));

    const response = await PostApi.removePostFile(token, post._id, payload);

    if (response.status && response.post) {
      getPostFiles();
      setFilesToRemove((prev) => ({ ...prev, idToRemove: [] }));
      const newPosts = updatedAllPosts(allPosts, response.post);
      setAllPosts(newPosts);
    }
  };

  const getAvailableImportFiles = (): "video" | "image" | "all" | "nothing" => {
    const imagesLength =
      postFilesLinks.images.length +
      updatePostImages.length -
      filesToRemove.imageremoveLength;
    const videoLength =
      postFilesLinks.videos.length +
      updatePostVideo.length -
      filesToRemove.videosRemoveLength;

    if (
      (imagesLength > 0 && imagesLength < 5) ||
      (postFilesLinks.images.length > 0 && imagesLength < 5)
    )
      return "image";
    /* if (videoLength > 0) {
      return "video";
    } */
    if (
      (videoLength > 0 && !filesToRemove.videosRemoveLength) ||
      imagesLength === 5
    )
      return "nothing";
    return "all";
  };

  return (
    <div className={s.container} style={{ padding: mainPadding ?? "20px" }}>
      <div className={s.avatarNameBlock}>
        <div>
          <div className={s.avatarBlock}>
            {post.isAdminPost ? (
              <img src={TalziLogo} alt="" className={s.adminAvatar} />
            ) : (
              <NavigateToProfile userId={post.createdBy._id}>
                <CircleAvatar
                  userId={post.createdBy._id}
                  width="30px"
                  height="30px"
                />
              </NavigateToProfile>
            )}
            <div>
              <span className={s.myName}>
                {post.isAdminPost
                  ? "Talzi News"
                  : post.createdBy.name + " " + post.createdBy.surname}
                {specPostData && specPostData.isVerified && (
                  <VerifyIcon className={s.verifyIcon} />
                )}
              </span>
              <div className={s.dateBlock}>
                <span>
                  {formatDateTimeAgo(
                    new Date(post.createdAt),
                    userData!.selectedLanguage
                  ) +
                    " " +
                    (new Date(post.createdAt).getFullYear() ===
                    new Date().getFullYear()
                      ? ""
                      : new Date(post.createdAt).getFullYear())}
                </span>
                {specPostData && specPostData.mainSpecialization && (
                  <span>{specPostData.mainSpecialization}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        {post.createdBy._id === userData?._id && !hiddenActionsPost && (
          <div className={s.postActionBlock} ref={postActionMenuRef}>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setPostActionMenuIsOpen((prev) => !prev);
              }}
              className={s.tripleDotBtn}
            >
              <TripleDotIcon />
            </span>
            <div
              className={s.postActionMenu}
              style={{ display: postActionMenuIsOpen ? "flex" : "none" }}
              ref={postActionMenuRef}
            >
              <span
                className={s.editButtonBlock}
                role="button"
                onClick={() => {
                  setUpdatePostData((prev) => ({
                    ...prev,
                    content: post.content,
                  }));
                  setPostIsEdit(true);
                  setPostActionMenuIsOpen(false);
                }}
              >
                <EditIcon className={s.editIcon} />
                <span>{strings.editSocial}</span>
              </span>
              <span
                className={s.deleteButtonBlock}
                role="button"
                onClick={() => setConfirmDeleteModalOpen(true)}
              >
                <DeleteIcon className={s.deletIcon} />
                <span>{strings.deleteSocial}</span>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className={s.contentBlock}>
        <textarea
          rows={1}
          value={updatePostData.content}
          onChange={(e) =>
            setUpdatePostData((prev) => ({
              ...prev,
              content: e.target.value,
            }))
          }
          className={s.updatePostInput}
          style={{ display: postIsEdit ? "block" : "none" }}
          placeholder="Start..."
          ref={textAreaRef}
        />

        <pre
          className={s.postContent}
          style={{ display: !postIsEdit ? "block" : "none" }}
        >
          {post.content}
        </pre>
      </div>
      {postFilesLinks.images &&
      postFilesLinks.images.length &&
      showMedia &&
      !postIsEdit ? (
        <div className={s.imagesBlock}>
          <FileGrid
            images={postFilesLinks.images.map((item) => item.link)}
            openImagesPreview={true}
            setCommentsModalVisible={setCommentsModalVisible}
            setSeletedImgIndex={setSeletedImgIndex}
          />
        </div>
      ) : null}
      {postFilesLinks.videos &&
      postFilesLinks.videos.length &&
      showMedia &&
      !postIsEdit ? (
        <div className={s.videosBlock}>
          <video
            src={postFilesLinks.videos[0].link}
            controls
            className={s.postVideo}
          />
        </div>
      ) : null}

      {postIsEdit &&
      (postFilesLinks.images.length ||
        postFilesLinks.videos.length ||
        updatePostImages.length ||
        updatePostVideo.length) ? (
        <div className={s.updateFilesBlock}>
          {postFilesLinks.images.map((item, index) => (
            <UpdateFileElement
              key={index}
              returnFunc={() =>
                setFilesToRemove((prev) => ({
                  ...prev,
                  idToRemove: prev.idToRemove.filter((el) => el !== item._id),
                  imageremoveLength: prev.imageremoveLength - 1,
                }))
              }
              filename={item.filename}
              removeFunc={() =>
                setFilesToRemove((prev) => ({
                  ...prev,
                  idToRemove: [...prev.idToRemove, item._id],
                  imageremoveLength: prev.imageremoveLength + 1,
                }))
              }
              isFileToRemove={filesToRemove.idToRemove.includes(item._id)}
            />
          ))}
          {updatePostImages.length
            ? updatePostImages.map((item, index) => (
                <UpdateFileElement
                  key={index}
                  filename={item.name}
                  removeFunc={() =>
                    setUpdatetPostImages((prev) =>
                      prev.filter((el) => el !== item)
                    )
                  }
                />
              ))
            : null}
        </div>
      ) : null}
      {postIsEdit &&
      (postFilesLinks.videos.length || updatePostVideo.length) ? (
        <div className={s.updateFilesBlock}>
          {postFilesLinks.videos.map((item, index) => (
            <UpdateFileElement
              key={index}
              returnFunc={() => {
                setUpdatePostVideo([]);
                setFilesToRemove((prev) => ({
                  ...prev,
                  idToRemove: prev.idToRemove.filter(
                    (el) => el !== postFilesLinks.videos[0]._id
                  ),
                  videosRemoveLength: prev.videosRemoveLength - 1,
                }));
              }}
              filename={item.filename}
              removeFunc={() =>
                setFilesToRemove((prev) => ({
                  ...prev,
                  idToRemove: [
                    ...prev.idToRemove,
                    postFilesLinks.videos[0]._id,
                  ],
                  videosRemoveLength: prev.videosRemoveLength + 1,
                }))
              }
              isFileToRemove={filesToRemove.idToRemove.includes(item._id)}
            />
          ))}
          {updatePostVideo.length
            ? updatePostVideo.map((item, index) => (
                <UpdateFileElement
                  key={index}
                  filename={item.name}
                  removeFunc={() => setUpdatePostVideo([])}
                />
              ))
            : null}
        </div>
      ) : null}
      {postIsEdit && (
        <div className={s.updateActionBlock}>
          <div className={s.importFilesBtnBlock}>
            {(getAvailableImportFiles() === "image" ||
              getAvailableImportFiles() === "all") && (
              <label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  multiple
                  value={""}
                  onChange={(e) =>
                    handleFileChange(e, setUpdatetPostImages, "image")
                  }
                />
                <ImageIcon className={s.importImageIcon} />
              </label>
            )}
            {(getAvailableImportFiles() === "video" ||
              getAvailableImportFiles() === "all") && (
              <label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="video/*"
                  multiple
                  value={""}
                  onChange={(e) =>
                    handleFileChange(e, setUpdatePostVideo, "video")
                  }
                />
                <VideoIcon className={s.importVideoIcon} />{" "}
              </label>
            )}
          </div>
          <div role="btn" onClick={updatePost} className={s.saveUpdateBtn}>
            Save
          </div>
        </div>
      )}
      <div className={s.actionBlock}>
        <div className={s.actionElement}>
          <div
            role="button"
            className={s.actionIconBtn}
            onClick={() => (checkMyselfLike() ? unlikePost() : likePost())}
          >
            <LikeIcon
              className={
                checkMyselfLike() ? s.myselfLikeIcon : s.actionIconLike
              }
            />
          </div>
          <span>{formatNumber(post.likes.length)}</span>
        </div>
        {!hiddenActionsPost && (
          <div className={s.actionElement}>
            <div
              role="button"
              className={s.actionIconBtn}
              onClick={() => setCommentsModalVisible(true)}
            >
              <CommentIcon className={s.actionIcon} />
            </div>
            <span>{formatNumber(post.comments.length)}</span>
          </div>
        )}
      </div>
      <ConfirmationModal
        confirmFunc={deletePost}
        confirmTitle={strings.confirmDeletePostTitle}
        modalVisible={confirmDeleteModalOpen}
        setModalVisible={setConfirmDeleteModalOpen}
      />

      <ModalWindow
        setIsOpen={setCommentsModalVisible}
        isOpen={commentsModalVisible}
        height={window.innerWidth > 768 ? "80vh" : "90vh"}
        maxWidth="fit-content"
        width={
          post.images.length || post.videos.length ? "fit-content" : "600px"
        }
        padding="0"
      >
        <CommentsModalContent
          allPosts={allPosts}
          post={post}
          setAllPosts={setAllPosts}
          images={postFilesLinks.images.map((item) => item.link)}
          videos={postFilesLinks.videos.map((item) => item.link)}
          seletedImgIndex={seletedImgIndex}
          setSeletedImgIndex={setSeletedImgIndex}
        />
      </ModalWindow>
    </div>
  );
};

export default NewsfeedCard;
