import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import s from "./Dashboard.module.css";
import { ReactComponent as ChevronIcon } from "../../assets/HomePage/chevron-up.svg";
import DashboardCard from "./DashboardCard/DashboardCard";
import StandartBlock from "../Initial/StandartBlock/StandartBlock";
import MuiCalendar from "../../components/MuiCalendar/MuiCalendar";
import { Dayjs } from "dayjs";
import UserAvatarList from "../../components/UserAvatarList/UserAvatarList";
import CompanyApi from "../../api/companyApi";
import { ContextProvider } from "../../contextProvider";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartInput from "../../components/StandartInput/StandartInput";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import { Roles, TypeCompanyEmployee } from "../../types/Company";
import StandartButton from "../../components/StandartButton/StandartButton";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import { Sessions } from "../../types/TypeSession";
import Notify, { SuccesNotify } from "../../utils/toaster";
import { ToastContainer } from "react-toastify";
import getTxtFileContent from "../../utils/getTxtFileContent";
import DownloadIcon from "../../assets/VideoSession/download.svg";
import strings from "../../localization";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import Input from "../../components/Input/Input";
import { convertDateForDaySlots } from "../../utils/dateActions";
import { formatDateTimeWithOutTimezone } from "../../utils/general";

type TypeInviteInputs = {
  email: string;
  role: Roles.DEEFAULT_USER | Roles.MANAGER;
  lable: string;
};
const Dashboard = () => {
  const token = localStorage.getItem("token");
  const { userData, userCompany } = useContext(ContextProvider);
  const tabEmptyRef1 = useRef<HTMLDivElement | null>(null);
  const tabEmptyRef2 = useRef<HTMLDivElement | null>(null);
  const tabRef1 = useRef<HTMLDivElement | null>(null);
  const tabRef2 = useRef<HTMLDivElement | null>(null);
  const [tabWidth, setTabWidth] = useState({ tab1: 0, tab2: 0 });
  const tabIndicatorRef = useRef<HTMLDivElement | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [inviteUsersModalVisible, setInviteUsersModalVisible] = useState(false);
  const [inviteLoader, setInviteLoader] = useState(false);
  const [activeTab, setActiveTab] = useState<"person" | "bulk">("person");
  const [bulkFile, setBulkFile] = useState<File | null>(null);
  const [incorrectLines, setIncorrectLines] = useState<{
    duplicate: number[];
    incorrect: number[];
  } | null>(null);

  const rolesData = [
    {
      value: Roles.DEEFAULT_USER,
      lable: strings.employeeSelectRole,
    },
    {
      value: Roles.MANAGER,
      lable: strings.managerSelectRole,
    },
  ];

  const [companySessions, setCompanySessions] = useState<Sessions[] | null>(
    null
  );
  const [companyEmployers, setCompanyEmployers] = useState<
    TypeCompanyEmployee[] | null
  >(null);
  const [companyStatisticsData, setCompanyStatisticsData] = useState<
    { title: string; value: number | undefined }[] | null
  >(null);
  const [inviteInputValues, setInviteInputValues] = useState<TypeInviteInputs>({
    email: "",
    role: Roles.DEEFAULT_USER,
    lable: "Emploee",
  });
  const [daySlots, setDaySlots] = useState<string[]>([]);
  const [showLimit, setShowLimit] = useState(3);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userCompany) return;
      const response = await CompanyApi.getCompanySessions(
        token,
        userCompany[0]._id
      );
      if (response.status && response.sessions && response.sessions.length) {
        const dateArr = response.sessions.map((item) =>
          convertDateForDaySlots(
            //@ts-expect-error
            formatDateTimeWithOutTimezone(item.datesAndTime.date).formattedDate
          )
        );
        setDaySlots(dateArr);
      }
    };
    makeAsync();
  }, [userCompany]);

  useEffect(() => {
    if (tabEmptyRef1.current && tabEmptyRef2.current) {
      const tab1Width = tabEmptyRef1.current.offsetWidth;
      const tab2Width = tabEmptyRef2.current.offsetWidth;

      setTabWidth({ tab1: tab1Width, tab2: tab2Width });
    }
  }, [activeTab, tabEmptyRef1, tabEmptyRef2, inviteUsersModalVisible]);

  useLayoutEffect(() => {
    if (tabRef1.current && tabRef2.current && tabIndicatorRef.current) {
      const selectedTabElement =
        activeTab === "person" ? tabRef1.current : tabRef2.current;
      if (selectedTabElement) {
        const tabWidth = selectedTabElement.offsetWidth;
        tabIndicatorRef.current.style.width = `${tabWidth}px`;

        // Рассчитать позицию по оси X для tabIndicator
        const offsetLeft = selectedTabElement.offsetLeft;
        tabIndicatorRef.current.style.transform = `translateX(${offsetLeft}px)`;
      }
    }
  }, [activeTab]);

  useEffect(() => {
    const setShowLimitHandler = () => {
      const showLimitCount =
        window.innerWidth > 1700
          ? 6
          : window.innerWidth > 1600
          ? 5
          : window.innerWidth > 1400
          ? 4
          : window.innerWidth < 1300 && window.innerWidth > 1200
          ? 9
          : window.innerWidth < 1200 && window.innerWidth > 900
          ? 5
          : 2;
      setShowLimit(showLimitCount);
    };
    setShowLimitHandler();
    window.addEventListener("resize", setShowLimitHandler);
    return () => {
      window.removeEventListener("resize", setShowLimitHandler);
    };
  }, []);

  const onChangeInviteInputs = (
    value: any,
    objKey: keyof TypeInviteInputs,
    lable: string
  ) => {
    setInviteInputValues((prev) => ({
      ...prev,
      [objKey]: value,
      lable: lable,
    }));
  };
  const sendInvite = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inviteInputValues.email))
      return console.log("invalid email");
    if (token && userCompany) {
      setInviteLoader(true);

      const payload = {
        emails: [inviteInputValues.email],
        companyId: userCompany[0]._id,
        role: inviteInputValues.role,
      };
      const response = await CompanyApi.inviteUserToCompany(token, payload);
      setInviteLoader(false);
      if (response.status) {
        setInviteInputValues((prev) => ({ ...prev, email: "" }));
        SuccesNotify(strings.invitationSent);
        setInviteUsersModalVisible(false);
      }
    }
  };
  useEffect(() => {
    const makeAsync = async () => {
      if (token && userCompany) {
        const companyStatisticsResponse = await CompanyApi.getCompanyStatistic(
          token,
          userCompany[0]._id
        );

        if (companyStatisticsResponse.status) {
          const dahsboardCardData = [
            {
              title: strings.totalEmployersCard,
              value: companyStatisticsResponse.statistics?.employees,
            },
            {
              title: strings.sessionHeldCard,
              value: companyStatisticsResponse.statistics?.finishedSessions,
            },
            {
              title: strings.sessionsFutureCard,
              value: companyStatisticsResponse.statistics?.futureSessions,
            },
            {
              title: strings.totalEmployersCard,
              value: companyStatisticsResponse.statistics?.employees,
            },
          ];
          setCompanyStatisticsData(dahsboardCardData);
        }
        const companySessionsResponse = await CompanyApi.getCompanySessions(
          token,
          userCompany[0]._id
        );
        if (companySessionsResponse.status) {
          setCompanySessions(companySessionsResponse.sessions);
        }
        const employersCompanyResponse =
          await CompanyApi.getCompanyParticipants(token, userCompany[0]._id);

        if (employersCompanyResponse.status) {
          setCompanyEmployers(employersCompanyResponse.participants);
        }
      }
    };
    makeAsync();
  }, [userCompany]);

  const handleBulkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Получаем выбранный файл
    if (
      file &&
      (file.type.startsWith("text/") || file.type === "application/vnd")
    ) {
      setBulkFile(file);
    } else {
      setBulkFile(null);
      Notify(strings.inccorectFileType);
    }
  };
  const handleBulkInvite = async (file: File) => {
    if (bulkFile && token && userCompany) {
      setInviteLoader(true);
      const fileContent = await getTxtFileContent(file);

      const response = await CompanyApi.inviteBulkUsersToCompany(token, {
        emailsList: fileContent as string,
        companyId: userCompany[0]._id,
      });

      setBulkFile(null);
      setInviteLoader(false);
      if (
        !response.status &&
        (response.duplicateLines.length ||
          response.incorrectEmailLines.length ||
          response.incorrectRoleLines.length)
      ) {
        const duplicatesLine = response.duplicateLines.flat();
        const incorrectLines = [
          ...response.incorrectEmailLines,
          ...response.incorrectRoleLines,
        ];
        setIncorrectLines({
          duplicate: duplicatesLine,
          incorrect: [...new Set(incorrectLines)],
        });
      }
      if (response.status) {
        setInviteUsersModalVisible(false);
        SuccesNotify(strings.invitationSent);
      }
    }
  };

  const downloadFiles = () => {
    const files = ["bulkExample.csv", "bulkExample.txt"];

    files.forEach((file) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = `./${file}`;
      downloadLink.download = file;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    });
  };
  useEffect(() => {
    if (inviteUsersModalVisible) return;
    setIncorrectLines(null);
  }, [inviteUsersModalVisible]);

  return (
    <div className={s.container}>
      <div ref={tabEmptyRef1} className={s.emptyTab}>
        {strings.personInviteTab}
      </div>
      <div ref={tabEmptyRef2} className={s.emptyTab}>
        {strings.bulkInviteTab}
      </div>
      <div className={s.locationTitleBlock}>
        <span>{strings.locationTitleCompany}</span>
        <ChevronIcon className={s.chevronIcon} />
        <span>{strings.locationTitleDashboard}</span>
      </div>

      <div className={s.dashboardCardBlock}>
        {companyStatisticsData &&
          companyStatisticsData.map((item, index) => (
            <div key={index} className={s.cardContainer}>
              <DashboardCard title={item.title} value={item.value} />
            </div>
          ))}
      </div>
      <div className={s.otherContentBlock}>
        <div className={s.calendarBlock}>
          <StandartBlock title={strings.dashboardCalendar}>
            <MuiCalendar
              dateSlots={daySlots}
              setSelectedDate={setSelectedDate}
              isSelectedMode={false}
            />
          </StandartBlock>
        </div>
        <div className={s.sendLinkBlock}>
          <StandartBlock title={strings.dashboardSendLink}>
            <div className={s.sendLinkAvatarBlock}>
              <UserAvatarList
                userIds={
                  companyEmployers
                    ? companyEmployers.map((item) => item.user._id)
                    : []
                }
                showLimit={showLimit}
              />
              <button
                className={s.addLinkButton}
                onClick={() => {
                  setInviteUsersModalVisible(true);
                }}
              >
                <PlusIcon className={s.plusIcon} />
              </button>
            </div>
          </StandartBlock>
        </div>
        <div style={{ width: "30%" }} className={s.emptyBlock}></div>
      </div>
      <ModalWindow
        isOpen={inviteUsersModalVisible}
        setIsOpen={setInviteUsersModalVisible}
        width="600px"
      >
        {inviteLoader ? (
          <div className={s.inviteLoaderContainer}>
            <ThemeProvider theme={theme}>
              <CircularProgress size={150} color="primary" />
            </ThemeProvider>
          </div>
        ) : (
          <div className={s.inviteContainer}>
            <h3>{strings.inviteUsersTitle}</h3>
            <div className={s.tabContainer}>
              <div className={s.tabButtons}>
                <div
                  ref={tabRef1}
                  className={`${s.tab} ${
                    activeTab === "person" ? s.active : ""
                  }`}
                  onClick={() => setActiveTab("person")}
                >
                  {strings.personInviteTab}
                </div>
                <div
                  ref={tabRef2}
                  className={`${s.tab} ${activeTab === "bulk" ? s.active : ""}`}
                  onClick={() => setActiveTab("bulk")}
                >
                  {strings.bulkInviteTab}
                </div>
              </div>
              <div
                ref={tabIndicatorRef}
                className={`${s.tabIndicator} ${
                  activeTab === "person" ? s.indicatorPerson : s.indicatorBulk
                }`}
                style={{
                  width:
                    activeTab === "person"
                      ? `${tabWidth.tab1}px`
                      : `${tabWidth.tab2}px`,
                }}
              />
            </div>
            <div className={s.inviteInputBlock}>
              {activeTab === "person" ? (
                <>
                  <span className={s.inviteInputTitle}>
                    {strings.enterTheEmailInvite}
                  </span>
                  <Input
                    inputValue={inviteInputValues.email}
                    onChangeInput={(value) =>
                      onChangeInviteInputs(
                        value,
                        "email",
                        inviteInputValues.lable
                      )
                    }
                    isVisible
                    required
                  />
                </>
              ) : (
                <div className={s.bulkBlock}>
                  <div className={s.inviteInputTitleBlock}>
                    <span className={s.inviteInputTitle}>
                      {strings.chooseFileWithEmail}
                    </span>
                    <span
                      className={s.downloadExampleTitle}
                      onClick={downloadFiles}
                    >
                      {strings.downloadExample}
                      <img
                        src={DownloadIcon}
                        alt=""
                        className={s.downloadIcon}
                      />
                    </span>
                  </div>
                  <div className={s.fileUpload}>
                    <label className={s.fileUploadLabel}>
                      <span>{strings.uplaodFile}</span>
                      <input
                        type="file"
                        className={s.fileInput}
                        style={{ display: "none" }}
                        accept="text/plain,text/csv"
                        onChange={handleBulkChange}
                      />
                    </label>
                    {bulkFile && (
                      <span className={s.fileName}>{bulkFile.name}</span>
                    )}
                  </div>
                </div>
              )}
              {incorrectLines && activeTab === "bulk" ? (
                <div className={s.errorTitle}>
                  <div>
                    <span className={s.errorSymbol}>!</span>{" "}
                    {strings.bulkInviteErrorTitle1}{" "}
                    {incorrectLines.incorrect.length
                      ? incorrectLines.incorrect.map(
                          (item, index) =>
                            item +
                            (index === incorrectLines.incorrect.length - 1
                              ? ""
                              : ", ")
                        )
                      : 0}
                  </div>
                  <div>
                    {" "}
                    <span className={s.errorSymbol}>!</span>{" "}
                    {strings.bulkInviteErrorTitle2}{" "}
                    {incorrectLines.duplicate.length
                      ? incorrectLines.duplicate.map(
                          (item, index) =>
                            item +
                            (index === incorrectLines.duplicate.length - 1
                              ? ""
                              : ", ")
                        )
                      : 0}
                  </div>
                </div>
              ) : (
                <span></span>
              )}
            </div>
            {activeTab === "person" && (
              <div className={s.inviteInputBlock}>
                <span
                  className={s.inviteInputTitle}
                  style={{ marginBottom: "5px" }}
                >
                  {strings.selectRoleToInvite}
                </span>
                <MultipleSelect
                  data={rolesData}
                  multiplie={false}
                  setValue={(value) =>
                    onChangeInviteInputs(
                      value,
                      "role",
                      rolesData.find((item) => item.value === value)?.lable!
                    )
                  }
                  value={inviteInputValues.role}
                  width="100%"
                />
              </div>
            )}
            <div className={s.inviteButtonBlock}>
              <StandartButton
                buttonTitle={strings.inviteBtn}
                action={
                  activeTab === "bulk" && bulkFile
                    ? () => handleBulkInvite(bulkFile)
                    : sendInvite
                }
                width="100%"
                height="50px"
              />
            </div>
          </div>
        )}
      </ModalWindow>
      {/*     <ToastContainer /> */}
    </div>
  );
};

export default Dashboard;
