import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./MyPrograms.module.css";
import MotionDiv from "./MotionDiv/MotionDiv";
import MyProgramCard from "./MyProgramCard/MyProgramCard";
import { motion, LayoutGroup } from "framer-motion";
import { ReactComponent as ChevronIcon } from "../../assets/SpecialistSetupInfo/arrow-chevron.svg";
import CalendarIcon from "../../assets/Header/calendar.svg";
import StandartButton from "../../components/StandartButton/StandartButton";
import ProgramSpecialistCard from "./ProgramSpecialistCard/ProgramSpecialistCard";
import { MyProjectType, Project } from "../../types/Company";
import { ContextProvider } from "../../contextProvider";
import ProjectApi from "../../api/projectApi";
import { formateDate } from "../../utils/general";
import Notify, { SuccesNotify } from "../../utils/toaster";
import { ToastContainer } from "react-toastify";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import strings from "../../localization";
import Loader from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";

const MyPrograms: React.FC = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("projectId");

  const mainRef = useRef<HTMLDivElement | null>(null);
  const [selectProgram, setSelectProgram] = useState<MyProjectType | null>(
    null
  );
  const [projectsData, setProjectsData] = useState<MyProjectType[] | null>(
    null
  );
  const [actionLoader, setActionLoader] = useState({
    joinProjectLoader: false,
    loadProjects: false,
  });
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    if (!projectId || !projectsData) return;
    const currentSelectedProject = projectsData.filter(
      (el) => el._id === projectId
    );
    if (currentSelectedProject[0]) {
      setSelectProgram(currentSelectedProject[0]);
    }
  }, [projectId, projectsData]);

  useEffect(() => {
    if (!mainRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (window.innerWidth <= 768) {
          setCardWidth(entry.contentRect.width);
        } else {
          const currentCardWidth = entry.contentRect.width / 4;
          setCardWidth(currentCardWidth);
        }
      }
    });

    observer.observe(mainRef.current);

    return () => {
      if (!mainRef.current) return;
      observer.unobserve(mainRef.current);
    };
  }, [mainRef, mainRef.current]);

  useEffect(() => {
    if (!token || !userData) return;
    const makeAsync = async () => {
      setActionLoader((prev) => ({ ...prev, loadProjects: true }));
      const response = await ProjectApi.getUserProjects(
        token,
        userData._id,
        50,
        1
      );

      setActionLoader((prev) => ({ ...prev, loadProjects: false }));
      if (response.status && response.projects) {
        setProjectsData(response.projects);
      }
    };
    makeAsync();
  }, [userData, token]);

  const joinProjectHandler = async (
    userId: string | undefined,
    projectId: string | undefined
  ) => {
    if (!token || !userId || !projectId) return;
    setActionLoader((prev) => ({ ...prev, joinProjectLoader: true }));
    const response = await ProjectApi.joinNonPrivateProject(
      token,
      userId,
      projectId
    );

    if (!response.status && response.message) {
      Notify(response.message);
    }
    setActionLoader((prev) => ({ ...prev, joinProjectLoader: false }));
    if (response.status) {
      setSelectProgram((prev) => {
        if (!prev) return null;
        return { ...prev, clients: [...prev.clients, userId] };
      });
      const projectIndex = projectsData!.findIndex(
        (project) => project._id === projectId
      );
      if (projectIndex !== -1 && projectsData) {
        const updatedProject = {
          ...projectsData[projectIndex],
          clients: [...projectsData[projectIndex].clients, userId],
        };

        const updatedProjects = [
          ...projectsData.slice(0, projectIndex),
          updatedProject,
          ...projectsData.slice(projectIndex + 1),
        ];

        // Обновляем стейт
        setProjectsData(updatedProjects);
      }

      SuccesNotify(strings.succesJoinproject);
    }
  };

  if (actionLoader.loadProjects) {
    return (
      <div className={s.loaderContainer} ref={mainRef}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container} ref={mainRef}>
      <div
        className={`${s.programListBlock} ${!selectProgram ? s.rowList : ""} ${
          selectProgram
            ? s.mobileProgramListBlockHidden
            : s.mobileProgramListBlockVisible
        }`}
      >
        <MotionDiv
          flexDirection={
            window.innerWidth <= 768
              ? "column"
              : selectProgram
              ? "column"
              : "row"
          }
        >
          {projectsData && projectsData.length ? (
            projectsData.map((item, index) => (
              <motion.div
                key={index}
                layout
                transition={{
                  type: "tween",
                  duration: window.innerWidth > 768 ? 1 : 0,
                }}
                onClick={() => setSelectProgram(item)}
                style={{ width: `calc(${cardWidth}px - 12px)` }}
                className={`${s.motionBlock} ${
                  !selectProgram ? s.nonSelectMotionBlock : ""
                }`}
              >
                <MyProgramCard
                  clientIds={item.clients}
                  programStartDate={formateDate(new Date(item.creationDate!))}
                  programTitle={item.title}
                  joinProjectHandler={joinProjectHandler}
                  projectId={item._id}
                  joinLoader={actionLoader.joinProjectLoader}
                  isSelected={item._id === selectProgram?._id}
                  discount={item.sessionDiscount}
                />
              </motion.div>
            ))
          ) : (
            <div className={s.noProgramsBlock}>
              <h1>{strings.noProgramAvailable}</h1>
            </div>
          )}
        </MotionDiv>
      </div>

      {selectProgram && (
        <div
          className={
            !selectProgram
              ? `${s.programContentBlock} ${s.mobileProgramInfoBlockHidden}`
              : `${s.programContentBlock} ${s.mobileProgramInfoBlockVisible}`
          }
        >
          <div className={s.programHeaderContent}>
            <div
              className={s.backButton}
              onClick={() => setSelectProgram(null)}
            >
              <ChevronIcon className={s.chevronIcon} />
              <span>{strings.myProgramBackBtn}</span>
            </div>
            <div className={s.dateBlock}>
              <img src={CalendarIcon} alt="" />
              {selectProgram && (
                <span>
                  {formateDate(new Date(selectProgram.creationDate!))}
                </span>
              )}
            </div>
          </div>
          {selectProgram && (
            <div className={s.programInfoTitleBlock}>
              <h3>{selectProgram.title}</h3>
              <p>{selectProgram.description}</p>
            </div>
          )}
          <div className={s.programJoinBlock}>
            {selectProgram.sessionDiscount > 0 ? (
              <div className={s.priceBlock}>
                <span>{strings.myProgramDiscountTitle}</span>
                <div className={s.priceValueBlock}>
                  <span>-{selectProgram.sessionDiscount}% </span>
                </div>
              </div>
            ) : null}
            {selectProgram &&
            !selectProgram.clients.includes(userData?._id!) ? (
              actionLoader.joinProjectLoader ? (
                <div className={s.loaderBlock}>
                  <ThemeProvider theme={theme}>
                    <CircularProgress size={40} color="primary" />
                  </ThemeProvider>
                </div>
              ) : (
                <StandartButton
                  action={() =>
                    joinProjectHandler(userData?._id, selectProgram._id)
                  }
                  buttonTitle={strings.joinBtn}
                  height="40px"
                  width="130px"
                />
              )
            ) : (
              <span></span>
            )}
          </div>
          <div className={s.programSpecialistListBlock}>
            <div className={s.specalistCardsHeader}>
              <span>{strings.specTableHeadLableName}</span>
              <span className={s.specializationLabel}>
                {strings.specTableHeadLableSpecialization}
              </span>
              <span className={s.langLable}>
                {strings.specTableHeadLableLanguages}
              </span>
              <span>{strings.myProgramPriceTitle}</span>
              <span>{strings.specTableHeadLableBookSession}</span>
            </div>
            {selectProgram &&
              selectProgram.specialists.map((item, index) => (
                <ProgramSpecialistCard
                  userId={item}
                  bgColor={index % 2 !== 0 ? "#F5F5F5" : "#FFFFFF"}
                  projectId={selectProgram._id}
                  discount={selectProgram.sessionDiscount}
                  key={index}
                />
              ))}
          </div>
        </div>
      )}
      {/*       <ToastContainer /> */}
    </div>
  );
};

export default MyPrograms;
