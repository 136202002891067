import React, { useContext, useEffect, useState } from "react";
import s from "./UsersProgramCard.module.css";
import NavigateToProfile from "../../../components/NavigateToProfile/NavigateToProfile";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import { ContextProvider } from "../../../contextProvider";
import UsersApi from "../../../api/usersApi";
import { calculateAge } from "../../../utils/setupProfileInfo";
import { Skeleton } from "@mui/material";

type UsersProgramCard = {
  userId: string;
  bgColor: string;
};

type ProgramUserData = {
  name: string;
  surname: string;
  gender: string;
  dateOfBirth: string;
  location: string;
};

const UsersProgramCard = ({ userId, bgColor }: UsersProgramCard) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [programUserData, setProgramUserData] =
    useState<ProgramUserData | null>(null);
  const [actionLoaders, setActionLoaders] = useState({ laodUserData: false });

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      const selectUserFields = "name surname gender dateOfBirth location";
      setActionLoaders((prev) => ({ ...prev, laodUserData: true }));
      const response = await UsersApi.getUserSelectedFields(
        token,
        userId,
        selectUserFields,
        "price"
      );
      if (response.status && response.userData) {
        const gender = response.userData.gender as unknown as {
          _id: string;
          gender: string;
          labels: { text: string; language: string }[];
        };
        const responseUserData = {
          name: response.userData.name!,
          surname: response.userData.surname!,
          gender: gender.labels.find(
            (el) => el.language === userData?.selectedLanguage
          )?.text!,
          dateOfBirth: response.userData.dateOfBirth!.toString(),
          location: response.userData.location!,
        };
        setProgramUserData(responseUserData);
      }
      setActionLoaders((prev) => ({ ...prev, laodUserData: false }));
    };
    makeAsync();
  }, [token]);

  if (actionLoaders.laodUserData) {
    return (
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={60}
        style={{ marginBottom: "3px" }}
        animation="wave"
      />
    );
  }

  return (
    <div className={s.container} style={{ backgroundColor: bgColor }}>
      <div className={s.mainUserInfoBlock}>
        <NavigateToProfile userId={userId}>
          <CircleAvatar
            userId={userId}
            width={window.innerWidth <= 768 ? "40px" : "50px"}
            height={window.innerWidth <= 768 ? "40px" : "50px"}
          />
        </NavigateToProfile>
        <NavigateToProfile userId={userId}>
          <div className={s.mainUserTitleInfo}>
            <span>
              {programUserData &&
                programUserData.name + " " + programUserData.surname}
            </span>
          </div>
        </NavigateToProfile>
      </div>
      <div className={s.ageUserBlock}>
        {programUserData && calculateAge(new Date(programUserData.dateOfBirth))}
      </div>
      <div className={s.genderUserBlock}>
        {programUserData && programUserData.gender}
      </div>
      <div className={s.locationuserBlock}>
        {programUserData && programUserData.location}
      </div>
    </div>
  );
};

export default UsersProgramCard;
