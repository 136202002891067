import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./BalanceHistoryList.module.css";
import {
  Payment,
  SessionPaymentStatuses,
  SessionPayment,
} from "../../types/Balance";
import strings from "../../localization";
import { ContextProvider } from "../../contextProvider";
import { formatDateAndTime } from "../../utils/dateActions";
import UsersApi from "../../api/usersApi";
import { theme } from "../../utils/theme";
import { CircularProgress, ThemeProvider } from "@mui/material";
import SessionsApi from "../../api/sessionApi";
import Loader from "../Loader/Loader";

const BalanceHistoryList = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const paymentsContainerRef = useRef<HTMLDivElement>(null);
  const [paymentPage, setPaymentPage] = useState(1);
  const [paymentHistory, setPaymentHistory] = useState<SessionPayment[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const getUsersSessionPayments = async () => {
    if (!token || !userData) return;
    setLoading(true);
    const response = await SessionsApi.getUsersSessionPayments(
      token,
      userData._id,
      paymentPage,
      5
    );

    setLoading(false);
    if (response.status && response.payments) {
      if (response.payments.length < 5) {
        setHasMore(false);
      }
      setPaymentHistory((prev) => [...prev, ...response.payments!]);
    }
  };

  useEffect(() => {
    getUsersSessionPayments();
  }, [userData, token]);

  const checkPaymentType = (
    forId: string,
    status: SessionPaymentStatuses
  ): { color: string; symbol: "+" | "-" | "" } => {
    if (!userData) return { color: "black", symbol: "" };
    if (status === SessionPaymentStatuses.RETURNED)
      return { color: "black", symbol: "" };
    if (status === SessionPaymentStatuses.PENDING && userData._id === forId)
      return { color: "black", symbol: "" };
    else if (userData._id === forId) return { color: "#3FB082", symbol: "+" };
    return { color: "#e34a4a", symbol: "-" };
  };

  const loadMorePosts = () => {
    if (hasMore && !loading) {
      setPaymentPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (paymentPage > 1) {
      getUsersSessionPayments();
    }
  }, [paymentPage]);

  const handleScroll = () => {
    if (paymentsContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        paymentsContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        loadMorePosts();
      }
    }
  };

  useEffect(() => {
    const container = paymentsContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [paymentsContainerRef.current, loading]);

  const statusObj = {
    SUCCEEDED: strings.completeStatus,
    PENDING: strings.pendingStatus,
    RETURNED: strings.returnedStatus,
  };

  return (
    <div className={s.container} ref={paymentsContainerRef}>
      {paymentHistory && paymentHistory.length ? (
        paymentHistory.map((item, index) => (
          <div
            key={index}
            className={s.historyCard}
            style={{
              marginBottom: index === paymentHistory.length - 1 ? "20px" : 0,
            }}
          >
            <div className={s.headTitlsBlock}>
              <span className={s.balanceDateLable}>
                {strings.balncehistoryDate}
              </span>
              <span className={s.balanceStatusLable}>
                {strings.balanceStatusTitle}
              </span>
              <span className={s.balanceTotalLable}>
                {strings.balanceHistoryTotal}
              </span>
            </div>
            <div className={s.cardValuesBlock}>
              <div className={s.dateValue}>
                <span>
                  {
                    formatDateAndTime(
                      new Date(item.date),
                      userData!.selectedLanguage
                    ).formattedDate
                  }
                </span>
                <span>
                  {" "}
                  {
                    formatDateAndTime(
                      new Date(item.date),
                      userData!.selectedLanguage
                    ).formattedTime
                  }
                </span>
              </div>
              <span
                className={s.statusValue}
                style={{
                  color:
                    item.status === "SUCCEEDED"
                      ? "#3FB082"
                      : item.status === "PENDING"
                      ? "#fac748"
                      : "#e34a4a",
                }}
              >
                {statusObj[item.status]}
              </span>
              <span
                className={s.totalValue}
                style={{
                  color: checkPaymentType(item.for._id, item.status).color,
                }}
              >
                {checkPaymentType(item.for._id, item.status).symbol}
                {(item.amount / 100).toFixed(0)}
              </span>
            </div>
          </div>
        ))
      ) : loading ? null : (
        <div className={s.emptyHistoryBlock}>
          <h2 className={s.historyEmptyText}>{strings.historyEmpty}</h2>
        </div>
      )}
      {loading && (
        <div className={s.loaderBlock}>
          <Loader size={40} />
        </div>
      )}
    </div>
  );
};

export default BalanceHistoryList;
