import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SpecialistCard from "../../components/SpecialistCard/SpecialistCard";
import s from "./SpecialistFindPage.module.css";
import { findDifferenceToMakeMultiple } from "../../utils/general";
import MagnifierIcon from "../../assets/Company/magnifier.svg";
import FilterIcon from "../../assets/Company/filter-icon.svg";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import ReactFlagsSelect from "react-flags-select";
import SpecialistApi from "../../api/specialistApi";
import RangeSlider from "../../components/RangeSlider/RangeSlider";
import { TypeUserData } from "../../types/TypeUsers";
import { CircularProgress, Pagination, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import { ContextProvider } from "../../contextProvider";
import { OverLaps } from "../../types/TypeSpecialist";
import countries from "i18n-iso-countries";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ReactComponent as NoSpecIcon } from "../../assets/SpecialistCard/noSpecialist.svg";
import "./SpecialistFindPage.css";
import getAvailableParams from "../../utils/getAvailableParams";
import {
  getExtraElements,
  removeFilterElements,
} from "../../utils/specialistFilter";
import MuiSwitch from "../../components/MuiSwitch/MuiSwitch";
import strings from "../../localization";
import UsersApi from "../../api/usersApi";
import Input from "../../components/Input/Input";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const genderSelectData = [
  {
    value: "Male",
    lable: "Male",
  },
  {
    value: "Female",
    lable: "Female",
  },
  {
    value: "Non-binary",
    lable: "Non-binary",
  },
];
type SelectData = {
  value: string;
  lable: string;
};
type SpecializationsState = {
  mainSpecData: SelectData[];
  subSpecData?: SelectData[];
  selectedMainSpec?: { value: string | string[]; label: string | string[] };
  selectedSubSpec?: { value: string | string[]; label: string | string[] };
};

const SpecialistFindPage = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const filterRef = useRef<any>(null);

  const [allMainSpecializations, setAllMainSpecializations] = useState<
    { specialization: string; _id: string }[]
  >([]);
  const [isLoader, setIsLoader] = useState(false);
  const [availableCountry, setAvailableCountry] = useState<string[]>([]);
  const [availableCity, setAvailableCity] = useState<
    { id: number; lable: string }[]
  >([]);
  const [filterRanges, setFilterRanges] = useState({
    age: { min: 0, max: 0 },
    price: { min: 0, max: 0 },
  });
  const [search, setSearch] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    title: string;
  }>({ code: "", title: "" });
  const [selectedCity, setSelectedCity] = useState<string | string[]>([]);
  const [selectedLangs, setSelectedLangs] = useState<string[]>([]);
  const [specializations, setSpecializations] = useState<SpecializationsState>({
    mainSpecData: [],
  });
  const [languagesList, setLanguagesList] = useState<
    { value: string; lable: string }[]
  >([]);

  const [genderFilterValue, setGenderFilterValue] = useState<string | string[]>(
    []
  );
  const [availableGenders, setAvailableGenders] = useState<
    | {
        value: string;
        lable: string;
      }[]
    | null
  >(null);
  const [filterCounter, setFilterCounter] = useState(0);
  const [ageRangeFilter, setAgeRangeFilter] = useState({
    value: { from: 0, to: 1000 },
    range: { min: 18, max: 100 },
  });
  const [priceRangeFilter, setPriceRangeFilter] = useState({
    value: { from: 0, to: 1000000000000 },
    range: { min: 1, max: 1000000000000 },
  });
  const [specialistData, setSpecialistData] = useState<
    TypeUserData[] | undefined
  >();
  const [pagginatuions, setPagginations] = useState({
    total: 0,
    currentPage: 1,
  });
  const [overLapsDays, setOverLapsDays] = useState<OverLaps[] | undefined>();
  const [realTimeSessionsChecked, setRealTimeSessionsChecked] = useState<
    boolean | null
  >(null);
  const [updateOverlaps, setUpdateOverlaps] = useState(0);

  const checkRangeValue = (
    value1: number,
    value2: number,
    max: number,
    min: number
  ): boolean => {
    if (
      value1 > value2 ||
      value1 < min ||
      value2 > max ||
      value2 < min ||
      value1 > max
    )
      return false;
    else return true;
  };

  useEffect(() => {
    if (userData && userData.specialistId) {
      setRealTimeSessionsChecked(userData.specialistId?.receiveRealTimeOffers);
    }
  }, [userData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData) {
        const payload = {
          userId: userData._id,
          filters: {
            name: search.length > 0 ? search : "",
            country:
              selectedCountry && selectedCountry.title
                ? [selectedCountry.title]
                : undefined,
            city:
              selectedCity && selectedCity.length ? selectedCity : undefined,
            gender: genderFilterValue.length ? genderFilterValue : undefined,
            age: {
              from: ageRangeFilter.value.from,
              to: ageRangeFilter.value.to,
            },
            preferredLanguages: selectedLangs.length
              ? selectedLangs
              : undefined,
            specialistId: {
              subSpecializations:
                specializations.selectedSubSpec &&
                specializations.selectedSubSpec.value.length
                  ? specializations.selectedSubSpec.value
                  : undefined,
              mainSpecializations:
                specializations.selectedMainSpec &&
                specializations.selectedMainSpec.value.length
                  ? specializations.selectedMainSpec.value
                  : undefined,
              price: {
                from: priceRangeFilter.value.from,
                to: priceRangeFilter.value.to,
              },
              isVerified: true,
              sort: {
                field: "_id",
                type: -1,
              },
            },
            page: pagginatuions.currentPage,
            limit: window.innerWidth < 1900 ? 6 : 8,
            considerOverlaps: true,
            receiveRealTimeOffers:
              realTimeSessionsChecked !== null
                ? realTimeSessionsChecked
                : false,
          },
        };
        setIsLoader(true);

        const response = await SpecialistApi.getFilteredSpecialists(
          token,
          payload
        );

        if (
          response.paramsAvailable &&
          response.paramsAvailable.totalAmount &&
          pagginatuions.total !==
            Math.ceil(response.paramsAvailable.totalAmount / 8)
        ) {
          setPagginations({
            total: Math.ceil(response.paramsAvailable.totalAmount / 8),
            currentPage: 1,
          });
        }
        if (response.users && !response.users.length) {
          setSpecialistData(undefined);
        }
        if (response.status && response.paramsAvailable) {
          setSpecialistData(response.users);

          setOverLapsDays(response.overlapDays);
        }
        setIsLoader(false);
        if (response.users && !response.users.length) return;
        const [
          genders,
          cities,
          countriesAvailable,
          mainSpecs,
          subSpecs,
          languages,
          age,
          price,
        ] = await getAvailableParams(token, payload.filters);

        const mainSpecResposne = await SpecialistApi.getMainSpecializations(
          token
        );

        if (mainSpecResposne.status && mainSpecResposne.mainSpecializations) {
          const availManSpecs = mainSpecResposne.mainSpecializations
            .filter((item) => mainSpecs.includes(item.specialization))
            .map((item) => ({
              lable: item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )?.text!,
              value: item.specialization,
            }));

          setSpecializations((prev) =>
            prev
              ? { ...prev, mainSpecData: availManSpecs }
              : { mainSpecData: availManSpecs }
          );

          const relatedMainSpec = mainSpecResposne.mainSpecializations.map(
            (item) => item._id
          );
          const subSpecResponse = await SpecialistApi.getSubSpecializations(
            token,
            relatedMainSpec
          );
          if (!subSpecResponse.status || !subSpecResponse.subSpecializations)
            return;
          const availSubSpecs = subSpecResponse.subSpecializations
            .filter((item) => subSpecs.includes(item.specialization))
            .map((item) => ({
              lable: item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )?.text!,
              value: item.specialization,
            }));

          setSpecializations((prev) =>
            prev
              ? { ...prev, mainSpecData: availManSpecs }
              : { mainSpecData: availManSpecs }
          );

          setSpecializations((prev) =>
            prev
              ? { ...prev, subSpecData: availSubSpecs }
              : {
                  mainSpecData: availManSpecs,
                  subSpecData: availSubSpecs,
                }
          );
        }

        const gendersResponse = await UsersApi.getAllGenders(token);
        if (gendersResponse.status && gendersResponse.genders) {
          const availGenders = gendersResponse.genders
            .filter((item) => genders.includes(item.gender))
            .map((item) => ({
              lable: item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )?.text!,
              value: item.gender,
            }));

          setAvailableGenders(availGenders);
        }

        if (
          removeFilterElements(
            getExtraElements(selectedLangs as string[], languages),
            selectedLangs
          ).length !== selectedLangs.length
        ) {
          setSelectedLangs(
            removeFilterElements(
              getExtraElements(selectedLangs as string[], languages),
              selectedLangs
            ) as string[]
          );
        }
        if (
          removeFilterElements(
            getExtraElements(genderFilterValue as string[], genders),
            genderFilterValue as string[]
          ).length !== genderFilterValue.length
        ) {
          setGenderFilterValue(
            removeFilterElements(
              getExtraElements(genderFilterValue as string[], genders),
              genderFilterValue as string[]
            ) as string[]
          );
        }

        const responseLangList = [
          ...new Set(
            languages.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setLanguagesList(responseLangList);

        setAgeRangeFilter((prev) => ({
          ...prev,
          value: {
            from: ageRangeFilter.value.from
              ? ageRangeFilter.value.from
              : //@ts-expect-error
                age.min,
            to: ageRangeFilter.value.from
              ? ageRangeFilter.value.to
              : //@ts-expect-error
                age.max,
          },
          //@ts-expect-error
          range: { min: age.min, max: age.max },
        }));

        setPriceRangeFilter((prev) => ({
          ...prev,
          value: {
            from: priceRangeFilter.value.from
              ? priceRangeFilter.value.from
              : //@ts-expect-error
                price.min,
            to: priceRangeFilter.value.from
              ? priceRangeFilter.value.to
              : //@ts-expect-error
                price.max,
          },
          //@ts-expect-error
          range: { min: price.min, max: price.max },
        }));
        const responseAvailableCountry = [
          ...new Set(
            countriesAvailable.filter((item) => !item.includes("undefined"))
          ),
        ];

        setAvailableCountry(
          responseAvailableCountry.map(
            (item) => countries.getAlpha2Code(item, "en") as string
          )
        );
        const responseAvailableCities = [
          ...new Set(
            cities
              .filter((item) => !item.includes("undefined"))
              .map((item, index) => ({
                id: index + 1,
                lable: item,
              }))
          ),
        ];

        setAvailableCity(responseAvailableCities);
        setFilterRanges({
          age: {
            //@ts-expect-error
            min: age.min,
            //@ts-expect-error
            max: age.max,
          },
          price: {
            //@ts-expect-error
            min: price.min,
            //@ts-expect-error
            max: price.max,
          },
        });
      }
    };
    makeAsync();
  }, [
    specializations.selectedMainSpec,
    specializations.selectedSubSpec,
    genderFilterValue,
    selectedLangs,
    filterCounter,
    pagginatuions.currentPage,
    selectedCity,
    selectedCountry,
    search,
    realTimeSessionsChecked,
    updateOverlaps,
  ]);

  const handleClickOutside = (event: any) => {
    const isInsideFilterMenu =
      filterRef.current && filterRef.current.contains(event.target);
    const isInsideSelect = event.target.closest(".multi-select-menu");
    const isInsideSelect2 = event.target.closest(".MuiModal-backdrop");

    if (
      !isInsideFilterMenu &&
      !isInsideSelect &&
      !isInsideSelect2 &&
      !event.target.classList.contains(s.menuIcon)
    ) {
      setIsFilterOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && specializations && specializations.selectedMainSpec) {
        const mainSpec = Array.isArray(specializations.selectedMainSpec)
          ? specializations.selectedMainSpec.map(
              (specialization: string) =>
                allMainSpecializations.find(
                  (spec) => spec.specialization === specialization
                )?._id
            )
          : [];
        if (!mainSpec[0]) return;

        const response = await SpecialistApi.getSubSpecializations(
          token,
          //@ts-expect-error
          mainSpec
        );

        if (response.status && response.subSpecializations) {
          const subSpecData = response.subSpecializations.map((item) => ({
            value: item.specialization,
            lable: item.specialization,
          }));
          setSpecializations(
            (prev) => prev && { ...prev, subSpecData: subSpecData }
          );
        }
      }
    };
    makeAsync();
  }, [specializations?.selectedMainSpec]);

  useEffect(() => {
    if (
      !specializations.selectedMainSpec ||
      !specializations.selectedMainSpec.value ||
      !specializations.selectedMainSpec.value.length
    ) {
      setSpecializations({ mainSpecData: specializations.mainSpecData });
    }
  }, [specializations.selectedMainSpec]);

  return (
    <div className={s.container}>
      <div className={s.filterBlock}>
        <div className={s.searchInputBlock}>
          {/* <StandartInput
            placeholder={strings.filterSearchPlaceHolder}
            fontSize="14px"
            value={search}
            onChangeFunc={(value) => setSearch(value)}
            height="40px"
          /> */}
          <Input
            inputValue={search}
            isVisible
            required
            onChangeInput={(value) => setSearch(value)}
            placeholder={strings.filterSearchPlaceHolder}
          />
          <img src={MagnifierIcon} alt="" className={s.magnifierIcon} />
        </div>
        <div className={s.filterButton} ref={filterRef}>
          <div
            className={isFilterOpen ? s.rectangle : s.circle}
            onClick={() => setIsFilterOpen(true)}
          >
            {isFilterOpen ? (
              <div className={s.inputsFilterBlock}>
                <div className={s.columnFilters}>
                  <div className={s.inputFilter}>
                    <ReactFlagsSelect
                      countries={availableCountry}
                      className={s.countrySelect}
                      selected={selectedCountry.code}
                      onSelect={(code) => {
                        setSelectedCountry({
                          code: code,
                          title: countries.getName(code, "en") as string,
                        });
                      }}
                      searchable
                      placeholder={
                        selectedCountry.code.length ? "" : strings.filterCountry
                      }
                    />
                    <span
                      className={s.closeIconBlock}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCountry({ code: "", title: "" });
                      }}
                    >
                      <CloseIcon className={s.closeIcon} />
                    </span>
                  </div>
                  <div className={s.inputFilter}>
                    {availableCity && (
                      <MultipleSelect
                        data={availableCity.map((item) => ({
                          value: item.lable,
                          lable: item.lable,
                        }))}
                        setValue={(value) => {
                          setSelectedCity(value);
                        }}
                        value={selectedCity}
                        multiplie={true}
                        lable={strings.filterCities}
                        lableColor="red"
                        disabled={
                          selectedCountry &&
                          selectedCountry.code !== "" &&
                          availableCity.length
                            ? false
                            : true
                        }
                      />
                    )}
                  </div>
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={languagesList}
                      setValue={(value) => {
                        typeof value === "string"
                          ? setSelectedLangs([value])
                          : setSelectedLangs(value);
                      }}
                      value={selectedLangs}
                      multiplie={true}
                      lable={strings.filterLanguages}
                      lableColor="red"
                    />
                  </div>
                  <div className={s.inputRangeFilter}>
                    <div className={s.rangeTitleBlock}>
                      <span>{strings.filterAge} </span>
                      <button
                        className={
                          /*  !checkRangeValue(
                            ageRangeFilter.value.from,
                            ageRangeFilter.value.to,
                            100,
                            18
                          )
                            ? s.btnDisabled
                            : */ s.rangeokButton
                        }
                        onClick={() =>
                          /* checkRangeValue(
                            ageRangeFilter.value.from,
                            ageRangeFilter.value.to,
                            100,
                            18
                          ) && */ setFilterCounter((prev) => prev + 1)
                        }
                      >
                        OK
                      </button>
                    </div>
                    <div className={s.rangeNumberInputsBlock}>
                      <input
                        type="number"
                        value={
                          ageRangeFilter.value.from
                            ? ageRangeFilter.value.from
                            : ""
                        }
                        max={ageRangeFilter.range.max}
                        min={ageRangeFilter.range.min}
                        onChange={(e) =>
                          setAgeRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: Number(e.target.value),
                              to: ageRangeFilter.value.to,
                            },
                          }))
                        }
                      />
                      <input
                        type="number"
                        value={
                          ageRangeFilter.value.to ? ageRangeFilter.value.to : ""
                        }
                        max={ageRangeFilter.range.max}
                        min={ageRangeFilter.range.min}
                        onChange={(e) =>
                          setAgeRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: ageRangeFilter.value.from,
                              to: Number(e.target.value),
                            },
                          }))
                        }
                      />
                    </div>
                    <RangeSlider
                      minDistance={1}
                      setValue={setAgeRangeFilter}
                      value={[
                        ageRangeFilter.value.from,
                        ageRangeFilter.value.to,
                      ]}
                      min={filterRanges.age.min}
                      max={filterRanges.age.max}
                    />
                  </div>
                </div>
                <div className={s.columnFilters}>
                  {specializations && specializations.mainSpecData && (
                    <div className={s.inputFilter}>
                      <MultipleSelect
                        data={
                          specializations.mainSpecData
                            ? specializations.mainSpecData
                            : []
                        }
                        setValue={(value) => {
                          setSpecializations((prev) => {
                            return {
                              ...prev,
                              selectedMainSpec: {
                                value: value,
                                label: specializations.mainSpecData.find(
                                  (item) => item.value === value
                                )?.lable!,
                              },
                              subSpecData: [],
                            };
                          });
                        }}
                        value={
                          specializations.selectedMainSpec?.value
                            ? specializations.selectedMainSpec?.value
                            : []
                        }
                        multiplie={true}
                        lable={strings.filterMainSpec}
                        lableColor="red"
                      />{" "}
                    </div>
                  )}
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={
                        specializations.subSpecData
                          ? specializations.subSpecData
                          : []
                      }
                      setValue={(value) => {
                        setSpecializations((prev) => ({
                          ...prev,
                          selectedSubSpec: {
                            value: value,
                            label: specializations.subSpecData?.find(
                              (item) => item.value === value
                            )?.lable!,
                          },
                        }));
                      }}
                      value={
                        specializations.selectedSubSpec?.value
                          ? specializations.selectedSubSpec?.value
                          : []
                      }
                      multiplie={true}
                      lable={strings.filterSubSpec}
                      lableColor="red"
                      disabled={
                        specializations.selectedMainSpec &&
                        specializations.selectedMainSpec.value &&
                        specializations.selectedMainSpec.value.length
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={
                        availableGenders ? availableGenders : genderSelectData
                      }
                      setValue={(value) => {
                        setGenderFilterValue(value);
                      }}
                      value={genderFilterValue}
                      multiplie={true}
                      lable={strings.filterGender}
                      lableColor="red"
                    />{" "}
                  </div>
                  <div className={s.inputRangeFilter}>
                    <div className={s.rangeTitleBlock}>
                      <span>{strings.filterPrice} </span>
                      <button
                        className={
                          /*   !checkRangeValue(
                            priceRangeFilter.value.from,
                            priceRangeFilter.value.to,
                            1000,
                            1
                          ) */
                          /*  ? s.btnDisabled
                            :  */ s.rangeokButton
                        }
                        onClick={() =>
                          /*  checkRangeValue(
                            priceRangeFilter.value.from,
                            priceRangeFilter.value.to,
                            1000,
                            1
                          ) &&  */ setFilterCounter((prev) => prev + 1)
                        }
                      >
                        OK
                      </button>
                    </div>
                    <div className={s.rangeNumberInputsBlock}>
                      <input
                        type="number"
                        value={
                          priceRangeFilter.value.from
                            ? priceRangeFilter.value.from
                            : ""
                        }
                        max={priceRangeFilter.range.max}
                        min={priceRangeFilter.range.min}
                        onChange={(e) =>
                          setPriceRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: Number(e.target.value),
                              to: priceRangeFilter.value.to,
                            },
                          }))
                        }
                      />
                      <input
                        type="number"
                        value={
                          priceRangeFilter.value.to
                            ? priceRangeFilter.value.to
                            : ""
                        }
                        max={priceRangeFilter.range.max}
                        min={priceRangeFilter.range.min}
                        onChange={(e) =>
                          setPriceRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: priceRangeFilter.value.from,
                              to: Number(e.target.value),
                            },
                          }))
                        }
                      />
                    </div>
                    <RangeSlider
                      minDistance={1}
                      setValue={setPriceRangeFilter}
                      value={[
                        priceRangeFilter.value.from,
                        priceRangeFilter.value.to,
                      ]}
                      max={filterRanges.price.max}
                      min={filterRanges.price.min}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <img src={FilterIcon} alt="" className={s.filterIcon} />
            )}
          </div>
        </div>
        <div className={s.realTimeSwitchBlock}>
          <MuiSwitch
            lable={strings.filterRealTimeSession}
            lablePlacement="start"
            checked={
              realTimeSessionsChecked !== null ? realTimeSessionsChecked : false
            }
            setChecked={setRealTimeSessionsChecked}
          />
        </div>
      </div>
      {isLoader ? (
        <div className={s.loaderBlock}>
          <ThemeProvider theme={theme}>
            <CircularProgress size={150} color="primary" />
          </ThemeProvider>
        </div>
      ) : (
        <div className={s.specialistsListBlock}>
          {specialistData && specialistData.length ? (
            specialistData.map((item, index) => (
              <div key={item._id} className={s.specialistCardContainer}>
                <SpecialistCard
                  overlapDays={
                    overLapsDays
                      ? overLapsDays.filter(
                          (overlap) => overlap.specialist === item._id
                        )
                      : []
                  }
                  fullName={item.name + " " + item.surname}
                  languages={
                    item.preferredLanguages ? item.preferredLanguages : []
                  }
                  location={item.location ? item.location : ""}
                  mainSpeciality={
                    item.specialistId?.mainSpecializations[0]
                      ? item.specialistId?.mainSpecializations[0].labels.find(
                          (item) => item.language === userData?.selectedLanguage
                        )?.text!
                      : ""
                  }
                  price={
                    item.specialistId?.price ? item.specialistId?.price : 0
                  }
                  rating={item.specialistId?.rating}
                  userId={item._id}
                  yearExp={
                    item.specialistId?.yearsOfExperience
                      ? item.specialistId?.yearsOfExperience
                      : 0
                  }
                  realTimeSessions={
                    item.specialistId
                      ? item.specialistId.receiveRealTimeOffers
                      : false
                  }
                  setUpdateOverlaps={setUpdateOverlaps}
                  discountPrice={item.specialistId!.discountPrice}
                />
              </div>
            ))
          ) : (
            <div className={s.noSpecialistBlock}>
              <NoSpecIcon />
              <h1>{strings.noSpecFound}</h1>
            </div>
          )}
          {specialistData &&
            findDifferenceToMakeMultiple(
              specialistData.length,
              window.innerWidth > 1600 ? 4 : 3
            ) > 0 &&
            Array.from(
              {
                length: findDifferenceToMakeMultiple(
                  specialistData.length,
                  window.innerWidth > 1600 ? 4 : 3
                ),
              },
              (_, index) => index
            ).map((item, index) => (
              <div key={index} className={s.emptySpecBlock}></div>
            ))}
          {specialistData && specialistData.length && (
            <div className={s.pagginationBlock}>
              <ThemeProvider theme={theme}>
                <Pagination
                  page={pagginatuions.currentPage}
                  count={pagginatuions.total}
                  /*    variant="outlined" */
                  color="primary"
                  onChange={(e, page) =>
                    setPagginations((prev) => ({ ...prev, currentPage: page }))
                  }
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      )}

      {/*    <ToastContainer /> */}
    </div>
  );
};

export default SpecialistFindPage;
