import React, { useContext, useEffect, useState } from "react";
import s from "./ChatContactElement.module.css";
import Photo from "../../../assets/SpecialistCard/photo.jpg";
import UsersApi from "../../../api/usersApi";
import { ContextProvider } from "../../../contextProvider";
import { formatDateTimeAgo } from "../../../utils/dateActions";

type ChatContactElementProps = {
  userId: string;
  avatar: string;
  lastMessage: string | null;
  fullname: string;
  dateLastMessage: Date | null | undefined;
  unreadMessages: number;
  isSelectedChat: boolean;
};

const ChatContactElement = ({
  lastMessage,
  fullname,
  dateLastMessage,
  userId,
  unreadMessages,
  avatar,
  isSelectedChat,
}: ChatContactElementProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);

  return (
    <div className={`${s.container} ${isSelectedChat ? s.selectedChat : ""}`}>
      <div className={s.leftContactInfo}>
        {avatar ? (
          <img src={avatar} alt="" className={s.avatar} />
        ) : (
          <span className={s.emptyAvatarBlock}>
            {fullname.split(" ")[0][0]}
            {fullname.split(" ")[1][0]}
          </span>
        )}
        <div className={s.nameTitleBlock}>
          <span>{fullname}</span>
          <span>{lastMessage}</span>
        </div>
      </div>
      <div className={s.rightContactBlock}>
        {dateLastMessage && (
          <span>
            {formatDateTimeAgo(
              new Date(dateLastMessage),
              userData!.selectedLanguage
            )}
          </span>
        )}
        {unreadMessages !== 0 && (
          <span className={s.newMessageIndicator}>{unreadMessages}</span>
        )}
      </div>
      {isSelectedChat && <div className={s.selectIndicator}></div>}
    </div>
  );
};

export default ChatContactElement;
