import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./RecommendetionCard.module.css";
import dayjs from "dayjs";
import StandartButton from "../StandartButton/StandartButton";
import ModalWindow from "../ModalWindow/ModalWindow";
import "./RecommendetionCard.css";
import { ContextProvider } from "../../contextProvider";
import SessionsApi from "../../api/sessionApi";
import Notify, { SuccesNotify } from "../../utils/toaster";

import SelectSessionDate from "../SelectSessionDate/SelectSessionDate";
import { OverLaps } from "../../types/TypeSpecialist";
import DialogChatAPI from "../../api/dialogChatApi";
import { createChat } from "../../utils/general";
import strings from "../../localization";
import UsersApi from "../../api/usersApi";
import { ReactComponent as Token } from "../../assets/Header/heart-tick.svg";

type RecommendetionCardProps = {
  userId: string;
  photo: any;
  name: string | undefined;
  surname: string | undefined;
  experience: number | undefined;
  practicHours: number | undefined;
  price: number | undefined;
  specializations: string[] | undefined;
  width?: string;
  height?: string;
  briefDescription: string | undefined;
  specialistUserId: string | undefined;
  setIsLoader: Dispatch<SetStateAction<boolean>>;
  overlapDays: OverLaps[] | null;
};

const RecommendetionCard = ({
  photo,
  name,
  surname,
  experience,
  practicHours,
  price,
  specializations,
  width,
  height,
  briefDescription,
  specialistUserId,
  setIsLoader,
  overlapDays,
  userId,
}: RecommendetionCardProps) => {
  const token = localStorage.getItem("token");
  const { userData, setIsSetupProfileInfo } = useContext(ContextProvider);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [overlapTime, setOverlapTime] = useState<{ start: Date; end: Date }[]>([
    { start: new Date(), end: new Date() },
  ]);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(
    dayjs().set("hour", 15).set("minute", 30)
  );
  const [selectSpecialistOverLapDays, setSelectSpecialistOverLapDays] =
    useState<OverLaps | null>(null);
  const [avatar, setAvatar] = useState<string | null>(null);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setSelectedDate(date);
    if (date) {
      setSelectedTime(date.set("hour", 15).set("minute", 30));
    }
  };

  const bookingHandler = async (
    sessionBookingData: { date: Date | string; duration: number }[]
  ) => {
    if (token && userData && userData._id && specialistUserId && selectedTime) {
      const response = await SessionsApi.bookSession(
        {
          datesAndTime: sessionBookingData,
          specialistUserId: specialistUserId,
          userId: userData._id,
        },
        token
      );
      if (!response.status && !response.notEnoughBalance) {
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (!response.status && response.notEnoughBalance && response.message) {
        Notify(response.message);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (response.status) {
        localStorage.removeItem("specUsername");
        createChat(userData, userId);
        setIsLoader(true);
        setModalIsOpen(false);
        SuccesNotify(strings.bookingSucces);

        await UsersApi.updateUserData(token, {
          _id: userData._id,
          registerFinished: true,
        });
        setTimeout(() => {
          setIsLoader(false);
          setIsSetupProfileInfo(true);
        }, 2000);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
    }
    return { status: false, notEnoughBalance: false };
  };
  useEffect(() => {
    if (overlapDays) {
      const currentOverlapDays = overlapDays.filter(
        (obj) => obj.specialist === specialistUserId
      );
      const allOverlaps: { start: Date; end: Date }[] =
        currentOverlapDays.flatMap((overlap) =>
          overlap.overlaps.flatMap((dayOverlap) => dayOverlap.overlapTime)
        );
      setOverlapTime(allOverlaps);
    }
  }, [overlapDays]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userId) return;
      const response = await UsersApi.getUserAvatarAndUsername(token, userId);
      if (response.avatar) {
        setAvatar(response.avatar);
      }
    };
    makeAsync();
  }, [userId]);

  return (
    <div className={s.container}>
      <div className={s.avatarBlock}>
        {avatar && avatar.length ? (
          <img src={avatar} alt="" />
        ) : (
          <span className={s.emptyAvatarBlock}>
            {userData?.name && userData?.name[0]}
            {userData?.surname && userData?.surname[0]}
          </span>
        )}
      </div>
      <div className={s.specialistInfoBlock}>
        <div className={s.nameBlock}>
          {name} {surname}
        </div>
        <div className={s.specializationBlock}>
          {specializations &&
            specializations
              .slice(0, 3)
              .map((item) => <span key={item}>{item}</span>)}
        </div>
        <div className={s.experienceBlock}>
          <div>
            <span>{experience}+</span>
            <span> {strings.specReccomendationExp}</span>
          </div>
          <div>
            <span>{practicHours}+</span>
            <span> {strings.specRecommendationHours}</span>
          </div>
        </div>
        <div className={s.briefBlock}>{briefDescription}</div>
        <div className={s.priceBlock}>
          <div className={s.priceValueBlock}>
            <span>{price}</span>
            <Token />
            <span> {strings.recommendationPerHour}</span>
          </div>
          <div>
            <StandartButton
              buttonTitle={strings.recommendationBookSessionBtn}
              action={() => setModalIsOpen(true)}
              height="40px"
            />
          </div>
        </div>
      </div>
      <ModalWindow
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        width={window.innerWidth > 600 ? "fit-content" : "90%"}
        padding={window.innerWidth > 600 ? "20px 45px" : "10px"}
      >
        <SelectSessionDate
          avatar={photo}
          fullname={name + " " + surname}
          specialization={specializations ? specializations[0] : ""}
          showDateInputs={false}
          bookingHandler={bookingHandler}
          overlapTime={overlapTime}
          price={Number(price)}
          userId={userId}
          setBookingModal={setModalIsOpen}
        />
      </ModalWindow>
    </div>
  );
};

export default RecommendetionCard;
