import React, { Dispatch, SetStateAction } from "react";
import s from "../AboutYourself.module.css";
import strings from "../../../../localization";

type AboutSubStep1Props = {
  textareaValue: string;
  setTextareaValue: Dispatch<SetStateAction<string>>;
  maxLenght: number;
};

const AboutSubStep1 = ({
  textareaValue,
  setTextareaValue,
  maxLenght,
}: AboutSubStep1Props) => {
  return (
    <div className={s.subStepBlock}>
      <div className={s.infoTitleBlock}>
        <p className={s.stepTitle}>{strings.setupAboutTitle}</p>
        <p className={s.substepText}>{strings.setupAboutDescription}</p>
        <ul className={s.ul}>
          <li>{strings.setupAboutListElement1}</li>
          <li>{strings.setupAboutListElement2}</li>
          <li>{strings.setupAboutListElement3}</li>
          <li>{strings.setupAboutListElement4}</li>
          <li>{strings.setupAboutListElement5}</li>
        </ul>
      </div>
      <div className={s.textAreaBlock}>
        <textarea
          maxLength={maxLenght}
          value={textareaValue}
          placeholder={strings.setupAboutPlaceHodler}
          className={s.textArea}
          onChange={(e) => setTextareaValue(e.target.value)}
        ></textarea>
        <p>
          {textareaValue.length}/{maxLenght}
        </p>
      </div>
    </div>
  );
};

export default AboutSubStep1;
