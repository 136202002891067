export const popularLanguages = [
  "English",
  "Spanish",
  "Mandarin Chinese",
  "Hindi",
  "Arabic",
  "Bengali",
  "Portuguese",
  "Russian",
  "Urdu",
  "Indonesian",
  "German",
  "French",
  "Japanese",
  "Swahili",
  "Korean",
  "Turkish",
  "Italian",
  "Dutch",
  "Vietnamese",
  "Polish",
  "Ukrainian",
  "Thai",
  "Romanian",
  "Greek",
  "Czech",
  "Hungarian",
  "Bulgarian",
  "Finnish",
  "Hebrew",
  "Lithuanian",
  "Latvian",
  "Estonian",
  "Malay",
  "Maltese",
  "Icelandic",
  "Slovak",
  "Slovenian",
  "Albanian",
  "Macedonian",
  "Montenegrin",
  "Bosnian",
  "Serbian",
  "Croatian",
  "Georgian",
  "Armenian",
  "Azerbaijani",
  "Kazakh",
  "Kyrgyz",
  "Turkmen",
  "Uzbek",
  "Tajik",
  "Pashto",
  "Farsi",
  "Kurdish",
  "Tamil",
  "Telugu",
  "Burmese",
  "Lao",
  "Khmer",
  "Filipino",
  "Hausa",
  "Yoruba",
  "Igbo",
  "Zulu",
  "Shona",
  "Swazi",
  "Kinyarwanda",
  "Amharic",
  "Tigrinya",
  "Oromo",
  "Somali",
  "Tswana",
  "Sesotho",
  "Xhosa",
  "Afrikaans",
  "Fijian",
  "Samoan",
  "Tahitian",
  "Maori",
  "Hawaiian",
  "Marshallese",
  "Chamorro",
  "Palauan",
  "Kosraean",
  "Yapese",
  "Nauruan",
  "Kiribati",
  "Tuvaluan",
  "Tongan",
  "Niuean",
  "Cook Islands Maori",
  "Tok Pisin",
  "Hiri Motu",
  "Solomon Islands Pijin",
  "Vanuatu Bislama",
];
