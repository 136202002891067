import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { TypeUserData } from "../types/TypeUsers";

const { socials } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "socials/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SocialApi {
  static async followUser(
    token: string,
    payload: { userIdToFollow: string }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${socials.followUser}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async unfollowUser(
    token: string,
    payload: { userIdToUnfollow: string }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${socials.unfollowUser}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getFollowingPosts(
    token: string,
    limit: number,
    page: number
  ): Promise<
    TypeDefaultResponse & {
      followers?: Pick<TypeUserData, "name" | "surname">[] | null;
    }
  > {
    return await ky
      .get(`${baseURL}${socials.getFollowers}/${limit}/${page}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getFollowings(
    token: string,
    limit: number,
    page: number
  ): Promise<
    TypeDefaultResponse & {
      followers?: Pick<TypeUserData, "name" | "surname">[] | null;
    }
  > {
    return await ky
      .get(`${baseURL}${socials.getFollowings}/${limit}/${page}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async checkIfFollowed(
    token: string,
    userIdToFollow: string
  ): Promise<
    TypeDefaultResponse & {
      followed: boolean;
    }
  > {
    return await ky
      .get(`${baseURL}${socials.checkIfFollowed}/${userIdToFollow}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
