import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as ProfileIcon } from "../../assets/SpecialistSetupInfo/profile.svg";
import s from "./Recommendation.module.css";
import { ContextProvider } from "../../contextProvider";
import SpecialistApi from "../../api/specialistApi";
import { TypeUserData } from "../../types/TypeUsers";
import RecommendetionCard from "../../components/RecommendetionCard/RecommendetionCard";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ReactComponent as ArrowChevron } from "../../assets/SpecialistSetupInfo/arrow-chevron.svg";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ToastContainer } from "react-toastify";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import { OverLaps } from "../../types/TypeSpecialist";
import "./Recommendation.css";
import SpecialistCard from "../../components/SpecialistCard/SpecialistCard";
import UsersApi from "../../api/usersApi";
import strings from "../../localization";

const Recommendation = () => {
  const token = localStorage.getItem("token");
  const { userData, setIsSetupProfileInfo } = useContext(ContextProvider);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const [recommendedData, setRecommendedData] = useState<TypeUserData[] | null>(
    null
  );
  const [halfRecommendedData, setHalfRecommendedData] = useState<{
    firstHalf: TypeUserData[] | null;
    secondHalf: TypeUserData[] | null;
  }>({
    firstHalf: null,
    secondHalf: null,
  });
  const [isLoader, setIsLoader] = useState(false);
  const [overlapDays, setOverlapDays] = useState<OverLaps[] | null>(null);
  const [updateOverlaps, setUpdateOverlaps] = useState(0);
  const skipHandler = async () => {
    if (!userData || !token) return;
    const registerResponse = await UsersApi.updateUserData(token, {
      _id: userData._id,
      registerFinished: true,
    });
    if (registerResponse.status) {
      setIsSetupProfileInfo(true);
    }
    setIsSetupProfileInfo(true);
  };
  useEffect(() => {
    const makeAsync = async () => {
      const token = localStorage.getItem("token");
      if (!token || !userData || !userData._id) return;
      const response = await SpecialistApi.getRecommendedSpecialists(
        userData._id,
        token
      );
      if (!response.recommendations) {
        skipHandler();
      }
      if (response.status) {
        setRecommendedData(response.recommendations);
        setOverlapDays(response.overlapDays);
      }
    };
    makeAsync();
  }, []);
  useEffect(() => {
    if (recommendedData) {
      const midIndex = Math.ceil(recommendedData.length / 2);
      const firstHalf = recommendedData.slice(0, midIndex);
      let secondHalf = recommendedData.slice(midIndex);
      if (secondHalf.length % 2 !== 0) {
        secondHalf.push({
          _id: "",
          email: "",
          isAdmin: false,
          password: "",
          registerConfirmed: false,
          username: "",
          bookings: [],
          timezone: "",
          selectedLanguage: "en",
          balance: 0,
        });
      }

      setHalfRecommendedData({ firstHalf: firstHalf, secondHalf: secondHalf });
    }
  }, [recommendedData]);

  if (isLoader) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.headerContainer}>
        <div></div>
        <h3></h3>
        <div className={s.profileIconBlcok}>
          <ProfileIcon className={s.profileIcon} />
        </div>
      </div>
      <div className={s.mainContent}>
        <div className={s.sliderContainer}>
          {recommendedData && recommendedData.length > 2 && (
            <button
              className={`custom-prev ${s.customPrev}`}
              ref={prevButtonRef}
            >
              <ArrowChevron className={s.arrowLeft} />
            </button>
          )}
          <div className={s.swiperBlock}>
            <Swiper
              navigation={{
                nextEl: nextButtonRef.current,
                prevEl: prevButtonRef.current,
              }}
              modules={[Navigation]}
              className={s.swiper}
              spaceBetween={20}
              slidesPerView={1}
              allowTouchMove={window.innerWidth < 1300 ? true : false}
            >
              {halfRecommendedData.firstHalf &&
                halfRecommendedData.firstHalf.map((item, index) => (
                  <SwiperSlide key={index}>
                    <RecommendetionCard
                      userId={item._id}
                      setIsLoader={setIsLoader}
                      overlapDays={overlapDays}
                      experience={item.specialistId?.yearsOfExperience}
                      name={item.name}
                      surname={item.surname}
                      photo={item.avatar}
                      practicHours={item.specialistId?.practiceHours}
                      price={item.specialistId?.price}
                      specializations={item.specialistId?.mainSpecializations.map(
                        (item) => item.specialization
                      )}
                      briefDescription={item.specialistId?.briefDescription}
                      specialistUserId={item._id}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
              navigation={{
                nextEl: nextButtonRef.current,
                prevEl: prevButtonRef.current,
              }}
              modules={[Navigation]}
              className={s.swiper}
              spaceBetween={20}
              slidesPerView={1}
              allowTouchMove={window.innerWidth < 1300 ? true : false}
            >
              {halfRecommendedData.secondHalf &&
                halfRecommendedData.secondHalf.map((item, index) => (
                  <SwiperSlide key={index}>
                    {item._id === "" ? (
                      <div></div>
                    ) : (
                      <RecommendetionCard
                        userId={item._id}
                        setIsLoader={setIsLoader}
                        overlapDays={overlapDays}
                        experience={item.specialistId?.yearsOfExperience}
                        name={item.name}
                        surname={item.surname}
                        photo={item.avatar}
                        practicHours={item.specialistId?.practiceHours}
                        price={item.specialistId?.price}
                        specializations={item.specialistId?.mainSpecializations.map(
                          (item) => item.specialization
                        )}
                        briefDescription={item.specialistId?.briefDescription}
                        specialistUserId={item._id}
                      />
                    )}
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className={s.swiperBlockMobile}>
            <Swiper
              navigation={{
                nextEl: nextButtonRef.current,
                prevEl: prevButtonRef.current,
              }}
              modules={[Navigation, Pagination]}
              className={s.swiper}
              spaceBetween={20}
              slidesPerView={1}
              allowTouchMove={true}
              pagination={{
                dynamicBullets: true,
              }}
            >
              {recommendedData &&
                recommendedData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <SpecialistCard
                      isRecommendetionCard={true}
                      fullName={
                        item.name && item.surname
                          ? `${item.name} ${item.surname}`
                          : ""
                      }
                      languages={
                        item.preferredLanguages ? item.preferredLanguages : []
                      }
                      location={item.location ? item.location : ""}
                      mainSpeciality={
                        item.specialistId?.mainSpecializations[0]
                          ? item.specialistId?.mainSpecializations[0]
                              .specialization
                          : ""
                      }
                      overlapDays={overlapDays!}
                      price={item.specialistId?.price!}
                      rating={4.5}
                      userId={item._id}
                      yearExp={item.specialistId?.yearsOfExperience!}
                      realTimeSessions={
                        item.specialistId
                          ? item.specialistId.receiveRealTimeOffers
                          : false
                      }
                      setUpdateOverlaps={setUpdateOverlaps}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className={s.swiperBlockTablet}>
            {recommendedData &&
              recommendedData.map((item, index) => (
                <div className={s.tabletRecomendedCard} key={item._id}>
                  <RecommendetionCard
                    userId={item._id}
                    setIsLoader={setIsLoader}
                    overlapDays={overlapDays}
                    experience={item.specialistId?.yearsOfExperience}
                    name={item.name}
                    surname={item.surname}
                    photo={item.avatar}
                    practicHours={item.specialistId?.practiceHours}
                    price={item.specialistId?.price}
                    specializations={item.specialistId?.mainSpecializations.map(
                      (item) => item.specialization
                    )}
                    briefDescription={item.specialistId?.briefDescription}
                    specialistUserId={item._id}
                  />
                </div>
              ))}
          </div>
          {recommendedData && recommendedData.length > 2 && (
            <button
              className={`custom-next ${s.customNext}`}
              ref={nextButtonRef}
            >
              <ArrowChevron className={s.arrowRight} />
            </button>
          )}
        </div>
      </div>
      <div className={s.skipBlock}>
        <StandartButton
          buttonTitle={strings.skipBtn}
          action={skipHandler}
          width="120px"
          height="40px"
        />
      </div>
    </div>
  );
};

export default Recommendation;
