function getWeek(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

function getDatesInWeek(date: Date): Date[] {
  const dayOfWeek = date.getDay();
  const startDate = new Date(date.getTime() - dayOfWeek * 86400000); // Get start date of the week
  const endDate = new Date(startDate.getTime() + 6 * 86400000); // Get end date of the week

  const datesInWeek: Date[] = [];
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    datesInWeek.push(new Date(d));
  }

  return datesInWeek;
}

export function drawDatesInWeek(date: Date): Date[] {
  const weekNumber = getWeek(date);
  const datesInWeek = getDatesInWeek(date);

  return datesInWeek;
}
