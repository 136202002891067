import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./EditSpecialistProfile.module.css";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import ReactFlagsSelect from "react-flags-select";
import strings from "../../../../localization";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import {
  TypeUpdatedUserInfo,
  TypeUserData,
  UserSchedule,
} from "../../../../types/TypeUsers";
import CityPicker, {
  PlaceType,
} from "../../../../components/CitySelect/CitySelect";
import { ContextProvider } from "../../../../contextProvider";
import { popularLanguages } from "../../../../constans/languagesList";
import EditIcon from "../../../../assets/Profile/cloud-plus.svg";
import CircleAvatar from "../../../../components/CircleAvatar/CircleAvatar";
import { ReactComponent as CheckIcon } from "../../../../assets/SpecialistSetupInfo/checked.svg";
import Input from "../../../../components/Input/Input";
import countries from "i18n-iso-countries";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import localizationJson from "../../../../localization.json";
import UsersApi from "../../../../api/usersApi";
import { Tooltip } from "@mui/material";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import NotificationsApi from "../../../../api/notifications";
import { SpecialistData } from "../../../../types/TypeSpecialist";
import { CertificatesData } from "../SpecialistProfileContent";
import Notify from "../../../../utils/toaster";
import { ReactComponent as ImportIcon } from "../../../../assets/Profile/specialist/import.svg";
import { downloadFile, formatFileSize } from "../../../../utils/messageChat";
import { ReactComponent as FileIcon } from "../../../../assets/Profile/file-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/Notes/delete.svg";
import { ReactComponent as VerifiedIcon } from "../../../../assets/Profile/specialist/verify.svg";
import { ReactComponent as CircleInfoIcon } from "../../../../assets/Profile/specialist/info-circle.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/Profile/specialist/clock.svg";
import SpecialistApi from "../../../../api/specialistApi";
import { transformSchedule } from "../../../../utils/shedule";
import Schedule from "../../../../components/Schedule/Schedule";
import MuiSwitch from "../../../../components/MuiSwitch/MuiSwitch";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import NewShedule from "../../../../components/Schedule/NewShedule";
import ChangeSpecialistTags from "./ChangeSpecialistTags/ChangeSpecialistTags";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

interface ServerData {
  [key: string]: { time: string[]; switch: boolean };
}

type MainSpecialiszationsData = {
  value: string;
  lable: string;
};
type ScheduleType = {
  dayValue: string;
  daytitle: string;
  isOpen: boolean;
  switch: boolean;
  workTime: { from: string; to: string }[];
};

type EditProfileProps = {
  userUpdateData: TypeUpdatedUserInfo;
  profileUserData: TypeUserData | undefined | null;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  setAvatar: Dispatch<SetStateAction<string | null>>;
  avatar: string | null;
  updateData: () => void;
  calendarIsConnected: boolean;
  specialistUpdateData: TypeUpdatedUserInfo;
  specialistData: SpecialistData | undefined;
  setSpecialistUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  certificatesData: CertificatesData[];
  setCertificatesData: Dispatch<SetStateAction<CertificatesData[]>>;
  setNewCertificates: Dispatch<SetStateAction<File[]>>;
  newCertificates: File[];
  setVideo: Dispatch<SetStateAction<File | null>>;
  daysState: ScheduleType[];
  setDaysState: Dispatch<SetStateAction<ScheduleType[]>>;
  video: File | null;
  setAvatarFile: Dispatch<SetStateAction<File | undefined>>;
  processServerData: (data: ServerData) => void;
};

export const EditSpecialistProfile = ({
  userUpdateData,
  profileUserData,
  setUserUpdateData,
  setAvatar,
  avatar,
  updateData,
  calendarIsConnected,
  specialistUpdateData,
  specialistData,
  setSpecialistUpdateData,
  certificatesData,
  setCertificatesData,
  setNewCertificates,
  newCertificates,
  setVideo,
  daysState,
  setDaysState,
  video,
  setAvatarFile,
  processServerData,
}: EditProfileProps) => {
  const token = localStorage.getItem("token");
  const { userData, setSelectLang, setUserData, selectLang } =
    useContext(ContextProvider);
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [availableSiteLanguages, setAvailableSiteLanguages] = useState<
    { lable: string; value: string }[] | null
  >(null);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [selectedCity, setSelectedCity] = useState<PlaceType | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    title: string;
  }>({ code: "", title: "" });

  const [langages, setLangages] = useState<string | string[]>([]);
  const [mainSpecializationsData, setMainSpecializationsData] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [checkedSpecializations, setCheckedSpecializations] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [realTimeSessionsChecked, setRealTimeSessionsChecked] = useState<
    boolean | null
  >(null);
  const [videoTitle, setVideoTitle] = useState<string | null>(null);
  const [selectedScheduleDay, setSelectedScheduleDay] =
    useState<ScheduleType | null>(null);
  const [sheduleModalIsOpen, setSheduleModalIsOpen] = useState(false);

  useEffect(() => {
    if (!selectedScheduleDay) return;
    setSheduleModalIsOpen(true);
  }, [selectedScheduleDay]);

  const onChangeSpecialistDataText = (
    value: any,
    objKey: keyof TypeUpdatedUserInfo
  ) => {
    setSpecialistUpdateData((prev) => ({
      ...prev,
      [objKey]: value ? value : " ",
    }));
  };

  const onChangeUserDataText = (
    value: any,
    objKey: keyof TypeUpdatedUserInfo,
    maxLength?: number
  ) => {
    let trimmedValue = value.trim();
    if (maxLength && trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.substring(0, maxLength);
    }

    setUserUpdateData((prev) => ({
      ...prev,
      [objKey]: trimmedValue,
    }));
  };
  const languagesList = popularLanguages.map((item) => ({
    value: item,
    lable: item,
  }));

  const languageNames: { [key: string]: string } = {
    en: "English",
    uk: "Українська",
  };

  useEffect(() => {
    if (
      selectedCity?.terms[0].value &&
      selectedCity?.terms[selectedCity?.terms.length - 1].value
    )
      setUserUpdateData((prev) => ({
        ...prev,
        location: selectedCity?.terms[0].value + ", " + selectedCountry.title,
      }));
  }, [selectedCity]);
  useEffect(() => {
    setSelectedCity(null);
  }, [selectedCountry]);

  useEffect(() => {
    const languageArray: {
      lable: string;
      value: string;
    }[] = Object.keys(localizationJson).map((key) => ({
      lable: languageNames[key],
      value: key,
    }));

    setAvailableSiteLanguages(languageArray);
  }, [localizationJson]);

  useEffect(() => {
    if (
      profileUserData &&
      profileUserData.specialistId &&
      profileUserData.specialistId.introductionVideo
    ) {
      const fileName = profileUserData.specialistId.introductionVideo.filename;
      setVideoTitle(fileName);
    }
    if (video) {
      setVideoTitle(video.name);
    }
  }, [video]);

  const handleSelectLang = (value: string | string[]) => {
    setLangages(value);
    setUserUpdateData((prev) => ({ ...prev, preferredLanguages: value }));
  };
  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        setAvatarFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) setAvatar(e.target.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  useEffect(() => {
    setUserUpdateData((prev) => ({ ...prev, dateOfBirth: dateOfBirth }));
  }, [dateOfBirth]);

  useEffect(() => {
    if (profileUserData && profileUserData.dateOfBirth) {
      setDateOfBirth(new Date(profileUserData.dateOfBirth));
    }
    if (profileUserData && profileUserData.preferredLanguages)
      setLangages(profileUserData.preferredLanguages);
  }, [profileUserData]);

  useEffect(() => {
    if (!userData || !userData.location) return;
    const country = userData.location.split(", ")[1];
    const userCountry = countries.getAlpha2Code(country, "en");
    setSelectedCountry({
      code: userCountry ? userCountry : "",
      title: country,
    });
  }, [userData]);

  const changeLangHandler = async (value: string) => {
    if (!token || !userData) return;
    const resposne = await UsersApi.updateUserData(token, {
      _id: userData._id,
      selectedLanguage: value,
    });

    if (resposne.status) {
      setUserData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          selectedLanguage: value,
        };
      });
      setSelectLang(value);
    }
  };

  const connectGoogleCalendar = async () => {
    if (!token) return;
    const response = await NotificationsApi.generateCode(token);
    if (response.status && response.url) {
      window.open(response.url);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles) {
      const totalFiles =
        (certificatesData ? certificatesData.length : 0) + newFiles.length;

      if (totalFiles <= 20) {
        const addedFiles = newFiles
          ? Array.from(newFiles).map((file) => ({
              file: URL.createObjectURL(file),
              filename: file.name,
              type: file.type,
              isNewFile: true,
              size: file.size,
            }))
          : [];
        /* setCertificatesData((prev) =>
          prev ? [...prev, ...addedFiles] : addedFiles
        ); */

        setNewCertificates((prevFiles) => [
          ...prevFiles,
          ...Array.from(newFiles),
        ]);
      } else {
        Notify(strings.toManyFiles);
      }
    }

    if (newFiles) {
    }
  };

  const removeCretificate = async (certificateId: string) => {
    if (token && userData && userData.specialistId?._id) {
      const payload = {
        certificateId: certificateId,
        specialistId: userData.specialistId._id,
      };
      const response = await SpecialistApi.removeCertificate(token, payload);

      if (response.status) {
        const filteredCertificates = certificatesData.filter(
          (certificate) => certificate._id !== certificateId
        );
        setCertificatesData(filteredCertificates);
      }
    }
  };
  const removeNewCertificate = (fileName: string) => {
    const filteredCertificates = certificatesData.filter(
      (certificate) => certificate.filename !== fileName
    );
    const filtredNewCertificates = newCertificates.filter(
      (certificate) => certificate.name !== fileName
    );
    setNewCertificates(filtredNewCertificates);
    setCertificatesData(filteredCertificates);
  };

  const getSubSpecializations = async (mainSpec: string) => {
    if (token && userData) {
      const subSpecResponse = await SpecialistApi.getSubSpecializations(token, [
        mainSpec,
      ]);

      if (subSpecResponse.status && subSpecResponse.subSpecializations) {
        const subSpecData = subSpecResponse.subSpecializations.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (item) => item.language === userData?.selectedLanguage
          )?.text!,
        }));

        setCheckedSpecializations(subSpecData);
      }
    }
  };

  useEffect(() => {
    if (userData && userData.specialistId) {
      setRealTimeSessionsChecked(userData.specialistId.receiveRealTimeOffers);
    }
    if (specialistData && specialistData.mainSpecializations) {
      getSubSpecializations(
        specialistUpdateData.mainSpecializations
          ? specialistUpdateData.mainSpecializations[0]
          : specialistData.mainSpecializations[0]._id
      );
    }

    const makeAsync = async () => {
      if (token) {
        const mainSpecializationsResponse =
          await SpecialistApi.getMainSpecializations(token);

        if (
          mainSpecializationsResponse.status &&
          mainSpecializationsResponse.mainSpecializations
        ) {
          setMainSpecializationsData(
            mainSpecializationsResponse.mainSpecializations.map((item) => ({
              value: item._id,
              lable: item.labels.find(
                (item) => item.language === userData?.selectedLanguage
              )?.text!,
            }))
          );
        }
      }
    };
    makeAsync();
  }, [userData]);

  useEffect(() => {
    if (!specialistUpdateData || !specialistUpdateData?.mainSpecializations)
      return;
    getSubSpecializations(specialistUpdateData.mainSpecializations);
  }, [specialistUpdateData]);

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setVideo(file || null);
  };

  useEffect(() => {
    const scheduleData = transformSchedule(daysState);

    setSpecialistUpdateData((prev) => ({
      ...prev,

      receiveRealTimeOffers: realTimeSessionsChecked,
    }));
    setUserUpdateData((prev) => ({
      ...prev,
      schedule: scheduleData,
    }));
  }, [daysState, realTimeSessionsChecked]);

  const cancelSheduleChanges = () => {
    if (!userData || !userData.schedule || !selectedScheduleDay) return;
    setSheduleModalIsOpen(false);
    processServerData(userData.schedule);
  };

  const hasValidTimeRange = (
    ranges: {
      from: string;
      to: string;
    }[]
  ): boolean => {
    return ranges.some((range) => range.from !== "" && range.to !== "");
  };

  return (
    <div className={s.container}>
      <div className={s.leftProfileBlock}>
        <div className={s.headerEditBlock}>
          <div
            className={
              specialistData?.isVerified
                ? s.verifiedMessage
                : s.unverifiedMessage
            }
          >
            {specialistData?.isVerified ? (
              <div className={s.verifiedStatusBlock}>
                <VerifiedIcon className={s.verifiedStatusIcon} />{" "}
                {strings.verifiedMessage}
              </div>
            ) : (
              <div className={s.verifiedStatusBlock}>
                <CircleInfoIcon className={s.verifiedStatusIcon} />{" "}
                {strings.unVerifiedmessage}
              </div>
            )}
          </div>
          <button className={s.saveProfileButton} onClick={updateData}>
            <span>{strings.profileSaveBtn}</span>
          </button>
        </div>
        <div className={s.mainEditBlock}>
          <div className={s.avatarBlock}>
            <div style={{ position: "relative" }}>
              <div className={s.avatarImageContainer}>
                {avatar ? (
                  <img src={avatar} alt="" className={s.avatarImg} />
                ) : (
                  <CircleAvatar
                    userId={
                      profileUserData
                        ? profileUserData._id
                        : userData
                        ? userData._id
                        : ""
                    }
                    width="110px"
                    height="110px"
                    fontSize="42px"
                  />
                )}
              </div>

              <label>
                <span className={s.updateAvatarBtn} role="button">
                  <img src={EditIcon} alt="" />
                  <span>{strings.replace}</span>
                </span>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleAvatarChange(e)}
                />
              </label>
            </div>
            <div className={s.switchBlock}>
              <MuiSwitch
                lable={strings.profilerealTimeSessionSwitch}
                lablePlacement="top"
                checked={
                  realTimeSessionsChecked !== null
                    ? realTimeSessionsChecked
                    : false
                }
                setChecked={setRealTimeSessionsChecked}
              />
            </div>
          </div>
          <div className={s.personInfoContainer}>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.firstNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.name
                        ? userUpdateData.name
                        : profileUserData?.name
                    }
                    onChangeInput={(value) =>
                      onChangeUserDataText(value, "name", 32)
                    }
                    isVisible
                    required
                  />
                </div>
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.lastNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.surname
                        ? userUpdateData.surname
                        : profileUserData?.surname
                    }
                    onChangeInput={(value) =>
                      onChangeUserDataText(value, "surname", 32)
                    }
                    isVisible
                    required
                  />
                </div>
              </div>
            </div>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.fromProfileLable}
                  </span>

                  <div className={s.countryBlock}>
                    <ReactFlagsSelect
                      blacklistCountries
                      countries={["RU"]}
                      className={s.countrySelect}
                      selected={selectedCountry.code}
                      onSelect={(code) =>
                        setSelectedCountry({
                          code: code,
                          title: countries.getName(code, "en") as string,
                        })
                      }
                      searchable
                      placeholder={
                        selectedCountry.code.length ? "" : "Select country"
                      }
                    />
                  </div>
                </div>

                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.fromProfileLable}
                  </span>
                  <div className={s.cityPickerBlock}>
                    <CityPicker
                      selectedCity={selectedCity}
                      selectedCountry={selectedCountry.code}
                      onCitySelect={setSelectedCity}
                      defaultValue={userData?.location}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.dateBirthProfileLable}
                  </span>

                  <div className={s.dateOfBirthBlock}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={s.datePicker}
                        value={dayjs(dateOfBirth)}
                        onChange={(value: Dayjs | null) =>
                          setDateOfBirth(value ? value.toDate() : null)
                        }
                      />
                    </LocalizationProvider>{" "}
                    <div></div>
                  </div>
                </div>
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <div>
                    <span className={s.infoLable}>
                      {strings.langProfileLable}
                    </span>

                    <MultipleSelect
                      data={languagesList}
                      setValue={handleSelectLang}
                      value={langages}
                      multiplie={true}
                      padding="10px 20px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.aboutWriteBlock}>
          <h3>{strings.aboutMeSpecProfile}</h3>
          <p className={s.blockDescriptionTitle}>{strings.aboutDescription}</p>
          <textarea
            name=""
            id=""
            cols={30}
            rows={10}
            value={
              !specialistUpdateData.aboutMyself ||
              specialistUpdateData.aboutMyself === ""
                ? specialistData?.aboutMyself
                : specialistUpdateData.aboutMyself
            }
            className={s.textAreaNewNote}
            onChange={(event) =>
              onChangeSpecialistDataText(event.target.value, "aboutMyself")
            }
          ></textarea>
        </div>
        <div className={s.aboutWriteBlock}>
          {specialistData?.aboutMyself && (
            <h3>{strings.howSessionGoesSpecProfile}</h3>
          )}
          <textarea
            name=""
            id=""
            cols={30}
            rows={10}
            value={
              !specialistUpdateData.aboutTherapy ||
              specialistUpdateData.aboutTherapy === ""
                ? specialistData?.aboutTherapy
                : specialistUpdateData.aboutTherapy
            }
            className={s.textAreaNewNote}
            onChange={(event) =>
              onChangeSpecialistDataText(event.target.value, "aboutTherapy")
            }
          ></textarea>
        </div>
        <div className={s.educationBlock}>
          <h3>{strings.educationSpecProfile}</h3>
          <p className={s.blockDescriptionTitle}>{strings.from1To20Diplomas}</p>
          {certificatesData.length < 20 && (
            <label className={s.newCertificate}>
              <input
                type="file"
                style={{ display: "none" }}
                accept=".pdf, image/*"
                multiple
                onChange={handleFileChange}
              />
              <div className={s.importCertBtn}>
                <ImportIcon className={s.importIcon} />{" "}
                <span>{strings.importCertificateDescription}</span>
              </div>
            </label>
          )}
          {certificatesData.map((item, index) => (
            <div key={index} className={s.educationElement}>
              <div className={s.leftEducationCardBlock}>
                <div className={s.educationIconBlock}>
                  <FileIcon
                    className={s.fileIcon}
                    onClick={() => {
                      const filename = item.filename
                        ? item.filename.split(".")[0]
                        : "";
                      const type = item.type ? item.type : "";
                      return downloadFile(item.link, filename, type);
                    }}
                  />
                </div>
                <div className={s.certificateTitleBlock}>
                  <span className={s.certificateTitle}>
                    {item.filename /*  && item.filename.split(".")[0] */}
                  </span>
                  <span>{formatFileSize(item.size!)}</span>
                </div>
              </div>
              {item._id && (
                <DeleteIcon
                  className={s.deleteCertificateButton}
                  onClick={() => removeCretificate(item._id as string)}
                />
              )}
              {!item._id && (
                <DeleteIcon
                  className={s.deleteCertificateButton}
                  onClick={() => removeNewCertificate(item.filename as string)}
                />
              )}
            </div>
          ))}
        </div>
        <div className={s.tagsBlock}>
          <h3>{strings.tagsSpecProfile}</h3>
          <p className={s.blockDescriptionTitle}>{strings.selectTopics}</p>
          <ChangeSpecialistTags
            setSpecialistUpdateData={setSpecialistUpdateData}
          />
        </div>
        {/*   {(userData?._id === profileUserData?._id || !profileUserData?._id) && (
          <div className={s.sessionScheduleBlock}>
            <div className={s.scheduleBlock}>
              <div className={s.headScheduleBlock}>
                <h3>{strings.scheduleSpecProfile}</h3>
              </div>
              <Schedule daysState={daysState} setDaysState={setDaysState} />
            </div>
          </div>
        )} */}
      </div>
      <div className={s.rightProfileBlock}>
        <div className={s.professionalBlock}>
          <div className={s.inputBlock}>
            <span>{strings.presSession}</span>
            <Input
              inputValue={
                !specialistUpdateData.price || specialistUpdateData.price === ""
                  ? specialistData?.price
                  : specialistUpdateData.price
              }
              isVisible
              required
              onChangeInput={(value) =>
                onChangeSpecialistDataText(value, "price")
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfileYearsExp}</span>
            <Input
              inputValue={
                !specialistUpdateData.yearsOfExperience ||
                specialistUpdateData.yearsOfExperience === ""
                  ? specialistData?.yearsOfExperience
                  : specialistUpdateData.yearsOfExperience
              }
              isVisible
              required
              onChangeInput={(value) =>
                onChangeSpecialistDataText(value, "yearsOfExperience")
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfilePracticeHours}</span>
            <Input
              inputValue={
                !specialistUpdateData.practiceHours ||
                specialistUpdateData.practiceHours === ""
                  ? specialistData?.practiceHours
                  : specialistUpdateData.practiceHours
              }
              isVisible
              required
              onChangeInput={(value) =>
                onChangeSpecialistDataText(value, "practiceHours")
              }
            />
          </div>
          <div className={s.qualificationBlock}>
            <h2>{strings.qualification}</h2>
            <div className={s.multiSelectBlock}>
              <span>{strings.filterMainSpec}</span>
              <MultipleSelect
                multiplie={false}
                data={mainSpecializationsData}
                setValue={(value) => {
                  setSpecialistUpdateData((prev) => ({
                    ...prev,
                    subSpecializations: [],
                  }));
                  onChangeSpecialistDataText([value], "mainSpecializations");
                }}
                value={
                  specialistUpdateData.mainSpecializations
                    ? specialistUpdateData.mainSpecializations
                    : specialistData!.mainSpecializations!.map(
                        (item) => item._id
                      )
                }
              />
            </div>
            <div className={s.multiSelectBlock}>
              <span>{strings.filterSubSpec}</span>
              <MultipleSelect
                multiplie={true}
                data={checkedSpecializations}
                setValue={(value) =>
                  onChangeSpecialistDataText(value, "subSpecializations")
                }
                value={
                  specialistUpdateData.subSpecializations
                    ? specialistUpdateData.subSpecializations
                    : specialistData!.subSpecializations!.map(
                        (item) => item._id
                      )
                }
              />
            </div>
          </div>
        </div>
        <div className={s.videoBlock}>
          <h2>{strings.videoTitle}</h2>
          <label>
            <div className={s.editVideoIcon} role="button">
              <ImportIcon className={s.importIcon} />
              <span>{videoTitle ?? "MP4"}</span>
            </div>
            <input
              style={{ display: "none" }}
              type="file"
              accept="video/*"
              onChange={(e) => handleVideoChange(e)}
            />
          </label>
        </div>
        <div className={s.settingsBlock}>
          {availableSiteLanguages && (
            <div className={s.selectLangBlock}>
              <span className={s.infoLable}>{strings.languageTitle}</span>
              <MultipleSelect
                data={availableSiteLanguages}
                multiplie={false}
                setValue={(value) => {
                  changeLangHandler(value as string);
                }}
                value={selectLang ? selectLang : ""}
                width="100%"
              />
            </div>
          )}
          <div className={s.timeZoneSelectBlock}>
            <span className={s.infoLable}>{strings.timezoneProfileLable}</span>

            <MultipleSelect
              data={options.map((option) => ({
                value: option.value,
                lable: option.label,
              }))}
              multiplie={false}
              setValue={(value) => onChangeUserDataText(value, "timezone")}
              value={
                userUpdateData.timezone
                  ? userUpdateData.timezone
                  : profileUserData?.timezone
              }
              padding="10px 20px"
            />
          </div>
          <div className={s.integrationsBlock}>
            <div className={s.infoTitleBlock}>
              <span>{strings.intagrationProfile}</span>
            </div>

            <div className={s.integrationsElement}>
              <p className={s.infoLable}>{strings.connectGoogleProfile}</p>
              <Tooltip
                title={calendarIsConnected ? strings.connectGoogleTooltip : ""}
                placement="right"
                arrow
              >
                <div
                  style={{
                    width: "fit-content",
                    marginTop: "10px",
                  }}
                >
                  <StandartButton
                    buttonTitle={strings.connectProfileBtn}
                    action={connectGoogleCalendar}
                    height="40px"
                    width="120px"
                    disabled={calendarIsConnected}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={s.sheduleBlock}>
          <h2>{strings.timeToBookTitle}</h2>
          <div>
            {daysState && daysState.length
              ? daysState.map((item, index) => (
                  <div className={s.scheduleElement} key={index}>
                    <div
                      className={s.sheduleClockBlock}
                      role="button"
                      onClick={() => {
                        setSelectedScheduleDay(item);
                        const updatedSchedule = daysState.map((day) =>
                          day.dayValue === item.dayValue
                            ? { ...day, isOpen: !day.isOpen }
                            : { ...day, isOpen: false }
                        );
                        setDaysState(updatedSchedule);
                      }}
                    >
                      <ClockIcon />
                      <div className={s.dayTimeBlock}>
                        <span>{item.daytitle}</span>
                        <span>
                          {!hasValidTimeRange(item.workTime) || !item.switch ? (
                            strings.dayOffShedulTitle
                          ) : (
                            <span className={s.workingDayTitleBlock}>
                              {strings.workingDay}
                              <Tooltip
                                title={
                                  <div>
                                    {item.workTime.map((time, index) => (
                                      <div key={index}>
                                        {time.from} - {time.to}
                                      </div>
                                    ))}
                                  </div>
                                }
                                arrow
                              >
                                <span className={s.sheduleTooltip}>!</span>
                              </Tooltip>
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={s.sheduleSwitchBlock}>
                      <MuiSwitch
                        lable=""
                        checked={item.switch}
                        setChecked={(value) => {
                          const updatedSchedule = daysState.map((day) =>
                            day.dayValue === item.dayValue
                              ? { ...day, switch: value }
                              : day
                          );
                          setDaysState(updatedSchedule);
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
          {selectedScheduleDay && (
            <ModalWindow
              isOpen={sheduleModalIsOpen}
              setIsOpen={setSheduleModalIsOpen}
              width="500px"
              padding="0"
              bgColor="transparent"
            >
              <div>
                <div className={s.sheduleSettingsModalBlock}>
                  <NewShedule
                    allDaysState={daysState}
                    dayState={selectedScheduleDay}
                    setDaysState={setDaysState}
                  />
                </div>
                <div className={s.sheduleSettingsModalBtnBlock}>
                  <button
                    onClick={cancelSheduleChanges}
                    className={s.cancelShedulBtn}
                  >
                    {strings.cancelShedulModal}
                  </button>
                  <StandartButton
                    buttonTitle={strings.saveSheduleModal}
                    action={() => setSheduleModalIsOpen(false)}
                    height="42px"
                    width="50%"
                  />
                </div>
              </div>
            </ModalWindow>
          )}
        </div>
      </div>
    </div>
  );
};
