import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  BookedPackage,
  BookSessionPayload,
  BookSessionResponse,
  CommentSessionPayload,
  DefaultComments,
  SessionComment,
  SessionPackage,
  Sessions,
  UsersBookingResponse,
} from "../types/TypeSession";
import { SessionPayment } from "../types/Balance";
import { TimeRange } from "../types/TypeSpecialist";
import { TypeUpdatedUserInfo } from "../types/TypeUsers";
import { TypeGoogleEvent, TypeSystemMessageData } from "../types/General";

const { sessions } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "session/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SessionsApi {
  static async bookSession(
    payload: BookSessionPayload,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      booking?: BookSessionResponse;
      notEnoughBalance: boolean;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.bookSession}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }
  static async getUsersBooking(
    userId: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      bookings?: UsersBookingResponse[];
    }
  > {
    return await ky
      .get(`${baseURL}${sessions.getUsersBooking}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async acceptBooking(
    userId: string,
    bookingId: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      bookings?: UsersBookingResponse[];
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.acceptBooking}/${bookingId}`, {
        headers: getAuthHeader(token),
        json: { userId, bookingId },
      })
      .then((res) => res.json());
  }

  static async getAllUserSessions(
    userId: string,
    token: string,
    startDate?: Date,
    endDate?: Date
  ): Promise<
    TypeDefaultResponse & {
      sessions?: Sessions[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.getAllUserSessions}/${userId}/${startDate}/${endDate}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async commentSession(
    token: string,
    payload: CommentSessionPayload
  ): Promise<
    TypeDefaultResponse & {
      comment?: SessionComment;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.commentSession}/${payload.sessionId}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async cancelBooking(
    token: string,
    bookingId: string
  ): Promise<
    TypeDefaultResponse & {
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.cancelBooking}/${bookingId}`, {
        headers: getAuthHeader(token),
        json: { bookingId },
      })
      .then((res) => res.json());
  }

  static async getSpecialistsComments(
    userId: string,
    limit: number,
    page: number
  ): Promise<
    TypeDefaultResponse & {
      comments: SessionComment[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.getSpecialistsComments}/${userId}/${limit}/${page}`
      )
      .then((res) => res.json());
  }
  static async getSessionVisitDuration(
    token: string,
    sessionId: string
  ): Promise<
    TypeDefaultResponse & {
      sessionLogs?: { _id: string; totalSessionTime: number }[];
    }
  > {
    return await ky
      .get(`${baseURL}${sessions.getSessionVisitDuration}/${sessionId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async checkIfCanLeaveComment(
    token: string,
    sessionId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .get(`${baseURL}${sessions.checkIfCanLeaveComment}/${sessionId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAllDefaultComments(token: string): Promise<
    TypeDefaultResponse & {
      defaultComments?: DefaultComments[];
    }
  > {
    return await ky
      .get(`${baseURL}${sessions.getAllDefaultComments}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUsersSessionPayments(
    token: string,
    userId: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      payments?: SessionPayment[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.getUsersSessionPayments}/${userId}/${page}/${limit}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async createSessionPackage(
    token: string,
    newPackage: Pick<
      SessionPackage,
      "title" | "description" | "packageCost" | "project" | "isActive" | "tags"
    > & {
      specializations: string[];
      sessions: {
        title: string;
        description?: string;
        datesAndTime: {
          duration: number;
        }[];
        order: number;
      }[];
    }
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.createSessionPackage}`, {
        headers: getAuthHeader(token),
        json: { newPackage },
      })
      .then((res) => res.json());
  }

  static async setSessionPackagePreviewFile(
    token: string,
    packageId: string,
    formData: FormData
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.setSessionPackagePreviewFile}/${packageId}`, {
        headers: getAuthHeader(token),
        body: formData,
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async getSpecialistSessionPackages(
    token: string,
    specialistId: string
  ): Promise<
    TypeDefaultResponse & {
      packages?: SessionPackage[] | null;
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.getSpecialistSessionPackages}/${specialistId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
  static async updatePackage(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.updatePackage}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async deletePackage(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.deletePackage}/${packageId}`, {
        headers: getAuthHeader(token),
        json: { packageId },
      })
      .then((res) => res.json());
  }

  static async bookSessionPackage(
    token: string,
    payload: {
      packageId: string;
      bookingsInfo: {
        title: string;
        description?: string;
        datesAndTime: { date: Date; duration: number }[];
        order: number;
      }[];
      projectId?: string;
      promocodeText?: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      package?: BookedPackage | null;
      systemMessageData?: TypeSystemMessageData;
      notEnoughBalance: boolean;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.bookSessionPackage}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getUsersBookedPackages(
    token: string,
    userId: string
  ): Promise<
    TypeDefaultResponse & {
      packages?: BookedPackage[];
    }
  > {
    return await ky
      .get(`${baseURL}${sessions.getUsersBookedPackages}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async acceptPackageBooking(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      sessions?: (Sessions | null | undefined)[];
      userIdToNotify?: string;
      systemMessageData?: TypeSystemMessageData;
      googleEventsData?: {
        userId: string;
        eventInfo: TypeGoogleEvent;
      }[];
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.acceptPackageBooking}/${packageId}`, {
        headers: getAuthHeader(token),
        json: { packageId },
      })
      .then((res) => res.json());
  }

  static async cancelPackageBooking(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      userIdToNotify?: string;
      systemMessageData?: TypeSystemMessageData;
    }
  > {
    return await ky
      .post(`${baseURL}${sessions.cancelPackageBooking}/${packageId}`, {
        headers: getAuthHeader(token),
        json: { packageId },
      })
      .then((res) => res.json());
  }

  static async getSpecialistSessionPackageById(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.getSpecialistSessionPackageById}/${packageId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getPackageFiles(
    token: string,
    fileId: string
  ): Promise<
    TypeDefaultResponse & {
      packageImages?: {
        _id: string;
        link: string;
        type: string;
        filename: string;
        size: number;
      }[];
      packageVideos?: {
        _id: string;
        link: string;
        type: string;
        filename: string;
        size: number;
      }[];
      packageSessionImages?: {
        previewImage: {
          _id: string;
          link: string;
          type: string;
          filename: string;
          size: number;
        };
        title: string;
        description?: string | undefined;
        datesAndTime: {
          duration: number;
        }[];
        order: number;
      }[];
    }
  > {
    return await ky
      .get(`${baseURL}${sessions.getPackageFiles}/${fileId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async deletePackageSessionFile(
    token: string,
    payload: {
      packageId: string;
      fileId: string;
      fileType: "packageImage" | "packageVideo" | "packageSessionImage";
    }
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage;
    }
  > {
    return await ky
      .post(
        `${baseURL}${sessions.deletePackageSessionFile}/${payload.packageId}`,
        {
          headers: getAuthHeader(token),
          json: { payload },
        }
      )
      .then((res) => res.json());
  }

  static async checkIfCanBookPackage(
    token: string,
    userId: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      canBook: boolean;
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.checkIfCanBookPackage}/${userId}/${packageId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
}
