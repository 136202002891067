import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./AboutYourself.module.css";
import NextSlideIcon from "../../../assets/SpecialistSetupInfo/next-slide.svg";
import StandartButton from "../../../components/StandartButton/StandartButton";
import AboutSubStep1 from "./AboutSubSteps/AboutSubStep1";
import AboutSubStep2 from "./AboutSubSteps/AboutSubStep2";
import AboutSubStep3 from "./AboutSubSteps/AboutSubStep3";
import { ContextProvider } from "../../../contextProvider";

import SpecialistApi from "../../../api/specialistApi";
import strings from "../../../localization";

type CreatingProfileProps = {
  setActiveStep: Dispatch<SetStateAction<number>>;
  subStep: { current: number; total: number };
  setSubStep: Dispatch<SetStateAction<{ current: number; total: number }>>;
  setLoader: Dispatch<SetStateAction<boolean>>;
};

const AboutYourself = ({
  setActiveStep,
  subStep,
  setSubStep,
  setLoader,
}: CreatingProfileProps) => {
  const { userData } = useContext(ContextProvider);
  const [firstStepTextValue, setFirstStepTextValue] = useState("");
  const [secondStepTextValue, setSecondStepTextValue] = useState("");
  const [thirdStepTextValue, setThirdStepTextValue] = useState("");

  const nextStep = async () => {
    const token = localStorage.getItem("token");

    if (
      token &&
      userData?.specialistId &&
      (userData?.specialistId ||
        (userData?.specialistId && userData?.specialistId._id))
    ) {
      const specialistId = userData?.specialistId._id
        ? userData?.specialistId._id
        : userData.specialistId.toString();
      setLoader(true);
      if (subStep.current === 1) {
        await SpecialistApi.update(
          { _id: specialistId, aboutMyself: firstStepTextValue },
          token
        );

        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 2) {
        await SpecialistApi.update(
          {
            _id: specialistId,
            aboutTherapy: secondStepTextValue,
          },
          token
        );
        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 3) {
        await SpecialistApi.update(
          {
            _id: specialistId,
            briefDescription: thirdStepTextValue,
          },
          token
        );
        setActiveStep((prev) => prev + 1);
      }
      setLoader(false);
    }
  };
  return (
    <div>
      {subStep.current === 1 && (
        <AboutSubStep1
          maxLenght={1500}
          textareaValue={firstStepTextValue}
          setTextareaValue={setFirstStepTextValue}
        />
      )}
      {subStep.current === 2 && (
        <AboutSubStep2
          maxLenght={1500}
          setTextareaValue={setSecondStepTextValue}
          textareaValue={secondStepTextValue}
        />
      )}
      {subStep.current === 3 && (
        <AboutSubStep3
          maxLenght={250}
          setTextareaValue={setThirdStepTextValue}
          textareaValue={thirdStepTextValue}
        />
      )}
      <div className={s.continueButtonBlock}>
        {subStep.current === subStep.total ? (
          <StandartButton
            buttonTitle={strings.continueBtnMob}
            action={nextStep}
            width="315px"
          />
        ) : (
          <div role="button" className={s.nextButtonBlock}>
            <img
              className={s.nextImage}
              src={NextSlideIcon}
              alt=""
              onClick={nextStep}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutYourself;
