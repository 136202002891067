import React, { useContext, useState } from "react";
import s from "./ConfirmRegistration.module.css";
import TextField from "@mui/material/TextField";
import StandartButton from "../../../components/StandartButton/StandartButton";
import UsersApi from "../../../api/usersApi";
import { ContextProvider } from "../../../contextProvider";
import { useLocation, useNavigate } from "react-router";
import strings from "../../../localization";
import LockIng from "../../../assets/Auth/lock.png";
import GroupBg1 from "../../../assets/Auth/Group.png";
import GroupBg2 from "../../../assets/Auth/Group-1.png";
import GroupBg3 from "../../../assets/Auth/Group-2.png";
import Input from "../../../components/Input/Input";
import { SuccesNotify } from "../../../utils/toaster";
import { ToastContainer } from "react-toastify";

const ConfirmRegistration = () => {
  const token = localStorage.getItem("token");
  const { userData, setIsAuth, setUserData, setSelectLang, selectLang } =
    useContext(ContextProvider);
  const [confirmeCode, setConfirmeCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const storeData = async (value: string) => {
    try {
      localStorage.setItem("token", value);
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = async () => {
    if (userData && confirmeCode) {
      const response = await UsersApi.confirmRegister({
        id: userData._id,
        code: Number(confirmeCode),
      });
      if (!response.status && response.message) {
        setError(response.message);
      }
      if (response.status && response.token) {
        storeData(response.token);
        setIsAuth(true);
        navigate("/user-quiez");
      }
    }
  };
  const handleResend = async () => {
    if (!userData || !token) return;

    const response = await UsersApi.resendConfCode(userData._id, token);

    if (!response.status && response.message) {
      setError(response.message);
    }
    if (response.status) {
      setError("");
      SuccesNotify(strings.codeSent);
      setUserData(response.userData);
    }
  };
  const changeLangHandler = async (value: string) => {
    strings.setLanguage(value);
    setSelectLang(value);
  };
  return (
    <div className={s.container} data-testid="confirm-reg">
      <div className={s.langSelectBlock}>
        <button
          onClick={() => changeLangHandler("en")}
          className={selectLang === "en" ? s.selectLang : s.unselectLang}
        >
          EN
        </button>
        <button
          onClick={() => changeLangHandler("uk")}
          className={selectLang === "uk" ? s.selectLang : s.unselectLang}
        >
          УКР
        </button>
      </div>
      <div className={s.mainContentBlock}>
        <img src={LockIng} alt="" className={s.logo} />
        <h1 className={s.headTitle}>{strings.emailConfirm}</h1>
        <p className={s.codeDescription}>{strings.emailConfirmDescription}</p>
        <div className={s.actionBlock}>
          <Input
            inputValue={confirmeCode}
            onChangeInput={(value) => setConfirmeCode(value)}
            isVisible
            required
            lable={strings.codeLable}
            placeholder="00000"
          />
          <div className={s.resendBlock}>
            <span>{strings.dintReciveCode}</span>
            <span role="button" onClick={handleResend}>
              {strings.resendBtn}
            </span>
          </div>
          <span className={s.errorMessage}>{error}</span>
          <div className={s.buttonBlock}>
            <StandartButton
              buttonTitle={strings.confirmBtn}
              action={handleConfirm}
              fontSize="14px"
              width="100%"
              height={window.innerWidth > 600 ? "50px" : "40px"}
            />
          </div>
        </div>
      </div>
      <div className={s.footerBlock}>
        <ToastContainer />
      </div>

      <img src={GroupBg1} alt="" className={s.group1} />
      <img src={GroupBg2} alt="" className={s.group2} />
      <img src={GroupBg3} alt="" className={s.group3} />
    </div>
  );
};

export default ConfirmRegistration;
