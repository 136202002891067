import { Booking, RealTimeBooking, Sessions } from "./TypeSession";

export enum PaymentStatuses {
  SUCCEEDED = "SUCCEEDED",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

export enum SessionPaymentStatuses {
  SUCCEEDED = "SUCCEEDED",
  PENDING = "PENDING",
  RETURNED = "RETURNED",
}

export enum PaymentForType {
  REAL_TIME_SESSION = "REAL_TIME_SESSION",
  SESSION = "SESSION",
  SESSION_PACKAGE = "SESSION_PACKAGE",
}

export type Payment = {
  paymentId: string;
  status: PaymentStatuses;
  cancelationReason?: string;
  amount: number;
  user: string;
  date: string;
};

export type CreatePaymentPayload = {
  order: { amount: number; promocode?: string };
  userId: string;
  cardTokenId?: string;
};
export type PaymentFor = {
  type: PaymentForType;
  bookingFor: string;
  subject?: string;
  realTimeSessionDuration?: number;
  datesAndTime?: { date: Date | string; duration: number }[];
  projectId?: string;
  packageId?: string;
  sessionPackageInfo?: {
    title: string;
    description?: string;
    datesAndTime: { date: Date | string; duration: number }[];
    order: number;
  }[];
  packagePromocode?: string;
};
export type SessionPayment = {
  _id: string;
  status: SessionPaymentStatuses;
  amount: number;
  from: {
    _id: string;
    name: string;
    surname: string;
  };
  for: {
    _id: string;
    name: string;
    surname: string;
  };
  date: string;
  booking?: Booking;
  realTimeBooking?: RealTimeBooking;
  session: Sessions;
};
