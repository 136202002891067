import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./ChangeSpecialistTags.module.css";
import { ContextProvider } from "../../../../../contextProvider";
import SpecialistApi from "../../../../../api/specialistApi";
import MultipleSelect from "../../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../../components/StandartButton/StandartButton";
import { SpecialistTagsDirections } from "../../../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo } from "../../../../../types/TypeUsers";

type DropDownMenuData = { lable: string; value: string; parentTag?: string };
type TagsData = {
  level1: DropDownMenuData[];
  level2: DropDownMenuData[];
  level3: DropDownMenuData[];
};

type ChangeSpecialistTagsProps = {
  setSpecialistUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
};

const ChangeSpecialistTags = ({
  setSpecialistUpdateData,
}: ChangeSpecialistTagsProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [tags, setTags] = useState<TagsData>({
    level1: [],
    level2: [],
    level3: [],
  });
  const [selectedTags, setSelectedTags] = useState<{
    level1: { _id: string; parentTag?: string }[];
    level2: { _id: string; parentTag: string }[];
    level3: { _id: string; parentTag: string }[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });

  const directions: { [key: string]: SpecialistTagsDirections } = {
    ["Coach"]: SpecialistTagsDirections.COACHING,
    ["Psychologist"]: SpecialistTagsDirections.COACHING,
    ["Mentor"]: SpecialistTagsDirections.COACHING,
  };
  const gatTags = async (
    level: number,
    keyForChange: keyof TagsData,
    parentTagsIds?: string[]
  ) => {
    if (!token || !userData || !userData.specialistId) return;
    const payload = {
      level: level,
      direction:
        directions[userData.specialistId.mainSpecializations[0].specialization],
      parentTagsIds: parentTagsIds,
    };

    const response = await SpecialistApi.getSpecialistTags(token, payload);

    if (response.status && response.tags) {
      const tagsData = response.tags.map((item) => ({
        lable: item.labels.find(
          (el) => el.language === userData.selectedLanguage
        )!.text,
        value: item._id,
        parentTag: item.parentTag,
      }));
      setTags((prev) => ({ ...prev, [keyForChange]: tagsData }));
    }
  };

  useEffect(() => {
    gatTags(1, "level1");
    if (
      !userData ||
      !userData.specialistId ||
      !userData.specialistId.tags ||
      !userData.specialistId.tags.length
    )
      return;
    const userTags = {
      level1: userData.specialistId.tags
        .filter((el) => el.level === 1)
        .map((item) => ({ _id: item._id })),
      level2: userData.specialistId.tags
        .filter((el) => el.level === 2)
        .map((item) => ({ _id: item._id, parentTag: item.parentTag! })),
      level3: userData.specialistId.tags
        .filter((el) => el.level === 3)
        .map((item) => ({ _id: item._id, parentTag: item.parentTag! })),
    };

    setSelectedTags(userTags);
  }, []);

  useEffect(() => {
    setSelectedTags((prev) => ({
      ...prev,
      level2: prev.level2.filter((el) =>
        prev.level1.map((item) => item._id).includes(el.parentTag)
      ),
    }));
    gatTags(
      2,
      "level2",
      selectedTags.level1.map((item) => item._id)
    );
  }, [selectedTags.level1]);

  useEffect(() => {
    setSelectedTags((prev) => ({
      ...prev,
      level3: prev.level3.filter((el) =>
        prev.level2.map((item) => item._id).includes(el.parentTag)
      ),
    }));
    gatTags(
      3,
      "level3",
      selectedTags.level2.map((item) => item._id)
    );
  }, [selectedTags.level2]);

  useEffect(() => {
    setSpecialistUpdateData((prev) => ({
      ...prev,
      tags: Object.values(selectedTags).flat(),
    }));
  }, [selectedTags]);

  return (
    <div className={s.container}>
      <MultipleSelect
        data={tags.level1}
        multiplie
        setValue={(value) => {
          setSelectedTags((prev) => ({
            ...prev,
            level1: (value as string[]).map((tagId) => ({
              _id: tagId,
            })),
          }));
        }}
        value={selectedTags.level1.map((item) => item._id)}
        width="32%"
        chip
      />
      <MultipleSelect
        data={tags.level2}
        multiplie
        setValue={(value) =>
          setSelectedTags((prev) => ({
            ...prev,
            level2: (value as string[]).map((tagId) => ({
              _id: tagId,
              parentTag: tags.level2.find((el) => el.value === tagId)!
                .parentTag!,
            })),
          }))
        }
        value={selectedTags.level2.map((item) => item._id)}
        width="32%"
        disabled={!tags.level2.length}
        chip
      />
      <MultipleSelect
        data={tags.level3}
        multiplie
        setValue={(value) =>
          setSelectedTags((prev) => ({
            ...prev,
            level3: (value as string[]).map((tagId) => ({
              _id: tagId,
              parentTag: tags.level3.find((el) => el.value === tagId)!
                .parentTag!,
            })),
          }))
        }
        value={selectedTags.level3.map((item) => item._id)}
        width="32%"
        disabled={!tags.level3.length}
        chip
      />
    </div>
  );
};

export default ChangeSpecialistTags;
