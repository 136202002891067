import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import s from "./Newsfeed.module.css";
import { ContextProvider } from "../../contextProvider";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import strings from "../../localization";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import PostApi from "../../api/postsApi";
import { Post } from "../../types/Posts";
import NewsfeedCard from "./NewsfeedCard/NewsfeedCard";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import ModalWindow from "../ModalWindow/ModalWindow";
import StandartButton from "../StandartButton/StandartButton";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ReactComponent as ImageIcon } from "../../assets/Social/image-post.svg";
import { ReactComponent as VideoIcon } from "../../assets/Social/video-post.svg";
import Notify from "../../utils/toaster";
import FileGrid from "./FileGrid/FileGrid";
import Loader from "../Loader/Loader";
import { compressFiles } from "../../utils/filesAction";

type NewsfeedProps = {
  newPostModalVisible?: boolean;
  setNewPostModalVisible?: Dispatch<SetStateAction<boolean>>;
  userId?: string;
};

const Newsfeed = ({
  newPostModalVisible,
  setNewPostModalVisible,
  userId,
}: NewsfeedProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const postsContainerRef = useRef<HTMLDivElement>(null);
  const [postText, setPostText] = useState("");
  const [pagginationPosts, setPagginationPosts] = useState(1);
  const [postsData, setPostsData] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [postVideo, setPostVideo] = useState<File[]>([]);
  const [postImages, setPostImages] = useState<File[]>([]);
  const [actionLoaders, setActionLoaders] = useState({ createPost: false });

  useAutosizeTextArea(textAreaRef.current, postText);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    prevFiles: File[],
    setFiles: Dispatch<SetStateAction<File[]>>,
    typeFiles: "video" | "image"
  ) => {
    const newFiles = event.target.files;

    if (!newFiles) {
      return;
    }

    const allFiles = Array.from(newFiles);
    const hasGif = allFiles.some((file) => file.type === "image/gif");

    if (hasGif) {
      Notify(strings.gifAllowed);
      return;
    }

    if (newFiles) {
      const maxFiles = typeFiles === "image" ? 5 : 1;
      const totalFiles = prevFiles.length + newFiles.length;
      if (totalFiles <= maxFiles) {
        if (typeFiles === "image") {
          compressFiles(allFiles, setFiles);
        }
        if (typeFiles === "video") {
          setFiles((prevFiles) => [...prevFiles, ...Array.from(newFiles)]);
        }
      } else {
        Notify(strings.toManyPostFile);
      }
    }
  };

  const createPost = async () => {
    if (
      !token ||
      (!postText.replace(/\s+/g, "").length &&
        !postImages.length &&
        !postVideo.length)
    )
      return;
    const formData = new FormData();
    formData.append("content", postText);
    if (postImages.length) {
      for (let i = 0; i < postImages.length; i++) {
        formData.append("images", postImages[i]);
      }
    }
    if (postVideo.length) {
      for (let i = 0; i < postVideo.length; i++) {
        formData.append("videos", postVideo[i]);
      }
    }

    setActionLoaders((prev) => ({ ...prev, createPost: true }));
    const response = await PostApi.createPost(token, formData);

    if (!response.status && response.message) {
      setActionLoaders((prev) => ({ ...prev, createPost: false }));
      Notify(response.message);
    }
    if (response.status && response.post) {
      setPostText("");
      setPostImages([]);
      setPostVideo([]);
      setPostsData((prev) => [response.post!, ...prev]);
      setNewPostModalVisible && setNewPostModalVisible(false);
      /* getFollowingPosts(1, true); */
      setActionLoaders((prev) => ({ ...prev, createPost: false }));
    }
  };

  const getFollowingPosts = async (page: number, reset: boolean = false) => {
    if (!token || !userData || loading) return;
    setLoading(true);
    if (!userId) {
      const response = await PostApi.getFollowingPosts(
        token,
        userData._id,
        5,
        page
      );

      if (response.status && response.posts) {
        setPostsData((prev) =>
          reset ? response.posts! : [...prev, ...response.posts!]
        );
        if (response.posts.length < 5) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    }
    if (userId) {
      const response = await PostApi.getUserPosts(token, userId, 5, page);

      if (response.status && response.posts) {
        setPostsData((prev) =>
          reset ? response.posts! : [...prev, ...response.posts!]
        );
        if (response.posts.length < 5) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userData && token) {
      getFollowingPosts(1, true);
    }
  }, [userData, token]);

  const loadMorePosts = () => {
    if (hasMore && !loading) {
      setPagginationPosts((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (pagginationPosts > 1) {
      getFollowingPosts(pagginationPosts);
    }
  }, [pagginationPosts]);

  const handleScroll = () => {
    if (postsContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        postsContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        loadMorePosts();
      }
    }
  };

  useEffect(() => {
    const container = postsContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [postsContainerRef.current, loading]);

  return (
    <div className={s.container} ref={postsContainerRef}>
      {(!userId || userId === userData?._id) && (
        <div className={s.myPostBlock}>
          <div className={s.avatarNameBlock}>
            <CircleAvatar
              userId={userData?._id}
              width="30px"
              height="30px"
              fontSize="16px"
            />
            <span className={s.myName}>
              {userData?.name + " " + userData?.surname}
            </span>
          </div>
          {actionLoaders.createPost ? (
            <div className={s.loaderBlock}>
              <Loader size={50} />
            </div>
          ) : (
            <div>
              <textarea
                rows={1}
                value={postText}
                onChange={(e) => setPostText(e.target.value)}
                className={s.postInput}
                placeholder="Start..."
                ref={textAreaRef}
              />
              {postImages.length ? (
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <FileGrid images={postImages} isCreatingPost />
                </div>
              ) : postVideo.length ? (
                <video
                  src={URL.createObjectURL(postVideo[0])}
                  controls
                  className={s.postVideo}
                />
              ) : null}
              <div className={s.postActionBlock}>
                <div className={s.postFilesActionBlock}>
                  {!postVideo.length && (
                    <label className={s.fileInputBlock}>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        multiple
                        value={""}
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            postImages,
                            setPostImages,
                            "image"
                          )
                        }
                      />
                      <ImageIcon className={s.scrapIcon} />
                    </label>
                  )}
                  {!postImages.length && (
                    <label className={s.fileInputBlock}>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="video/*"
                        multiple
                        value={""}
                        onChange={(e) =>
                          handleFileChange(e, postVideo, setPostVideo, "video")
                        }
                      />
                      <VideoIcon className={s.scrapIcon} />
                    </label>
                  )}
                  {postImages.length || postVideo.length ? (
                    <div
                      role="button"
                      onClick={() => {
                        setPostImages([]);
                        setPostVideo([]);
                      }}
                      className={s.clearFilesBtn}
                    >
                      {strings.clearFiles}
                    </div>
                  ) : null}
                </div>
                <div role="btn" className={s.postBtn} onClick={createPost}>
                  {strings.postBtn}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={s.postsContainer}>
        {postsData.map((item, index) => (
          <div className={s.postElement} key={index}>
            <NewsfeedCard
              post={item}
              allPosts={postsData}
              setAllPosts={setPostsData}
              showMedia
            />
          </div>
        ))}
        {loading && (
          <div className={s.loaderBlock}>
            <ThemeProvider theme={theme}>
              <CircularProgress size={40} color="primary" />
            </ThemeProvider>
          </div>
        )}
      </div>
      {newPostModalVisible !== undefined &&
        setNewPostModalVisible !== undefined && (
          <ModalWindow
            isOpen={newPostModalVisible}
            setIsOpen={setNewPostModalVisible}
            width="fit-content"
            position={window.innerWidth > 768 ? "right" : "bottom"}
            padding="0"
          >
            <div className={s.newPostBlock}>
              <div className={s.newPostHeader}>
                <span>{strings.newPost}</span>
                <CloseIcon
                  className={s.closeIcon}
                  onClick={() => setNewPostModalVisible(false)}
                />
              </div>

              <div className={s.newPostInputBlock}>
                {/*   <span>{strings.newNoteDescriptionLable}</span> */}
                <textarea
                  name=""
                  id=""
                  placeholder={strings.postPlaceholder}
                  cols={30}
                  rows={10}
                  value={postText}
                  className={s.textAreaNewPost}
                  onChange={(e) => setPostText(e.target.value)}
                ></textarea>

                {postImages.length ? (
                  <div style={{ width: "100%", marginBottom: "20px" }}>
                    <FileGrid images={postImages} />
                  </div>
                ) : postVideo.length ? (
                  <video
                    src={URL.createObjectURL(postVideo[0])}
                    controls
                    className={s.postVideo}
                  />
                ) : null}
                <div className={s.postActionBlock}>
                  <div className={s.postFilesActionBlock}>
                    {!postVideo.length && (
                      <label className={s.fileInputBlock}>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          multiple
                          value={""}
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              postImages,
                              setPostImages,
                              "image"
                            )
                          }
                        />
                        <ImageIcon className={s.scrapIcon} />
                      </label>
                    )}
                    {!postImages.length && (
                      <label className={s.fileInputBlock}>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="video/*"
                          multiple
                          value={""}
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              postVideo,
                              setPostVideo,
                              "video"
                            )
                          }
                        />
                        <VideoIcon className={s.scrapIcon} />
                      </label>
                    )}
                    {postImages.length || postVideo.length ? (
                      <div
                        role="button"
                        onClick={() => {
                          setPostImages([]);
                          setPostVideo([]);
                        }}
                        className={s.clearFilesBtn}
                      >
                        {strings.clearFiles}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={s.createPostBlock}>
                <StandartButton
                  action={createPost}
                  buttonTitle={strings.postBtn}
                  width="200px"
                  fontSize="20px"
                  height="45px"
                  disabled={
                    postText === "" && !postImages.length && !postVideo.length
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </ModalWindow>
        )}
    </div>
  );
};

export default Newsfeed;
