import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import s from "./SpecialistProfileContent.module.css";
import { ContextProvider } from "../../../contextProvider";
import { SpecialistData } from "../../../types/TypeSpecialist";
import SpecialistApi from "../../../api/specialistApi";
import SessionsApi from "../../../api/sessionApi";
import { SessionComment } from "../../../types/TypeSession";
import "swiper/css";
import "swiper/css/pagination";
import { formatFileSize } from "../../../utils/messageChat";

import { ReactComponent as FileIcon } from "../../../assets/Profile/file-icon.svg";
import { TypeUpdatedUserInfo, TypeUserData } from "../../../types/TypeUsers";
import { calculateAge } from "../../../utils/setupProfileInfo";
import { PlaceType } from "../../../components/CitySelect/CitySelect";
import StandartButton from "../../../components/StandartButton/StandartButton";
import NotificationsApi from "../../../api/notifications";
import {
  CircularProgress,
  Pagination,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import { ReactSpoiler } from "react-simple-spoiler";
import countries from "i18n-iso-countries";
import strings from "../../../localization";

import { ReactComponent as TokenIcon } from "../../../assets/Profile/specialist/heart-tick.svg";
import { ReactComponent as MessageIcon } from "../../../assets/Profile/specialist/message.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/Profile/specialist/calendar.svg";
import { ReactComponent as PracticHoursIcon } from "../../../assets/Profile/specialist/play-circle.svg";
import { ReactComponent as YearExpIcon } from "../../../assets/Profile/specialist/year-exp.svg";
import { ReactComponent as FromIcon } from "../../../assets/Profile/specialist/smart-home.svg";
import { ReactComponent as RatingIcon } from "../../../assets/Profile/specialist/unlimited.svg";
import { ReactComponent as GenderIcon } from "../../../assets/Profile/specialist/gender.svg";
import { ReactComponent as StarIcon } from "../../../assets/Profile/specialist/star.svg";
import { ReactComponent as YellowStarIcon } from "../../../assets/Profile/specialist/yellow-star.svg";
import { EditSpecialistProfile } from "./EditSpecialistProfile/EditSpecialistProfile";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import { ReactComponent as PlayBtnIcon } from "../../../assets/Profile/specialist/play-circle-video.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/Profile/specialist/verify.svg";

import { ReactComponent as EyeIcon } from "../../../assets/Auth/eye.svg";
import { theme } from "../../../utils/theme";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import SubscribeBtn from "../SubscribeBtn/SubscribeBtn";
import PublishedPosts from "./PublishedPosts/PublishedPosts";
import { useLocation, useNavigate } from "react-router-dom";
import Packages from "./Packages/Packages";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

interface WorkTime {
  from: string;
  to: string;
}

interface ServerData {
  [key: string]: { time: string[]; switch: boolean };
}
export type CertificatesData = {
  _id: string;
  filename: string;
  type: string;
  size: number;
  link: string;
};

type SpecialistProfileContentProps = {
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  updateData: () => void;
  specialistUpdateData: TypeUpdatedUserInfo;
  setSpecialistUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  setNewCertificates: Dispatch<SetStateAction<File[]>>;
  specialistData: SpecialistData | undefined;
  profileUserData: TypeUserData | null | undefined;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  userUpdateData: TypeUpdatedUserInfo;
  video: File | null;
  setVideo: Dispatch<SetStateAction<File | null>>;
  calendarIsConnected: boolean;
  setBookingModalIsOpen: Dispatch<SetStateAction<boolean>>;
  realTimeSessionsChecked: boolean | null;
  setAvatar: Dispatch<SetStateAction<string | null>>;
  avatar: string | null;
  setRealTimeSessionsChecked: Dispatch<SetStateAction<boolean | null>>;
  newCertificates: File[];
  isUpdateLoader: boolean;
  setAvatarFile: Dispatch<SetStateAction<File | undefined>>;
};

const SpecialistProfileContent = ({
  isEdit,
  specialistUpdateData,
  setSpecialistUpdateData,
  setNewCertificates,
  specialistData,
  profileUserData,
  setUserUpdateData,
  userUpdateData,
  video,
  setVideo,
  calendarIsConnected,
  setIsEdit,
  updateData,
  setBookingModalIsOpen,
  realTimeSessionsChecked,
  setRealTimeSessionsChecked,
  setAvatar,
  avatar,
  newCertificates,
  isUpdateLoader,
  setAvatarFile,
}: SpecialistProfileContentProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const activeProfileTab =
    location.state && location.state.activeProfileTab
      ? location.state.activeProfileTab
      : undefined;
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const { userData } = useContext(ContextProvider);
  const [commentsData, setCommentsData] = useState<SessionComment[]>([]);
  const [selectedCity, setSelectedCity] = useState<PlaceType | null>(null);
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [actionLoaders, setActionLoaders] = useState({ certificates: false });
  const [previewFile, setPreviewFile] = useState<{
    pdfFile: string | null;
    imageFile: string | null;
  }>({ pdfFile: null, imageFile: null });
  const [daysState, setDaysState] = useState([
    {
      dayValue: "Monday",
      daytitle: strings.scheduleMonday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Tuesday",
      daytitle: strings.scheduleTuesday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Wednesday",
      daytitle: strings.scheduleWednesday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Thursday",
      daytitle: strings.scheduleThursday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Friday",
      daytitle: strings.scheduleFriday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Saturday",
      daytitle: strings.scheduleSaturday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Sunday",
      daytitle: strings.scheduleSunday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
  ]);
  const [dateOfBirth, setDateOfBirth] = useState({
    day: 0,
    month: 0,
    year: 0,
  });
  const [certificatesPaggination, setCertificatesPaggination] = useState({
    page: 1,
    total: 0,
  });
  const [certificatesData, setCertificatesData] = useState<CertificatesData[]>(
    []
  );
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth <= 768);
  const [generalInfoData, setGeneralInfoData] = useState<
    { title: string; icon: any; value: string | number | undefined }[] | null
  >(null);
  const [activeTab, setActiveTab] = useState<"general" | "posts" | "packages">(
    activeProfileTab ?? "general"
  );

  const introVideoId =
    profileUserData?.specialistId &&
    profileUserData.specialistId.introductionVideo?._id
      ? profileUserData.specialistId.introductionVideo._id
      : null;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    function handleResize() {
      setIsScreenSmall(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const img = imgRef.current;

    if (!video || !canvas || !img) return;

    const handleLoadedMetadata = () => {
      video.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();
      img.src = dataURL;
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (!profileUserData || !specialistData) return;
    const price = specialistData.price;
    const languages = profileUserData.preferredLanguages!.join(", ");
    const practicHours = specialistData.practiceHours;
    const yearsExp = specialistData.yearsOfExperience;
    const location = profileUserData.location;
    const age = calculateAge(new Date(profileUserData.dateOfBirth!));
    const rating = specialistData.rating?.toFixed(1);
    //@ts-expect-error
    const gender = profileUserData.gender.labels.find(
      //@ts-expect-error
      (item) => item.language === userData?.selectedLanguage
    ).text;

    const generalInfoElementData = [
      {
        title: strings.presSession,
        icon: TokenIcon,
        value: price,
      },
      {
        title: strings.langProfileSpecLable,
        icon: MessageIcon,
        value: languages,
      },
      {
        title: strings.specProfilePracticeHours,
        icon: PracticHoursIcon,
        value: practicHours + "+",
      },
      {
        title: strings.specProfileYearsExp,
        icon: YearExpIcon,
        value: yearsExp + "+",
      },
      {
        title: strings.filterGender,
        icon: GenderIcon,
        value: gender,
      },
      {
        title: strings.fromProfileSpecLable,
        icon: FromIcon,
        value: location,
      },
      {
        title: strings.ageSpecProfile,
        icon: CalendarIcon,
        value: age,
      },
      {
        title: strings.ratingSpecProfile,
        icon: RatingIcon,
        value: rating,
      },
    ];
    setGeneralInfoData(generalInfoElementData);
  }, [
    profileUserData,
    specialistData,
    userUpdateData,
    specialistUpdateData,
    isUpdateLoader,
  ]);

  useEffect(() => {
    if (
      selectedCity?.terms[0].value &&
      selectedCity?.terms[selectedCity?.terms.length - 1].value
    )
      setUserUpdateData((prev) => ({
        ...prev,
        location:
          selectedCity?.terms[0].value +
          ", " +
          selectedCity?.terms[selectedCity?.terms.length - 1].value,
      }));
  }, [selectedCity]);

  useEffect(() => {
    const makeAsync = async () => {
      if (
        token &&
        profileUserData &&
        profileUserData.specialistId &&
        profileUserData.specialistId._id &&
        profileUserData._id
      ) {
        const commentsResponse = await SessionsApi.getSpecialistsComments(
          profileUserData?._id,
          0,
          10
        );

        if (commentsResponse.status) setCommentsData(commentsResponse.comments);

        if (
          certificatesPaggination.total > 0 &&
          certificatesData.length >= certificatesPaggination.total
        )
          return;
        const certificatesAmount = isEdit ? 20 : 2;
        const page = certificatesPaggination.page;
        setActionLoaders((prev) => ({ ...prev, certificates: true }));
        const certificatesResponse =
          await SpecialistApi.getSpecialistCertificates(
            token,
            profileUserData.specialistId._id,
            page,
            certificatesAmount
          );

        setActionLoaders((prev) => ({ ...prev, certificates: false }));
        if (certificatesResponse.status && certificatesResponse.certificates) {
          setCertificatesPaggination((prev) => ({
            ...prev,
            page: isEdit ? prev.page + 1 : prev.page,
            total: certificatesResponse.total,
          }));
          setCertificatesData((prev) =>
            isEdit
              ? [...prev, ...certificatesResponse.certificates!]
              : certificatesResponse.certificates!
          );
        }
      }
    };
    makeAsync();
  }, [certificatesPaggination.page, isEdit]);

  const connectGoogleCalendar = async () => {
    if (!token) return;
    const response = await NotificationsApi.generateCode(token);
    if (response.status && response.url) {
      window.open(response.url);
    }
  };

  const processServerData = (data: ServerData): void => {
    const newDaysState = daysState.map((day) => {
      const dayKey = day.dayValue.toLowerCase();
      const schedule = data[dayKey];

      if (schedule.time.includes("DayOff")) {
        return { ...day, isOpen: false, workTime: [] };
      }

      const workTime: WorkTime[] = schedule.time.map((time) => {
        const [from, to] = time.split("-");
        return { from, to };
      });

      return { ...day, switch: schedule.switch, workTime };
    });

    setDaysState(newDaysState);
  };

  useEffect(() => {
    if (!userData || !userData.schedule) return;

    processServerData(userData.schedule);
  }, [userData]);

  const previewFileHandler = (link: string, type: string) => {
    if (type === "pdf") {
      setPreviewFile({
        imageFile: null,
        pdfFile: link,
      });
    } else {
      setPreviewFile({
        imageFile: link,
        pdfFile: null,
      });
    }
    setPreviewFileModal(true);
  };

  useEffect(() => {
    if (location.search.includes("packages")) {
      setActiveTab("packages");
    }
  }, [location]);

  const updateQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("activeTab", "packages");
    queryParams.delete("oldParam");
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <div
      className={isEdit ? s.editContainer : s.container}
      id="profile-content"
    >
      {isEdit ? (
        <EditSpecialistProfile
          profileUserData={profileUserData}
          setUserUpdateData={setUserUpdateData}
          userUpdateData={userUpdateData}
          setAvatar={setAvatar}
          avatar={avatar}
          updateData={updateData}
          calendarIsConnected={calendarIsConnected}
          specialistUpdateData={specialistUpdateData}
          specialistData={specialistData}
          setSpecialistUpdateData={setSpecialistUpdateData}
          certificatesData={certificatesData}
          setCertificatesData={setCertificatesData}
          setNewCertificates={setNewCertificates}
          newCertificates={newCertificates}
          setVideo={setVideo}
          daysState={daysState}
          setDaysState={setDaysState}
          video={video}
          setAvatarFile={setAvatarFile}
          processServerData={processServerData}
        />
      ) : (
        <>
          <div className={s.headBlock}>
            {specialistData &&
              !specialistData.isVerifiedChanges &&
              profileUserData?._id === userData?._id && (
                <span className={s.warningMessage}>
                  {strings.warningProfileVerify}
                </span>
              )}
            <div className={s.headBlockMainContent}>
              <div className={s.headNameBlock}>
                <div className={s.mobileAvatarBlock}>
                  <CircleAvatar
                    userId={profileUserData?._id || ""}
                    name={profileUserData?.name || ""}
                    surname={profileUserData?.surname || ""}
                    height="110px"
                    width="110px"
                    fontSize="52px"
                    marginRight="20px"
                    isModalAvatar
                  />
                </div>
                <div className={s.nameBlock}>
                  {commentsData.length &&
                  specialistData &&
                  specialistData.rating ? (
                    <div className={s.headRatingBlock}>
                      <div className={s.headRatingValueBlock}>
                        <YellowStarIcon />
                        <span>{specialistData?.rating.toFixed(1)}</span>
                      </div>
                      <div className={s.basedOnBlock}>
                        <span>{strings.basedOn}</span>
                        <span>
                          {commentsData.length} {strings.basedReviews}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <span className={s.nameTitle}>
                    {profileUserData?.name} {profileUserData?.surname}
                    {profileUserData?.specialistId &&
                      profileUserData.specialistId.isVerified && (
                        <VerifiedIcon className={s.verifyIcon} />
                      )}
                  </span>
                  <span className={s.mainSpecTitle}>
                    {specialistData
                      ? specialistData.mainSpecializations[0].labels.find(
                          (item) => item.language === userData?.selectedLanguage
                        )?.text
                      : ""}
                  </span>
                  <span className={s.subSpecTitle}>
                    {specialistData
                      ? specialistData?.subSpecializations
                          .map(
                            (item, index) =>
                              item.labels.find(
                                (el) =>
                                  el.language === userData?.selectedLanguage
                              )?.text
                          )
                          .join(", ")
                      : ""}
                  </span>
                </div>
              </div>
              <div className={s.actionBlock}>
                {profileUserData?._id !== userData?._id && (
                  <div className={s.guestProfileActionBlock}>
                    <div className={s.headPriceBlock}>
                      <span>{strings.pricePerHour}</span>
                      {profileUserData!.specialistId!.discountPrice ? (
                        <div className={s.priceWithDiscountBlock}>
                          <span className={s.currentPrice}>
                            {profileUserData!.specialistId!.discountPrice}
                            <TokenIcon />
                          </span>
                          <span className={s.oldPrice}>
                            {profileUserData!.specialistId!.price}
                          </span>
                        </div>
                      ) : (
                        <span className={s.currentPrice}>
                          {profileUserData!.specialistId!.price} <TokenIcon />
                        </span>
                      )}
                    </div>
                    {specialistData?.isVerified && (
                      <StandartButton
                        buttonTitle={strings.bookProfileBtn}
                        action={() => setBookingModalIsOpen(true)}
                        width="100%"
                        height="40px"
                      />
                    )}
                    <SubscribeBtn userId={profileUserData?._id!} />
                  </div>
                )}
                {userData?._id === profileUserData?._id ||
                !profileUserData?._id ? (
                  <div className={s.myProfileActionBtn}>
                    <div
                      className={s.headPriceBlock}
                      style={{ alignItems: "center" }}
                    >
                      <span>{strings.pricePerHour}</span>
                      <span className={s.currentPrice}>
                        {profileUserData!.specialistId!.price} <TokenIcon />
                      </span>
                    </div>
                    <StandartButton
                      buttonTitle={strings.editProfileBtn}
                      action={() => setIsEdit(true)}
                      height="40px"
                      width="100%"
                    />
                    <button
                      className={s.specToolsBtn}
                      onClick={() => navigate("tools/package")}
                    >
                      {strings.specTools}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={s.tabsBlock}>
              <div
                className={
                  activeTab === "general" ? s.activeTab : s.notActiveTab
                }
                onClick={() => {
                  navigate(location.pathname, { replace: true });
                  setActiveTab("general");
                }}
              >
                <span>{strings.generalInfo}</span>
                <span
                  className={
                    activeTab === "general"
                      ? s.tabActiveIndicator
                      : s.indicatorHidden
                  }
                ></span>
              </div>
              <div
                className={activeTab === "posts" ? s.activeTab : s.notActiveTab}
                onClick={() => {
                  navigate(location.pathname, { replace: true });
                  setActiveTab("posts");
                }}
              >
                <span>{strings.publishePosts}</span>
                <span
                  className={
                    activeTab === "posts"
                      ? s.tabActiveIndicator
                      : s.indicatorHidden
                  }
                ></span>
              </div>
              <div
                className={
                  activeTab === "packages" ? s.activeTab : s.notActiveTab
                }
                onClick={() => {
                  updateQueryParams();
                  setActiveTab("packages");
                }}
              >
                <span>{strings.sessionPackage}</span>
                <span
                  className={
                    activeTab === "packages"
                      ? s.tabActiveIndicator
                      : s.indicatorHidden
                  }
                ></span>
              </div>
            </div>
          </div>
          {activeTab === "general" ? (
            <div className={s.mainInfoBlock}>
              <div className={s.leftProfileBlock}>
                <div className={s.generalWhiteBlock}>
                  <div className={s.generalInfoBlock}>
                    {generalInfoData &&
                      generalInfoData.map((item, index) => (
                        <div className={s.generalInfoElement} key={index}>
                          <item.icon className={s.genderalIcon} />
                          <div className={s.generalTitleBlock}>
                            <span>{item.title}</span>
                            <span>{item.value}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                  {isScreenSmall && (
                    <div className={s.mobileVideoEducationBlock}>
                      {introVideoId ? (
                        <div className={s.previewVideoBlock}>
                          <canvas ref={canvasRef} style={{ display: "none" }} />
                          <img
                            ref={imgRef}
                            alt="First frame of video"
                            className={s.previewVideoImg}
                          />
                          <PlayBtnIcon
                            className={s.playBtn}
                            onClick={() => setVideoModalOpen(true)}
                          />
                        </div>
                      ) : null}
                      {certificatesData && certificatesData.length ? (
                        <div className={s.educationBlock}>
                          <h4 className={s.blockLable}>
                            {strings.educationSpecProfile}
                          </h4>
                          {actionLoaders.certificates ? (
                            <div className={s.educationLoaderBlock}>
                              <ThemeProvider theme={theme}>
                                <CircularProgress size={50} color="primary" />
                              </ThemeProvider>
                            </div>
                          ) : (
                            certificatesData.map((item, index) => (
                              <div
                                key={index}
                                className={s.educationElement}
                                style={{
                                  marginBottom:
                                    index < certificatesData.length - 1
                                      ? "10px"
                                      : 0,
                                }}
                              >
                                <div className={s.leftEducationElContent}>
                                  <div className={s.educationIconBlock}>
                                    <FileIcon className={s.fileIcon} />
                                  </div>
                                  <div className={s.certificateTitleBlock}>
                                    <Tooltip
                                      title={
                                        item.filename &&
                                        item.filename.length > 30
                                          ? item.filename
                                          : ""
                                      }
                                    >
                                      <span className={s.certificateTitle}>
                                        {item.filename &&
                                        item.filename.length > 30
                                          ? item.filename?.slice(0, 30) + "..."
                                          : item.filename}
                                      </span>
                                    </Tooltip>
                                    <span>{formatFileSize(item.size!)}</span>
                                  </div>
                                </div>
                                <EyeIcon
                                  className={s.eyeIcon}
                                  onClick={() =>
                                    previewFileHandler(item.link, item.type!)
                                  }
                                />
                              </div>
                            ))
                          )}

                          <div className={s.pagginationBlock}>
                            <ThemeProvider theme={theme}>
                              <Pagination
                                page={certificatesPaggination.page}
                                count={Math.ceil(
                                  certificatesPaggination.total / 2
                                )}
                                color="primary"
                                onChange={(e, page) =>
                                  setCertificatesPaggination((prev) => ({
                                    ...prev,
                                    page: page,
                                  }))
                                }
                              />
                            </ThemeProvider>
                          </div>
                        </div>
                      ) : null}
                      {profileUserData?._id === userData?._id && (
                        <div className={s.integrationsBlock}>
                          <h4 className={s.blockLable}>
                            {strings.intagrationsSpecProfile}
                          </h4>
                          <div className={s.integrationsElement}>
                            <p className={s.integartionsElementTitle}>
                              {strings.connectGoogleSpecProfile}
                            </p>
                            <Tooltip
                              title={
                                calendarIsConnected
                                  ? strings.connectGoogleTooltip
                                  : ""
                              }
                              placement="right"
                              arrow
                            >
                              <div
                                style={{
                                  width: "fit-content",
                                }}
                              >
                                <StandartButton
                                  buttonTitle={
                                    strings.connectGoogleBtnSpecProfile
                                  }
                                  action={connectGoogleCalendar}
                                  height="40px"
                                  width="120px"
                                  disabled={calendarIsConnected}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {specialistData?.aboutMyself &&
                  specialistData?.aboutMyself[0] !== "" ? (
                    <div className={s.aboutTitleBlock}>
                      <h4 className={s.blockLable}>
                        {strings.aboutMeSpecProfile}
                      </h4>
                      <ReactSpoiler
                        collapsedSize={100}
                        showMoreComponent={
                          <div className={s.showMoreBtn}>
                            <p>{strings.showMore}</p>
                          </div>
                        }
                        showLessComponent={
                          <div className={s.showMoreBtn}>
                            <p>{strings.showLess}</p>
                          </div>
                        }
                      >
                        <p className={s.aboutTitle}>
                          {specialistData.aboutMyself}
                        </p>
                      </ReactSpoiler>
                    </div>
                  ) : null}
                  {specialistData?.aboutTherapy &&
                  specialistData?.aboutTherapy[0] !== "" ? (
                    <div className={s.aboutTitleBlock}>
                      <h4 className={s.blockLable}>
                        {strings.howSessionGoesSpecProfile}
                      </h4>
                      <ReactSpoiler
                        collapsedSize={100}
                        showMoreComponent={
                          <div className={s.showMoreBtn}>
                            <p>{strings.showMore}</p>
                          </div>
                        }
                        showLessComponent={
                          <div className={s.showMoreBtn}>
                            <p>{strings.showLess}</p>
                          </div>
                        }
                      >
                        <p className={s.aboutTitle}>
                          {specialistData.aboutTherapy}
                        </p>
                      </ReactSpoiler>
                    </div>
                  ) : null}
                </div>
                {commentsData && commentsData.length ? (
                  <div className={s.generalWhiteBlock}>
                    <div className={s.reviewsBlock}>
                      <h4 className={s.blockLable}>
                        {strings.reviewSpecProfile} ({commentsData.length})
                      </h4>
                      <div className={s.commentsListBlock}>
                        {commentsData.map((item, index) => (
                          <div className={s.commentCard} key={index}>
                            <div className={s.ratingBlock}>
                              {Array.from({ length: item.rating }).map(
                                (el, index) => (
                                  <StarIcon
                                    className={s.starIcon}
                                    key={index}
                                  />
                                )
                              )}
                              <span>{item.rating + ".0"}</span>
                            </div>
                            <div className={s.commentTextBlock}>
                              <p className={s.commentText}>
                                {item.customComment}
                              </p>

                              {item.defaultComments.map((item) => (
                                <p key={item.comment} className={s.commentText}>
                                  {
                                    item.labels.find(
                                      (lable) =>
                                        lable.language ===
                                        userData?.selectedLanguage
                                    )?.text
                                  }
                                </p>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {!isScreenSmall && (
                <div className={s.rightProfileBlock}>
                  {introVideoId ? (
                    <div className={s.previewVideoBlock}>
                      <canvas ref={canvasRef} style={{ display: "none" }} />
                      <img
                        ref={imgRef}
                        alt="First frame of video"
                        className={s.previewVideoImg}
                      />
                      <PlayBtnIcon
                        className={s.playBtn}
                        onClick={() => setVideoModalOpen(true)}
                      />
                    </div>
                  ) : null}
                  {certificatesData && certificatesData.length ? (
                    <div className={s.educationBlock}>
                      <h4 className={s.blockLable}>
                        {strings.educationSpecProfile}
                      </h4>
                      {actionLoaders.certificates ? (
                        <div className={s.educationLoaderBlock}>
                          <ThemeProvider theme={theme}>
                            <CircularProgress size={50} color="primary" />
                          </ThemeProvider>
                        </div>
                      ) : (
                        certificatesData.map((item, index) => (
                          <div
                            key={index}
                            className={s.educationElement}
                            style={{
                              marginBottom:
                                index < certificatesData.length - 1
                                  ? "10px"
                                  : 0,
                            }}
                          >
                            <div className={s.leftEducationElContent}>
                              <div className={s.educationIconBlock}>
                                <FileIcon className={s.fileIcon} />
                              </div>
                              <div className={s.certificateTitleBlock}>
                                <Tooltip
                                  title={
                                    item.filename && item.filename.length > 30
                                      ? item.filename
                                      : ""
                                  }
                                >
                                  <span className={s.certificateTitle}>
                                    {item.filename && item.filename.length > 30
                                      ? item.filename?.slice(0, 30) + "..."
                                      : item.filename}
                                  </span>
                                </Tooltip>
                                <span>{formatFileSize(item.size!)}</span>
                              </div>
                            </div>
                            <EyeIcon
                              className={s.eyeIcon}
                              onClick={() =>
                                previewFileHandler(item.link, item.type!)
                              }
                            />
                          </div>
                        ))
                      )}

                      <div className={s.pagginationBlock}>
                        <ThemeProvider theme={theme}>
                          <Pagination
                            page={certificatesPaggination.page}
                            count={Math.ceil(certificatesPaggination.total / 2)}
                            color="primary"
                            onChange={(e, page) =>
                              setCertificatesPaggination((prev) => ({
                                ...prev,
                                page: page,
                              }))
                            }
                          />
                        </ThemeProvider>
                      </div>
                    </div>
                  ) : null}
                  {profileUserData?._id === userData?._id && (
                    <div className={s.integrationsBlock}>
                      <h4 className={s.blockLable}>
                        {strings.intagrationsSpecProfile}
                      </h4>
                      <div className={s.integrationsElement}>
                        <p className={s.integartionsElementTitle}>
                          {strings.connectGoogleSpecProfile}
                        </p>
                        <Tooltip
                          title={
                            calendarIsConnected
                              ? strings.connectGoogleTooltip
                              : ""
                          }
                          placement="right"
                          arrow
                        >
                          <div
                            style={{
                              width: "fit-content",
                            }}
                          >
                            <StandartButton
                              buttonTitle={strings.connectGoogleBtnSpecProfile}
                              action={connectGoogleCalendar}
                              height="40px"
                              width="120px"
                              disabled={calendarIsConnected}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : activeTab === "posts" ? (
            <PublishedPosts profileUserData={profileUserData} />
          ) : (
            <Packages profileUserData={profileUserData} />
          )}
        </>
      )}
      <video
        ref={videoRef}
        src={`${connectionString}specialists/${
          profileUserData?._id === userData?._id
            ? "getSpecialistIntroVideo"
            : "getSpecialistPublicIntroVideo"
        }/${
          profileUserData?._id === userData?._id
            ? profileUserData?._id
            : profileUserData?.username
        }`}
        style={{ display: "none" }}
        crossOrigin="anonymous"
      />
      <ModalWindow
        isOpen={previewFileModal}
        setIsOpen={setPreviewFileModal}
        bgColor="transparent"
        height="fit-content"
        width={"fit-content"}
        padding="0"
        maxWidth="fit-content"
      >
        <div
          className={
            previewFile.pdfFile ? s.pdfPreviewBlock : s.imagePreviewBlock
          }
        >
          {previewFile.pdfFile && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
              <div className={s.pdfWorker}>
                <Viewer
                  fileUrl={previewFile.pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}

          {previewFile.imageFile && (
            <img src={previewFile.imageFile} alt="" className={s.previewImg} />
          )}
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={videoModalOpen}
        setIsOpen={setVideoModalOpen}
        bgColor="transparent"
        width="fit-content"
      >
        <video
          src={`${connectionString}specialists/${
            profileUserData?._id === userData?._id
              ? "getSpecialistIntroVideo"
              : "getSpecialistPublicIntroVideo"
          }/${
            profileUserData?._id === userData?._id
              ? profileUserData?._id
              : profileUserData?.username
          }`}
          crossOrigin="anonymous"
          controls
          autoPlay
          className={s.video}
        />
      </ModalWindow>
    </div>
  );
};

export default SpecialistProfileContent;
