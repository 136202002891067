import React, { Dispatch, SetStateAction } from "react";
import s from "../AboutYourself.module.css";
import strings from "../../../../localization";

type AboutSubStep3Props = {
  textareaValue: string;
  setTextareaValue: Dispatch<SetStateAction<string>>;
  maxLenght: number;
};

const AboutSubStep3 = ({
  textareaValue,
  setTextareaValue,
  maxLenght,
}: AboutSubStep3Props) => {
  return (
    <div className={s.subStepBlock}>
      <div className={s.infoTitleBlock}>
        <p className={s.stepTitle}>{strings.briefTitle}</p>
        <p className={s.substepText}>{strings.briefDescription}</p>
      </div>
      <div className={s.textAreaBlock}>
        <textarea
          maxLength={maxLenght}
          value={textareaValue}
          placeholder={strings.setupAboutPlaceHodler}
          className={s.textArea}
          onChange={(e) => setTextareaValue(e.target.value)}
        ></textarea>
        <p>
          {textareaValue.length}/{maxLenght}
        </p>
      </div>
    </div>
  );
};

export default AboutSubStep3;
