import React from "react";

type StandartInputProps = {
  value: string | undefined;
  onChangeFunc: (value: any) => void;
  width?: string;
  height?: string;
  fontSize?: string;
  backgroundColor?: string;
  defaultValue?: string | number;
  placeholder?: string;
  padding?: string;
};

const StandartInput = ({
  value,
  onChangeFunc,
  width,
  height,
  fontSize,
  backgroundColor,
  defaultValue,
  placeholder,
  padding,
}: StandartInputProps) => {
  return (
    <input
      placeholder={placeholder ? placeholder : ""}
      type="text"
      /*    defaultValue={defaultValue ? defaultValue : value} */
      value={value}
      onChange={(e) => onChangeFunc(e.target.value)}
      style={{
        width: width ? width : "100%",
        height: height ? height : "auto",
        backgroundColor: backgroundColor ? backgroundColor : "#e0e0e0",
        outline: "none",
        border: "none",
        fontSize: fontSize ? fontSize : "18px",
        padding: padding ? padding : "15px 10px",
        borderRadius: "10px",
      }}
    />
  );
};

export default StandartInput;
