import React, { useContext, useEffect, useState } from "react";
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  formatDate,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import s from "./Calendar.module.css";
import { EventInput } from "@fullcalendar/core";
import "./Calendar.css";
import ModalWindow from "../ModalWindow/ModalWindow";
import { useNavigate } from "react-router-dom";
import ClockIcon from "../../assets/HomePage/clock.svg";
import DeleteIcon from "../../assets/HomePage/delete-icon.svg";
import ChangeIcon from "../../assets/HomePage/change-icon.svg";
import JoinIcon from "../../assets/HomePage/join-icon.svg";
import { ContextProvider } from "../../contextProvider";
import UsersApi from "../../api/usersApi";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import { checkElapsedTime, checkTimeRemaining } from "../../utils/general";
import { Tooltip } from "@mui/material";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as moment from "moment";
import strings from "../../localization";
import allLocales from "@fullcalendar/core/locales-all";
dayjs.extend(utc);

interface DemoAppState {
  weekendsVisible: boolean;
  currentEvents: EventApi[];
}

export type TypeEventData = {
  id: number;
  title: string;
  start: string;
  end: string;
  userId?: string;
  bookedSessionId?: string | undefined;
  className?: string;
  avatar?: string | undefined;
  name?: string;
  surname?: string;
};

type CalendarProps = {
  eventsData: TypeEventData[] | null;
};

let eventGuid = 0;
const today = new Date();
const tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

const dayAfterTomorrow = new Date();
dayAfterTomorrow.setDate(today.getDate() + 2);

export function createEventId() {
  return String(eventGuid++);
}

const Calendar = ({ eventsData }: CalendarProps) => {
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const [avatar, setAvatar] = useState("");
  const [weekendsVisible, setWeekendsVisible] = useState<boolean>(true);
  const [currentEventsData, setCurrentEventsData] = useState<EventInput[]>([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [selectedEventData, setSelectedEventData] =
    useState<TypeEventData | null>(null);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);

  /*   const handleDateSelect = (selectInfo: DateSelectArg) => {
    let title = prompt("Please enter a new title for your event");

    if (title) {
      setCurrentEvents((prevEvents: EventApi[]) => [
        ...prevEvents,
        {
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        } as unknown as EventApi,
      ]);
    }
  }; */

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedEventData(
      eventsData && eventsData[Number(clickInfo.event._def.publicId)]
    );
    setSessionId(
      eventsData
        ? eventsData[Number(clickInfo.event._def.publicId)].bookedSessionId
        : undefined
    );
    setTooltipVisible(!tooltipVisible);
  };

  const arrayEventsColor = [
    s.bgRed,
    s.bgGreen,
    s.bgYellow,
    s.bgIndigo,
    s.bgBlue,
    s.bgSalmon,
    s.bgDarkBlue,
    s.bgDarkGreen,
  ];
  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <div className={s.eventContainer} data-testid="event-container">
        <span>{eventContent.event.startStr.slice(11, 16)}</span>
        <span className={s.eventTitle}>{" " + eventContent.event.title}</span>
      </div>
    );
  };

  useEffect(() => {
    const initialEvents: EventInput[] | undefined = eventsData
      ? eventsData.map((event, index) => ({
          id: String(event.id),
          title: event.title,
          start: event.start,
          end: event.end,
          editable: false,
          className:
            arrayEventsColor[index % arrayEventsColor.length] +
            ` ${s.eventBlock}`,
        }))
      : undefined;

    setCurrentEventsData(initialEvents ? initialEvents : []);
  }, [eventsData]);

  const joinSession = () => {
    navigate("/session-meet", {
      state: {
        sessionId: sessionId,
      },
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const makeAsync = async () => {
      if (token && selectedEventData && selectedEventData.userId) {
        const response = await UsersApi.getUserAvatarAndUsername(
          token,
          selectedEventData.userId
        );

        if (response) setAvatar(response.avatar);
      }
    };
    makeAsync();
  }, [selectedEventData]);

  const oneAndHalfHoursAgo = new Date();
  oneAndHalfHoursAgo.setHours(oneAndHalfHoursAgo.getHours() + 3);
  oneAndHalfHoursAgo.setMinutes(oneAndHalfHoursAgo.getMinutes() - 30);

  return (
    <div className={s.container}>
      <FullCalendar
        timeZone={"Etc/GMT"}
        locales={allLocales}
        locale={
          userData?.selectedLanguage === "ua"
            ? "uk"
            : userData?.selectedLanguage
        }
        viewClassNames={s.calendarView}
        eventClassNames={s.eventContainer}
        height={"100%"}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView={window.innerWidth > 768 ? "timeGridWeek" : "timeGridDay"}
        editable={true}
        selectable={false}
        selectMirror={true}
        dayMaxEvents={3}
        weekends={weekendsVisible}
        events={currentEventsData}
        /*       select={handleDateSelect} */
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        eventStartEditable={false}
      />
      <ModalWindow
        isOpen={tooltipVisible}
        setIsOpen={setTooltipVisible}
        width="fit-content"
        height="fit-content"
      >
        {selectedEventData && (
          <div data-testid="event-modal" className={s.eventModalContainer}>
            <p className={s.sessionModalTitle}>{strings.calendarModalTitle}</p>
            <div className={s.infoModalBlock}>
              <NavigateToProfile
                userId={
                  selectedEventData.userId ? selectedEventData.userId : ""
                }
              >
                <div className={s.personInfo}>
                  <CircleAvatar
                    userId={
                      selectedEventData.userId ? selectedEventData.userId : ""
                    }
                    name={selectedEventData.name ? selectedEventData.name : ""}
                    surname={
                      selectedEventData.surname ? selectedEventData.surname : ""
                    }
                    fontSize="32px"
                    height="80px"
                    width="80px"
                    marginRight="1rem"
                  />
                  <div className={s.nameTitle}>
                    {selectedEventData.name} {selectedEventData.surname}
                  </div>
                </div>
              </NavigateToProfile>
              <div className={s.timeInfo}>
                <img src={ClockIcon} alt="" />
                <span>{selectedEventData.start.slice(11, 16)}</span>
              </div>
            </div>
            {/*  <div className={s.themeTitle}>
              <span>Theme: </span>anxiety and stress
            </div> */}
            <div className={s.buttonsBlock}>
              {checkTimeRemaining(
                new Date(selectedEventData.start.replace("Z", "")),
                userData?.timezone!
              ) &&
              checkElapsedTime(
                new Date(selectedEventData.start.replace("Z", "")),
                userData?.timezone!
              ) ? (
                <button className={s.joinBtn} onClick={joinSession}>
                  {strings.calendarModalJoinBtn} <img src={JoinIcon} alt="" />
                </button>
              ) : (
                <Tooltip
                  title={"It's not time yet to connect to this session"}
                  placement="right"
                  arrow
                >
                  <button className={s.notActiveJoinButton}>
                    {strings.calendarModalJoinBtn}
                  </button>
                </Tooltip>
              )}
              <button className={s.changeBtn}>
                {strings.calendarModalChangeBtn} <img src={ChangeIcon} alt="" />
              </button>
              <button className={s.deleteBtn}>
                {strings.calendarModalDeleteBtn} <img src={DeleteIcon} alt="" />
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    </div>
  );
};

export default Calendar;
