import React, { useContext, useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  menuClasses,
  MenuItemStyles,
  SubMenu,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import s from "./SideBar.module.css";
import Burger from "../Burger/Burger";
import { TypeMenuItem } from "../../constans/sidebar-menu";
import { theme } from "../../utils/theme";
import { ReactComponent as LogoutIcon } from "../../assets/SideBar/logout.svg";
import Logo from "../../assets/logo.png";
import "./SideBar.css";
import { logout } from "../../utils/general";
import strings from "../../localization";
import { ReactComponent as HomeIcon } from "../../assets/SideBar/home.svg";
import { ReactComponent as TodoIcon } from "../../assets/SideBar/todo.svg";
import { ReactComponent as NotesIcon } from "../../assets/SideBar/notes.svg";
import { ReactComponent as TreeQuizIcon } from "../../assets/SideBar/tree-quiz.svg";
import { ReactComponent as CompanyIcon } from "../../assets/SideBar/company-icon.svg";
import { ReactComponent as MyProgramsIcon } from "../../assets/SideBar/my-programs.svg";
import { ReactComponent as SpecialistIcon } from "../../assets/Company/magnifier.svg";
import { ContextProvider } from "../../contextProvider";

type Theme = "light" | "dark";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "red",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const SideBar = () => {
  const selectLanguage = localStorage.getItem("lang");
  const userHaveProgramsStored = localStorage.getItem("userHavePrograms");
  const userHavePrograms =
    userHaveProgramsStored !== null
      ? JSON.parse(userHaveProgramsStored)
      : false;
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const isUserCompany = localStorage.getItem("haveCompany");
  const { selectLang } = useContext(ContextProvider);
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState(
    localStorage.getItem("sidebarCollapsed") === "true"
  );
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(true);
  const [theme, setTheme] = React.useState<Theme>("dark");
  const [sidebareMenuData, setSidebareMenuData] = useState<
    TypeMenuItem[] | null
  >(null);
  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRtl(e.target.checked);
  };
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? "dark" : "light");
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", String(collapsed));
  }, [collapsed]);

  useEffect(() => {
    if (selectLanguage) {
      strings.setLanguage(selectLanguage);
    }

    if (isSpecialist) {
      setSidebareMenuData(
        !userHavePrograms
          ? [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
              {
                pathname: "/",
                dataTestId: "sessions-link",
                icon: CompanyIcon,
                title: strings.companyMenuTitle,
                submenu: true,
                submenuItems: [
                  {
                    title: strings.dashboardMenuTitle,
                    path: "/company-dashboard",
                  },
                  {
                    title: strings.programsMenuTitle,
                    path: "/company-programs",
                  },
                  {
                    title: strings.employersMenuTitle,
                    path: "/company-employers",
                  },
                ],
              },
            ]
          : [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
              {
                pathname: "/my-programs-specialist",
                dataTestId: "sessions-link",
                icon: MyProgramsIcon,
                title: strings.myProgramsMenuTitle,
                submenu: false,
              },
              {
                pathname: "/",
                dataTestId: "sessions-link",
                icon: CompanyIcon,
                title: strings.companyMenuTitle,
                submenu: true,
                submenuItems: [
                  {
                    title: strings.dashboardMenuTitle,
                    path: "/company-dashboard",
                  },
                  {
                    title: strings.programsMenuTitle,
                    path: "/company-programs",
                  },
                  {
                    title: strings.employersMenuTitle,
                    path: "/company-employers",
                  },
                ],
              },
            ]
      );
    }

    if (!isSpecialist) {
      setSidebareMenuData(
        !userHavePrograms
          ? [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/specialist-find",
                dataTestId: "home-link",
                icon: SpecialistIcon,
                title: strings.specialistsMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
              {
                pathname: "/tree-quiz",
                dataTestId: "sessions-link",
                icon: TreeQuizIcon,
                title: strings.treeQuizMenuTitle,
                submenu: false,
                strokeColor: true,
              },
              {
                pathname: "/",
                dataTestId: "company",
                icon: CompanyIcon,
                title: strings.companyMenuTitle,
                submenu: true,
                submenuItems: [
                  {
                    title: strings.dashboardMenuTitle,
                    path: "/company-dashboard",
                  },
                  {
                    title: strings.programsMenuTitle,
                    path: "/company-programs",
                  },
                  {
                    title: strings.employersMenuTitle,
                    path: "/company-employers",
                  },
                ],
              },
            ]
          : [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/specialist-find",
                dataTestId: "home-link",
                icon: SpecialistIcon,
                title: strings.specialistsMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
              {
                pathname: "/tree-quiz",
                dataTestId: "sessions-link",
                icon: TreeQuizIcon,
                title: strings.treeQuizMenuTitle,
                submenu: false,
                strokeColor: true,
              },
              {
                pathname: "/my-programs",
                dataTestId: "sessions-link",
                icon: MyProgramsIcon,
                title: strings.myProgramsMenuTitle,
                submenu: false,
              },
              {
                pathname: "/",
                dataTestId: "company",
                icon: CompanyIcon,
                title: strings.companyMenuTitle,
                submenu: true,
                submenuItems: [
                  {
                    title: strings.dashboardMenuTitle,
                    path: "/company-dashboard",
                  },
                  {
                    title: strings.programsMenuTitle,
                    path: "/company-programs",
                  },
                  {
                    title: strings.employersMenuTitle,
                    path: "/company-employers",
                  },
                ],
              },
            ]
      );
    }
  }, [isSpecialist, userHaveProgramsStored, selectLanguage]);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 1
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
      borderRadius: 20,
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      [`&.${menuClasses.active}`]: {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 1 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
      "&:hover": {
        /* backgroundColor:  "transparent" hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 1 : 1
        ), */
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div
      className={s.sidebarContainer}
      style={{
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar
        className={!collapsed ? s.sideBar : s.sideBarClose}
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        rtl={rtl}
        breakPoint="md"
        backgroundColor={"white"}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <div className={s.sidebarContent}>
          <div className={s.logoBlock}>
            {!collapsed && <img src={Logo} alt="LOGO" />}
            <Burger action={setCollapsed} value={collapsed} />
          </div>

          <div style={{ flex: 1, marginBottom: "32px" }}>
            <Menu menuItemStyles={menuItemStyles}>
              {sidebareMenuData &&
                sidebareMenuData.map((item, index) =>
                  item.hidden ? (
                    <span key={index}></span>
                  ) : (
                    <div
                      key={index}
                      className={
                        location.pathname === item.pathname
                          ? s.menuItemSelect
                          : s.menuItem
                      }
                    >
                      {item.submenu ? (
                        <div style={{ position: "relative", width: "100%" }}>
                          <span
                            style={{
                              opacity:
                                location.pathname === "/company-dashboard" ||
                                location.pathname === "/company-programs" ||
                                location.pathname === "/company-employers"
                                  ? 1
                                  : 0,
                              position: "absolute",
                              top: "5px",
                            }}
                            className={
                              collapsed
                                ? s.collapsedMenuLeftBlock
                                : s.menuLeftBlock
                            }
                          ></span>
                          {item.dataTestId !== "company" ||
                            (isUserCompany && (
                              <SubMenu
                                className={s.subMenu}
                                label={!collapsed ? item.title : ""}
                                style={{
                                  width: collapsed ? "60%" : "80%",
                                  borderRadius: "5px",
                                  marginLeft: collapsed
                                    ? "1rem"
                                    : "calc(10px + 1rem)",
                                  paddingLeft:
                                    collapsed &&
                                    (location.pathname ===
                                      "/company-dashboard" ||
                                      location.pathname ===
                                        "/company-programs" ||
                                      location.pathname ===
                                        "/company-employers")
                                      ? "5px"
                                      : collapsed
                                      ? "6px"
                                      : "15px",
                                  paddingRight:
                                    collapsed &&
                                    (location.pathname ===
                                      "/company-dashboard" ||
                                      location.pathname ===
                                        "/company-programs" ||
                                      location.pathname ===
                                        "/company-employers")
                                      ? 0
                                      : "20px",
                                }}
                                key={index}
                                data-testid={item.dataTestId}
                                active={
                                  location.pathname === "/company-dashboard" ||
                                  location.pathname === "/company-programs" ||
                                  location.pathname === "/company-employers"
                                    ? true
                                    : false
                                }
                                icon={
                                  <div className={s.block}>
                                    <item.icon
                                      className={
                                        location.pathname ===
                                          "/company-dashboard" ||
                                        location.pathname ===
                                          "/company-programs" ||
                                        location.pathname ===
                                          "/company-employers"
                                          ? item.strokeColor
                                            ? s.subMenuIconStrokeSelect
                                            : s.subMenuIconSelect
                                          : item.strokeColor
                                          ? s.subMenuIconStroke
                                          : s.subMenuIcon
                                      }
                                    />
                                  </div>
                                }
                                /*  onClick={
                                  !collapsed
                                    ? undefined
                                    : (e) => e.stopPropagation()
                                } */
                              >
                                {item.submenuItems &&
                                  item.submenuItems.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      component={<Link to={item.path} />}
                                      className={
                                        location.pathname === item.path
                                          ? s.subMenuItemSelected
                                          : s.submenuItem
                                      }
                                    >
                                      {/* !collapsed &&  */ item.title}
                                    </MenuItem>
                                  ))}
                              </SubMenu>
                            ))}
                        </div>
                      ) : (
                        <>
                          <span
                            style={{
                              opacity:
                                location.pathname === item.pathname ? 1 : 0,
                            }}
                            className={
                              collapsed
                                ? s.collapsedMenuLeftBlock
                                : s.menuLeftBlock
                            }
                          ></span>
                          <MenuItem
                            style={{
                              width: collapsed ? "75%" : "90%",
                              borderRadius: "5px",
                              paddingLeft:
                                collapsed && location.pathname === item.pathname
                                  ? "5px"
                                  : "15px",
                              paddingRight:
                                collapsed && location.pathname === item.pathname
                                  ? 0
                                  : "20px",
                            }}
                            key={index}
                            data-testid={item.dataTestId}
                            active={
                              location.pathname === item.pathname ? true : false
                            }
                            component={<Link to={item.pathname} />}
                            icon={
                              <div className={s.block}>
                                <item.icon
                                  className={
                                    item.strokeColor
                                      ? s.menuIconStroke
                                      : s.menuIcon
                                  }
                                />
                              </div>
                            }
                            className={s.menuLink}
                          >
                            {!collapsed && item.title}
                          </MenuItem>
                        </>
                      )}
                    </div>
                  )
                )}
            </Menu>
          </div>
          <div
            className={
              collapsed ? s.bottomButtonsBlockCollapsed : s.bottomButtonsBlock
            }
          >
            <button className={s.logoutButton} onClick={logout}>
              <LogoutIcon className={s.logoutButtonIcon} />
              {!collapsed && <p>{strings.logOut}</p>}
            </button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;
