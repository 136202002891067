import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./CommentComponent.module.css";
import StandartButton from "../StandartButton/StandartButton";
import { ReactComponent as LikeIcon } from "../../assets/VideoSession/like-shapes.svg";
import AnimateHeight from "react-animate-height";
import CustomRating from "../Rating/Rating";
import { ContextProvider } from "../../contextProvider";
import SessionsApi from "../../api/sessionApi";
import CheckIcon from "../../assets/SpecialistSetupInfo/checked.svg";
import strings from "../../localization";
import { DefaultComments } from "../../types/TypeSession";
import { SuccesNotify } from "../../utils/toaster";

const staticCommets = [
  "Great session, helped me understand myself better.",
  "Very useful and informative, thank you!",
  "I feel much better after the conversation.",
  "Received a lot of valuable advice and recommendations.",
  "The session was productive and inspiring.",
  "I liked it, I will continue working with this specialist.",
  "I feel I have made progress in solving my problems.",
  "The session gave me a new perspective on my situation.",
];

type CommentComponentProps = {
  sessionId: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
};

const CommentComponent = ({
  sessionId,
  setModalOpen,
}: CommentComponentProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [selectedComments, setSelectedComments] = useState<number[]>([]);
  const [reviewValue, setReviewValue] = useState("");
  const [reviewCollapsed, setReviewCollapsed] = useState(true);
  const [ratingValue, setRatingValue] = useState(3);
  const [isReviewSend, setIsReviewSend] = useState(false);
  const [defualtCommentsData, setDefualtCommentsData] = useState<
    DefaultComments[] | null
  >(null);

  const toggleComment = (index: number) => {
    const newSelectedComments = [...selectedComments];
    const commentIndex = newSelectedComments.indexOf(index);
    if (commentIndex === -1) {
      newSelectedComments.push(index);
    } else {
      newSelectedComments.splice(commentIndex, 1);
    }
    setSelectedComments(newSelectedComments);
  };
  const onChangeRating = (value: number) => {
    setRatingValue(value);
  };

  const sendComment = async () => {
    if (token && userData && userData._id && sessionId && defualtCommentsData) {
      const defaultComments = selectedComments.map(
        (item, index) => defualtCommentsData[item]._id
      );

      const payload = {
        sessionId: sessionId,
        userId: userData._id,
        date: new Date(),
        rating: ratingValue,
        customComment: reviewValue,
        defaultComments: defaultComments ? defaultComments : [],
      };

      const response = await SessionsApi.commentSession(token, payload);

      if (response.status) {
        setModalOpen(false);
        SuccesNotify(strings.sccesReviewSend);
      }
    }
  };
  useEffect(() => {
    if (!token) return;
    const makeAsync = async () => {
      const response = await SessionsApi.getAllDefaultComments(token);

      if (response.status && response.defaultComments) {
        setDefualtCommentsData(response.defaultComments);
      }
    };
    makeAsync();
  }, [token]);

  return (
    <div className={s.conteiner}>
      <div className={s.content}>
        <div className={s.headTitle}>{strings.accesQuality}</div>
        <div className={s.rateBlock}>
          <div className={s.rateTitle}>{strings.rateSessionTitle}</div>
          <div className={s.starBlock}>
            <CustomRating onChangeValue={onChangeRating} value={ratingValue} />
          </div>
        </div>
        <div className={s.staticCommetsContainer}>
          <span className={s.staticCommetTitle}>{strings.whatCanYouTell}</span>
          <div className={s.staticCommentBlock}>
            {defualtCommentsData &&
              defualtCommentsData.map((item, index) => (
                <span
                  key={index}
                  className={
                    selectedComments.includes(index)
                      ? s.selectedStaticCommentElement
                      : s.staticCommentElement
                  }
                  onClick={() => toggleComment(index)}
                >
                  {
                    item.labels.find(
                      (item) => item.language === userData?.selectedLanguage
                    )?.text
                  }
                </span>
              ))}
          </div>
        </div>
        <AnimateHeight height={reviewCollapsed ? 0 : "auto"}>
          <div className={s.reviewBlock}>
            <span className={s.reviewTitle}>{strings.writeYourReviw}</span>
            <textarea
              name=""
              id=""
              className={s.reviewTextArea}
              value={reviewValue}
              onChange={(e) => setReviewValue(e.target.value)}
            ></textarea>
          </div>
        </AnimateHeight>
        <div className={s.buttonsBlock}>
          {!reviewCollapsed ? (
            <div></div>
          ) : (
            <button
              className={s.reviewButton}
              onClick={() => setReviewCollapsed(false)}
            >
              {strings.leaveReview} <LikeIcon className={s.likeIcon} />
            </button>
          )}
          <StandartButton
            action={sendComment}
            buttonTitle={strings.sendFeedbackBtn}
            height="40px"
            fontSize="16px"
          />
        </div>
      </div>
    </div>
  );
};

export default CommentComponent;
