import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import StandartButton from "../../../components/StandartButton/StandartButton";

import s from "./Price.module.css";
import { ContextProvider } from "../../../contextProvider";
import SpecialistApi from "../../../api/specialistApi";
import AnimateHeight, { Height } from "react-animate-height";
import PlusIcon from "../../../assets/SpecialistSetupInfo/plusIcon.svg";
import WorkTimeInput from "./WorkTimeInput/WorkTimeInput";
import ExcludeIcon from "../../../assets/SpecialistSetupInfo/Exclude.svg";
import PlusIcon2 from "../../../assets/SpecialistSetupInfo/plus.svg";
import CancelIcon from "../../../assets/SpecialistSetupInfo/cancel.svg";
import UsersApi from "../../../api/usersApi";
import Notify from "../../../utils/toaster";
import { ToastContainer } from "react-toastify";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import { transformSchedule } from "../../../utils/shedule";
import strings from "../../../localization";
import { ReactComponent as TokenIcon } from "../../../assets/Header/heart-tick.svg";
import Input from "../../../components/Input/Input";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

type WorkTime = {
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thursday: string[];
  friday: string[];
  saturday: string[];
  sunday: string[];
};

type TypePticeProps = {
  setLoader: Dispatch<SetStateAction<boolean>>;
  subStep: { current: number; total: number };
  setSubStep: Dispatch<SetStateAction<{ current: number; total: number }>>;
};

const Price = ({ setLoader, subStep, setSubStep }: TypePticeProps) => {
  const { userData, setIsSetupProfileInfo, isAuth } =
    useContext(ContextProvider);

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [timeZone, setTimeZone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [inputValues, setInputValues] = useState({
    price: "",
    discountOne: "",
    discountTwo: "",
    discountThree: "",
  });
  const [daysState, setDaysState] = useState([
    {
      dayValue: "Monday",
      daytitle: strings.scheduleMonday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Tuesday",
      daytitle: strings.scheduleTuesday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Wednesday",
      daytitle: strings.scheduleWednesday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Thursday",
      daytitle: strings.scheduleThursday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Friday",
      daytitle: strings.scheduleFriday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Saturday",
      daytitle: strings.scheduleSaturday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Sunday",
      daytitle: strings.scheduleSunday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
  ]);

  const calcDiscount = (price: number, discount: number) => {
    const newPrice = price - (price * discount) / 100;
    return Math.round(newPrice) + "$";
  };
  const handleInputChange = (
    value: string,
    inputName: string,
    maxValue?: number
  ) => {
    if (/^\d*$/.test(value)) {
      if (maxValue !== undefined) {
        value =
          value !== ""
            ? Math.min(parseInt(value, 10), maxValue).toString()
            : "";
      }

      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [inputName]: value,
      }));
    }
  };

  const isWorkTimeSpecified = () => {
    return daysState.some((day) => {
      return day.workTime.some((time) => time.from !== "" && time.to !== "");
    });
  };

  const nextStep = async () => {
    const token = localStorage.getItem("token");

    if (
      token &&
      userData?.specialistId &&
      (userData?.specialistId ||
        (userData?.specialistId && userData?.specialistId._id))
    ) {
      const specialistId = userData?.specialistId._id
        ? userData?.specialistId._id
        : userData.specialistId.toString();
      if (subStep.current === 1) {
        if (inputValues.price === "") return Notify(strings.specifyPrice);
        await SpecialistApi.update(
          {
            _id: specialistId,
            price: Number(inputValues.price),
          },
          token
        );
        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 2) {
        if (!isWorkTimeSpecified()) return Notify(strings.indicateSchedule);

        const scheduleData = transformSchedule(daysState);

        const response = await UsersApi.updateUserData(token, {
          _id: userData?._id,
          schedule: scheduleData,
          timezone: timeZone,
        });
        if (!response.status && response.message) {
          Notify(response.message);
        }
        if (response.status) {
          const registerResponse = await UsersApi.updateUserData(token, {
            _id: userData._id,
            registerFinished: true,
          });
          if (registerResponse.status) {
            setIsSetupProfileInfo(true);
          }
        }
      }
    }
  };

  const updateWorkTime = (index: number) => {
    setDaysState((prev) => {
      const updatedState = [...prev];
      updatedState[index] = {
        ...prev[index],
        isOpen: !prev[index].isOpen,
      };
      return updatedState;
    });
  };
  const handleCancelIconClick = (dayIndex: number, timeIndex: number) => {
    setDaysState((prev) => {
      const updatedState = [...prev];
      const day = { ...updatedState[dayIndex] };
      day.workTime = day.workTime.filter((_, i) => i !== timeIndex);
      updatedState[dayIndex] = day;

      return updatedState;
    });
  };
  const handleTimeZoneChange = (value: any) => {
    setTimeZone(value);
  };
  return (
    <div className={s.container}>
      {subStep.current === 1 && (
        <div className={s.subStepBlock}>
          <p className={s.stepTitle}>{strings.priceSetupTitle}</p>
          <p className={s.substepText}>{strings.priceSetupDescription}</p>
          <div className={s.priceInputBlock}>
            <Input
              inputValue={inputValues.price}
              onChangeInput={(value) => handleInputChange(value, "price")}
              isVisible
              required
            />
            <TokenIcon className={s.inputDollarUnit} />
          </div>
          {/* <p className={s.stepTitle}>Discount on therapy plans</p>
          <p className={s.substepText}>
            Here you can display discounts for customers. We recommend
            increasing the discount as the number of sessions increases.
          </p> */}
          {/*  <div className={s.discountBlock}>
            <div className={s.discountElement}>
              <p className={s.stepTitle}>
                4 classes <br /> ( 1 month )
              </p>
              <div className={s.discountInputBlock}>
                <div style={{ position: "relative" }}>
                  <p className={s.inputUnit}>%</p>
                  <input
                    type="text"
                    className={s.textInput}
                    value={inputValues.discountOne}
                    onChange={(e) => handleInputChange(e, "discountOne", 99)}
                  />
                </div>
                <p
                  className={
                    inputValues.price !== "" && inputValues.discountOne !== ""
                      ? s.discountPriceTitle
                      : s.discountPriceTitleOpacity
                  }
                >
                  {inputValues.price !== "" && inputValues.discountOne !== ""
                    ? calcDiscount(
                        Number(inputValues.price),
                        Number(inputValues.discountOne)
                      )
                    : "100"}
                </p>
              </div>
            </div>
            <div className={s.discountElement}>
              <p className={s.stepTitle}>
                12 classes <br /> ( 3 month )
              </p>
              <div className={s.discountInputBlock}>
                <div style={{ position: "relative" }}>
                  <p className={s.inputUnit}>%</p>
                  <input
                    type="text"
                    className={s.textInput}
                    value={inputValues.discountTwo}
                    onChange={(e) => handleInputChange(e, "discountTwo", 99)}
                  />
                </div>
                <p>
                  {inputValues.price !== "" && inputValues.discountTwo !== ""
                    ? calcDiscount(
                        Number(inputValues.price),
                        Number(inputValues.discountTwo)
                      )
                    : " "}
                </p>
              </div>
            </div>
            <div className={s.discountElement}>
              <p className={s.stepTitle}>
                24 classes <br /> ( 6 month )
              </p>
              <div className={s.discountInputBlock}>
                <div style={{ position: "relative" }}>
                  <p className={s.inputUnit}>%</p>
                  <input
                    type="text"
                    className={s.textInput}
                    value={inputValues.discountThree}
                    onChange={(e) => handleInputChange(e, "discountThree", 99)}
                  />
                </div>

                <p>
                  {inputValues.price !== "" && inputValues.discountThree !== ""
                    ? calcDiscount(
                        Number(inputValues.price),
                        Number(inputValues.discountThree)
                      )
                    : " "}
                </p>
              </div>
            </div>
          </div> */}
        </div>
      )}
      {subStep.current === 2 && (
        <div className={s.subStepBlock}>
          <div className={s.timezoneBlock}>
            <h3>{strings.timezoneSetupTitle}</h3>
            <MultipleSelect
              data={options.map((option) => ({
                value: option.value,
                lable: option.label,
              }))}
              multiplie={false}
              setValue={handleTimeZoneChange}
              value={timeZone}
              placeholder={strings.timeZonePlaceHolder}
            />
          </div>
          <h3>{strings.scheduleSetupTitle}</h3>
          <p>{strings.scheduleDescription}</p>
          {Array.isArray(daysState) &&
            daysState.map((item, index) => (
              <div className={s.timeWorkPickBlock} key={item.daytitle}>
                <div className={s.dayTitleBlock}>
                  <p>{item.daytitle}</p>
                  {!daysState[index].isOpen && (
                    <img
                      src={PlusIcon}
                      alt=""
                      onClick={() =>
                        setDaysState((prev) => {
                          const updatedState = [...prev];
                          updatedState[index] = {
                            ...prev[index],
                            isOpen: !prev[index].isOpen,
                          };
                          return updatedState;
                        })
                      }
                    />
                  )}
                </div>
                <AnimateHeight
                  id="example-panel"
                  duration={500}
                  height={daysState[index].isOpen ? "auto" : 0}
                >
                  <div className={s.worktimeInputsBlock}>
                    <div className={s.inputs}>
                      {daysState[index].workTime.map((timeItem, timeIndex) => (
                        <div className={s.inputElementBlock} key={timeIndex}>
                          <WorkTimeInput
                            inputsValue={daysState}
                            mainIndex={index}
                            timeIndex={timeIndex}
                            day={item.daytitle.toLowerCase()}
                            setInputsValue={setDaysState}
                            inputBorder
                          />
                          <img
                            src={CancelIcon}
                            alt=""
                            className={s.cancelIcon}
                            onClick={() =>
                              handleCancelIconClick(index, timeIndex)
                            }
                          />
                        </div>
                      ))}
                      <div
                        className={s.plusButton}
                        role="button"
                        onClick={() =>
                          setDaysState((prev) => {
                            const updatedState = [...prev];
                            updatedState[index].workTime = [
                              ...updatedState[index].workTime,
                              { from: "", to: "" }, // Изменения здесь
                            ];
                            return updatedState;
                          })
                        }
                      >
                        <img src={PlusIcon2} alt="" />
                      </div>
                    </div>
                    <img
                      src={ExcludeIcon}
                      alt=""
                      onClick={() => updateWorkTime(index)}
                    />
                  </div>
                </AnimateHeight>
              </div>
            ))}
        </div>
      )}
      <div className={s.continueButtonBlock}>
        <StandartButton
          buttonTitle="Continue"
          action={nextStep}
          width="100%"
          height="100%"
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Price;
