import React, { Dispatch, SetStateAction, useState } from "react";
import s from "../CreatingProfile.module.css";
import strings from "../../../../localization";
import Notify from "../../../../utils/toaster";

type SubSteps3Props = {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
};

const SubSteps3 = ({ files, setFiles }: SubSteps3Props) => {
  const maxFiles = 20;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles) {
      const totalFiles = files.length + newFiles.length;
      if (totalFiles <= maxFiles) {
        setFiles((prevFiles) => [...prevFiles, ...Array.from(newFiles)]);
      } else {
        Notify(strings.toManyFiles);
      }
    }
  };
  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className={s.subStepBlock}>
      <p className={s.stepTitle}>{strings.setupDiplomasTitle}</p>
      <p>{strings.from1To20Diplomas}</p>
      <div className={s.uploadDocumentsBlock}>
        {files.length <= 20 && (
          <label className={s.fileInputBlock}>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".pdf, image/*"
              multiple
              onChange={handleFileChange}
            />
            <div className={s.uploadButton}>{strings.setupUploadFiles}</div>
          </label>
        )}
        {files.map((file, index) => (
          <div key={index} className={s.uploadedFileBlock}>
            <span className={s.fileName}>{file.name}</span>
            <button
              className={s.removeButton}
              onClick={() => handleRemoveFile(index)}
            >
              {strings.setupRemoveBtn}
            </button>
          </div>
        ))}
      </div>
      <p>{strings.setupDiplomasDescription}</p>
    </div>
  );
};

export default SubSteps3;
