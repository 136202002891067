import React, { useContext, useEffect, useState } from "react";
import s from "./UserQuize.module.css";
import PersonInfo from "./PersonInfo/PersonInfo";
import { ReactComponent as ArrowRightIcon } from "../../assets/SpecialistSetupInfo/arrow-right.svg";
import { ReactComponent as ProfileIcon } from "../../assets/SpecialistSetupInfo/profile.svg";
import Quieze from "./Quieze/Quieze";
import Image from "../../assets/SpecialistSetupInfo/mentor.jpg";
import DescriptionStep from "./DescriptionStep/DescriptionStep";
import QuizeApi from "../../api/quiezeApi";
import { ContextProvider } from "../../contextProvider";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import { ReactComponent as LogOutIcon } from "../../assets/SideBar/logout.svg";
import { logout } from "../../utils/general";
import strings from "../../localization";
import UsersApi from "../../api/usersApi";
import HeaderChangeLangs from "../../components/HeaderChangeLangs/HeaderChangeLangs";
import { Question } from "../../types/TypeQuieze";

export type TypeQuestionWithId = {
  _id?: string;
  title: string;
  description?: string;
  answers: string[];
  multiselect?: boolean;
};

type UserQuizesData = {
  mainQuizData: {
    quizId: string | undefined;
    questions: Question[] | undefined;
  };
  coachQuizData: {
    quizId: string | undefined;
    questions: Question[] | undefined;
  };
  mentorQuizData: {
    quizId: string | undefined;
    questions: Question[] | undefined;
  };
  psyhologistQuizData: {
    quizId: string | undefined;
    questions: Question[] | undefined;
  };
};

const UserQuize = () => {
  const { userData } = useContext(ContextProvider);
  const [dateOfBith, setDateOfBith] = useState({
    day: 1,
    month: 1,
    year: 2000,
  });
  const [textInputValues, setTextInputValues] = useState({
    name: "",
    surname: "",
  });
  const [quizResults, setQuizResults] = useState<
    { questionId: string; answers: string[] }[]
  >([]);
  const [isLoader, setIsLoader] = useState(false);
  const [genderValue, setGenderValue] = useState<string>("male");
  const [avatar, setAvatar] = useState<File | null>(null);
  const [firstQuizeStep, setFirstQuizeStep] = useState(0);
  const [firstQuiezeValue, setFirstQuiezeValue] = useState("");
  const [secondQuizeStep, setSecondQuizeStep] = useState(0);
  const [secondQuiezeValue, setSecondQuiezeValue] = useState<string>("");
  const [thirdQuizeStep, setThirdQuizeStep] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const [mainQuizResult, setMainQuizResult] = useState<string | null>(null);
  const [correctQuiz, setCorrectQuiz] = useState<{
    quizId: string | undefined;
    questions: Question[] | undefined;
  } | null>(null);
  const [quizesData, setQuizesData] = useState<UserQuizesData>({
    mainQuizData: { quizId: undefined, questions: undefined },
    coachQuizData: {
      quizId: undefined,
      questions: undefined,
    },
    mentorQuizData: {
      quizId: undefined,
      questions: undefined,
    },
    psyhologistQuizData: {
      quizId: undefined,
      questions: undefined,
    },
  });

  const handlePrevStep = () => {
    if (
      (firstQuizeStep === 0 && activeStep === 2) ||
      (secondQuizeStep === 0 && activeStep === 4) ||
      (thirdQuizeStep === 0 && activeStep === 6) ||
      activeStep === 3 ||
      activeStep === 5
    ) {
      setActiveStep(activeStep - 1);
    } else if (activeStep === 2 && firstQuizeStep > 0) {
      setFirstQuizeStep(firstQuizeStep - 1);
    } else if (activeStep === 4 && secondQuizeStep > 0) {
      setSecondQuizeStep(secondQuizeStep - 1);
    } else if (activeStep === 6 && thirdQuizeStep > 0) {
      setThirdQuizeStep(thirdQuizeStep - 1);
    }
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (userData && userData._id) {
        setIsLoader(true);
        const response = await QuizeApi.getQuizResultByTitle(
          "Main quiz",
          userData._id
        );
        if (!response.status && mainQuizResult) {
          const quizData =
            mainQuizResult === "Coach"
              ? quizesData.coachQuizData
              : mainQuizResult === "Mentor"
              ? quizesData.mentorQuizData
              : quizesData.psyhologistQuizData;

          setCorrectQuiz(quizData);
        }
        if (response.status) {
          const quizData =
            response.quizResult?.results[0].answers[0] === "Coach"
              ? quizesData.coachQuizData
              : response.quizResult?.results[0].answers[0] === "Mentor"
              ? quizesData.mentorQuizData
              : quizesData.psyhologistQuizData;

          setCorrectQuiz(quizData);
        }
        setIsLoader(false);
      }
    };
    makeAsync();
  }, [activeStep, userData, mainQuizResult]);

  useEffect(() => {
    const makeAsync = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      const [
        responseMainQuiz,
        responseCoachQuiz,
        responseMentorQuiz,
        responsePsychologistQuiz,
      ] = await Promise.all([
        QuizeApi.getQuizByTitle("Main quiz", token),
        QuizeApi.getQuizByTitle("Coach quiz", token),
        QuizeApi.getQuizByTitle("Mentor quiz", token),
        QuizeApi.getQuizByTitle("Psyhologist quiz", token),
      ]);

      if (
        !responseMainQuiz ||
        !responseCoachQuiz ||
        !responseMentorQuiz ||
        !responsePsychologistQuiz
      )
        return;

      setQuizesData({
        mainQuizData: {
          quizId: responseMainQuiz.quiz?._id,
          questions: responseMainQuiz.quiz?.questions,
        },
        coachQuizData: {
          quizId: responseCoachQuiz.quiz?._id,
          questions: responseCoachQuiz.quiz?.questions,
        },
        mentorQuizData: {
          quizId: responseMentorQuiz.quiz?._id,
          questions: responseMentorQuiz.quiz?.questions,
        },
        psyhologistQuizData: {
          quizId: responsePsychologistQuiz.quiz?._id,
          questions: responsePsychologistQuiz.quiz?.questions,
        },
      });
    };

    makeAsync();
  }, [userData]);

  return (
    <div className={s.container}>
      <div className={s.headerContainer}>
        {activeStep === 1 ||
        (activeStep === 2 && firstQuizeStep === 0) ||
        activeStep === 3 ||
        activeStep === 5 ? (
          <div></div>
        ) : (
          <div
            role="button"
            className={s.arrowImageBlock}
            onClick={handlePrevStep}
          >
            <ArrowRightIcon className={s.arrowIcon} />
          </div>
        )}

        <div className={s.profileIconBlcok}>
          <HeaderChangeLangs />
          <LogOutIcon className={s.loguotIcon} onClick={logout} />
        </div>
      </div>
      {activeStep === 1 && (
        <PersonInfo
          avatar={avatar}
          setAvatar={setAvatar}
          dateOfBith={dateOfBith}
          genderValue={genderValue}
          setDateOfBith={setDateOfBith}
          setGenderValue={setGenderValue}
          setTextInputValues={setTextInputValues}
          textInputValues={textInputValues}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 2 && (
        <DescriptionStep
          title={strings.descriptionStepTitle}
          description1={strings.descriptionText1}
          description2={strings.descriptionText2}
          image={Image}
          setActiveStep={setActiveStep}
          setQuizResult={setQuizResults}
        />
      )}
      {activeStep === 3 && (
        <>
          {quizesData.mainQuizData.questions &&
            quizesData.mainQuizData.questions.map(
              (item, index) =>
                firstQuizeStep === index && (
                  <Quieze
                    multiselect={item.multiselect}
                    setActiveStep={setActiveStep}
                    totalSteps={quizesData.mainQuizData.questions!.length}
                    currentStep={firstQuizeStep}
                    setStep={setFirstQuizeStep}
                    quizeData={item}
                    value={firstQuiezeValue}
                    setValue={setFirstQuiezeValue}
                    activeStep={activeStep}
                    questionId={item._id!}
                    quizId={quizesData.mainQuizData.quizId}
                    setQuizResults={setQuizResults}
                    quizResults={quizResults}
                    key={index}
                    setMainQuizResult={setMainQuizResult}
                  />
                )
            )}
        </>
      )}

      {activeStep === 4 &&
        (isLoader ? (
          <ThemeProvider theme={theme}>
            <CircularProgress size={150} color="primary" />
          </ThemeProvider>
        ) : (
          <>
            {correctQuiz &&
              correctQuiz.questions &&
              correctQuiz.questions.map(
                (item, index) =>
                  secondQuizeStep === index && (
                    <Quieze
                      multiselect={item.multiselect}
                      setActiveStep={setActiveStep}
                      totalSteps={correctQuiz.questions!.length}
                      currentStep={secondQuizeStep}
                      setStep={setSecondQuizeStep}
                      quizeData={item}
                      value={secondQuiezeValue}
                      setValue={setSecondQuiezeValue}
                      activeStep={activeStep}
                      questionId={item._id!}
                      quizId={correctQuiz.quizId}
                      setQuizResults={setQuizResults}
                      quizResults={quizResults}
                      key={index}
                      setMainQuizResult={setMainQuizResult}
                    />
                  )
              )}
          </>
        ))}
      <div></div>
    </div>
  );
};

export default UserQuize;
