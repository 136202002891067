import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import socket from "../utils/socket";
import {
  MessageFile,
  TypeMessage,
  TypeSendMessagePayload,
  TypeSessionChatResponse,
} from "../types/TypeMessageChat";

const { chat } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "session/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class MessageChatApi {
  static async joinChat(sessionId: string, userId: string) {
    return new Promise<string>((resolve) => {
      socket.emit(chat.joinChat, sessionId, userId);
      socket.once(chat.onJoinChat, (response) => {
        resolve(response);
      });
    });
  }
  static async sendMessage(payload: TypeSendMessagePayload): Promise<
    TypeDefaultResponse & {
      newMessage?: TypeMessage | null;
    }
  > {
    return new Promise((resolve) => {
      socket.emit(chat.sendMessage, payload);
      socket.once(chat.onMessage, (response) => {
        resolve(response);
      });
    });
  }

  static async getMessageFiles(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & {
      files: MessageFile[];
    }
  > {
    return await ky
      .get(`${baseURL}${chat.getMessageFiles}/${id}`, {
        headers: getAuthHeader(token),
        timeout: 30000,
      })
      .then((res) => res.json());
  }

  static async getSessionChat(
    sessionId: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      avatars: { id: string; avatar: string; specialistId?: string }[];
      chat?: TypeSessionChatResponse | null;
    }
  > {
    return await ky
      .get(`${baseURL}${chat.getChat}/${sessionId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
