import React, { Dispatch, SetStateAction } from "react";
import s from "./DescriptionStep.module.css";
import { ReactComponent as NextStepIcon } from "../../../assets/SpecialistSetupInfo/next-slide.svg";

type DescriptionStepProps = {
  title: string;
  description1: string;
  description2: string;
  image: string;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setQuizResult: Dispatch<
    SetStateAction<{ questionId: string; answers: string[] }[]>
  >;
};

const DescriptionStep = ({
  title,
  description1,
  description2,
  image,
  setActiveStep,
  setQuizResult,
}: DescriptionStepProps) => {
  const nextQuizHandler = () => {
    setQuizResult([]);
    setActiveStep((prev) => prev + 1);
  };
  return (
    <div className={s.container}>
      <p className={s.mainText}>{title}</p>
      <p className={s.secondaryText}>{description1}</p>
      <img src={image} alt="" className={s.image} />
      <div className={s.actionBlock}>
        <span></span>
        <p className={s.secondaryText}>{description2}</p>
        <div
          role="button"
          className={s.nextButtonBlock}
          onClick={nextQuizHandler}
        >
          <NextStepIcon className={s.nextStepIcon} />
        </div>
      </div>
    </div>
  );
};

export default DescriptionStep;
