import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import s from "./Notes.module.css";
import NoteCard from "../../components/NoteCard/NoteCard";
import { ReactComponent as EditIcon } from "../../assets/Notes/edit.svg";
import CheckIcon from "../../assets/Notes/check.svg";
import { ContextProvider } from "../../contextProvider";
import NotesAPI from "../../api/notesApi";
import { Note } from "../../types/TypeNotes";
import { ReactComponent as ChevronIcon } from "../../assets/HomePage/chevron-up.svg";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import strings from "../../localization";
import MotionDiv from "../MyPrograms/MotionDiv/MotionDiv";
import { motion } from "framer-motion";
import CalendarIcon from "../../assets/Header/calendar.svg";
import Input from "../../components/Input/Input";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { useLocation } from "react-router-dom";
import { ResizeObserver } from "@juggle/resize-observer";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import Notify from "../../utils/toaster";
import { ToastContainer } from "react-toastify";

const currentDate = new Date();

const day = currentDate.getDate().toString().padStart(2, "0");
const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // добавляем 1, так как месяцы в JS начинаются с 0
const year = currentDate.getFullYear().toString().slice(-2); // берем только последние две цифры года

const formattedDate = `${day}.${month}.${year}`;

const Notes = () => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { noteId } = location.state || {};

  const mainRef = useRef<HTMLDivElement | null>(null);
  const [notesData, setNotesData] = useState<Note[] | null>(null);
  const [selectNote, setSelectNote] = useState<Note | null>(null);
  const [isEdite, setIsEdite] = useState(false);
  const selectNoteHandler = (noteData: Note) => {
    setSelectNote(noteData);
    setIsEdite(false);
  };
  const [newNote, setNewNote] = useState({
    title: "",
    date: formattedDate,
    noteText: "",
  });
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newNoteModal, setNewNoteModal] = useState(false);
  const [cardWidth, setCardWidth] = useState(0);
  const [loader, setLoader] = useState(false);
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (!mainRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (window.innerWidth <= 768) {
          setCardWidth(entry.contentRect.width);
        } else {
          const currentCardWidth = entry.contentRect.width / 3;
          setCardWidth(currentCardWidth);
        }
      }
    });

    observer.observe(mainRef.current);

    return () => {
      if (!mainRef.current) return;
      observer.unobserve(mainRef.current);
    };
  }, [mainRef]);

  const formateDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };

  const createNote = async () => {
    if (token && userData && userData._id) {
      const payload = {
        userId: userData._id,
        newNote: {
          title: newNote.title,
          description: newNote.noteText,
          date: new Date(),
        },
      };
      const response = await NotesAPI.createNote(token, payload);
      if (!response.status && response.message) {
        Notify(response.message);
      }
      if (response.status) {
        /*  setUpdateNoteData(updateNoteData + 1); */
        setNotesData(notesData && [...notesData, response.note]);
        setNewNoteModal(false);
        setNewNote({
          title: "",
          date: formattedDate,
          noteText: "",
        });
      }
    }
  };

  const updateNote = async () => {
    if (token && selectNote) {
      const response = await NotesAPI.updateNote(token, selectNote);

      if (response.status) {
        setNotesData((prevNotes) => {
          if (!prevNotes) return prevNotes;
          const updatedNotes = prevNotes.map((note) => {
            if (note._id === response.note._id) {
              return Object.assign({}, note, response.note);
            }
            return note;
          });

          return updatedNotes;
        });
        setIsEdite(false);
      }
    }
  };

  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight - 10 &&
      !loading
    ) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (!noteId || !notesData) return;
    const noteIndex = notesData.findIndex((note) => note._id === noteId);
    if (noteIndex === -1) return;
    setSelectNote(notesData[noteIndex]);
  }, [noteId, notesData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData && userData._id) {
        setLoader(true);
        const response = await NotesAPI.getUserNotes(
          token,
          userData._id,
          10,
          page
        );
        setLoader(false);
        if (response.status) {
          setNotesData((prevNotes) => {
            if (!prevNotes) return response.notes;
            const uniqueNotes = response.notes.filter((newNote) => {
              return !prevNotes.some(
                (prevNote) => prevNote._id === newNote._id
              );
            });

            return [...prevNotes, ...uniqueNotes];
          });
          setLoading(false);
        }
      }
    };

    makeAsync();
  }, [page]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <div className={s.container} ref={mainRef}>
      <div className={s.mobileHeaderContent}>
        <div>
          {selectNote ? (
            <span></span>
          ) : (
            <span
              className={s.mobileNewTodoButton}
              onClick={() => setNewNoteModal(true)}
            >
              <PlusIcon className={s.plusIcon} />
            </span>
          )}
        </div>
        <span className={s.mobileLocationTitle}>{strings.notesHeadTitle}</span>
      </div>
      {loader ? (
        <div className={s.laoderContainer}>
          <ThemeProvider theme={theme}>
            <CircularProgress size={150} color="primary" />
          </ThemeProvider>
        </div>
      ) : (
        <div
          className={
            selectNote
              ? `${s.noteListBlock} ${s.mobileNoteListHidden} `
              : `${s.noteListBlock} ${s.mobileNoteListVisible} ${s.rowList}`
          }
        >
          <MotionDiv flexDirection={selectNote ? "column" : "row"}>
            <div style={{ width: `calc(${cardWidth}px - 14px)` }}>
              <div
                style={{ width: `calc(100% - 52px)` }}
                className={selectNote ? s.smallNewNoteBlock : s.bigNewNoteBlock}
                onClick={() => setNewNoteModal(true)}
              >
                <span>
                  <PlusIcon className={s.plusIcon} />
                </span>
              </div>
            </div>

            {notesData ? (
              notesData.map((item, index) => (
                <motion.div
                  onClick={() => selectNoteHandler(item)}
                  key={index}
                  layout
                  transition={{
                    type: "tween",
                    duration: window.innerWidth > 768 ? 1 : 0,
                  }}
                  style={{ width: `calc(${cardWidth}px - 12px)` }}
                  className={`${s.motionBlock} ${
                    !selectNote ? s.nonSelectMotionBlock : ""
                  }`}
                >
                  <NoteCard
                    setIsEdit={setIsEdite}
                    noteId={item._id}
                    date={item.date.toString()}
                    title={item.title}
                    key={index}
                    setNotesData={setNotesData}
                    setSelectNote={setSelectNote}
                    noteData={item}
                    selectNote={selectNote}
                    isDashboardCard={false}
                  />
                </motion.div>
              ))
            ) : (
              <h2 className={s.noDataTitle}>{strings.noNotes}</h2>
            )}
            {loading && <div></div>}
          </MotionDiv>
        </div>
      )}
      <div
        className={
          selectNote
            ? `${s.notesList} ${s.mobileNotesListHidden}`
            : `${s.notesList} ${s.mobileNotesListVisible}`
        }
        ref={containerRef}
      >
        {notesData &&
          notesData.map((item, index) => (
            <div
              className={s.noteCardBlock}
              onClick={() => selectNoteHandler(item)}
              key={index}
            >
              <NoteCard
                setIsEdit={setIsEdite}
                noteId={item._id}
                date={item.date.toString()}
                title={item.title}
                key={index}
                setNotesData={setNotesData}
                setSelectNote={setSelectNote}
                noteData={item}
                selectNote={selectNote}
                isDashboardCard={false}
              />
            </div>
          ))}
        {loading && (
          <div></div>
          /*  <ThemeProvider theme={theme}>
              <CircularProgress size={50} color="primary" />
            </ThemeProvider> */
        )}
      </div>
      {selectNote && (
        <div
          className={
            selectNote
              ? `${s.notesContentBlock} ${s.mobileNoteContentVisible}`
              : `${s.notesContentBlock} ${s.mobileNoteContentHidden}`
          }
        >
          {selectNote && (
            <div className={s.noteDataBlock}>
              <div className={s.programHeaderContent}>
                <div
                  className={s.backButton}
                  onClick={() => setSelectNote(null)}
                >
                  <ChevronIcon className={s.chevronIcon} />
                  <span>{strings.myProgramBackBtn}</span>
                </div>
                <div className={s.dateBlock}>
                  <img src={CalendarIcon} alt="" />
                  {selectNote && (
                    <span>{formateDate(new Date(selectNote.date!))}</span>
                  )}
                  <div className={s.editButtonBlock}>
                    {isEdite ? (
                      <img
                        src={CheckIcon}
                        alt=""
                        className={s.saveNoteButton}
                        onClick={updateNote}
                      />
                    ) : (
                      <EditIcon
                        className={s.editIcon}
                        onClick={() => setIsEdite(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={s.emptyGreenBlock}></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {isEdite ? (
                  <Input
                    isVisible
                    required
                    inputValue={selectNote.title}
                    onChangeInput={(value) =>
                      setSelectNote((prev) => {
                        if (typeof prev === "object" && prev !== null) {
                          return { ...prev, title: value };
                        }
                        return prev;
                      })
                    }
                  />
                ) : (
                  <span className={s.noteTitle}>{selectNote.title} </span>
                )}
              </div>

              <div className={s.noteTextData}>
                <textarea
                  readOnly={!isEdite}
                  name=""
                  id=""
                  cols={30}
                  rows={10}
                  value={selectNote.description}
                  className={isEdite ? s.textAreaNewNote : s.textArea}
                  onChange={(event) =>
                    setSelectNote((prev) => {
                      if (typeof prev === "object" && prev !== null) {
                        return { ...prev, description: event.target.value };
                      }
                      return prev;
                    })
                  }
                ></textarea>
              </div>
            </div>
          )}
        </div>
      )}
      {/*     <ToastContainer /> */}
      <ModalWindow
        isOpen={newNoteModal}
        setIsOpen={setNewNoteModal}
        width="fit-content"
        position="right"
        padding="0"
      >
        <div className={s.newNoteBlock}>
          <div className={s.newNoteHeader}>
            <span>{strings.newNoteBtn}</span>
            <CloseIcon
              className={s.closeIcon}
              onClick={() => setNewNoteModal(false)}
            />
          </div>
          {/* <span className={s.newNoteTitle}>{strings.newNoteHeadTitle}</span> */}
          <div className={s.newNoteInputBlock}>
            <span>{strings.newNoteTitleLable}</span>
            <Input
              inputValue={newNote.title}
              onChangeInput={(value: any) =>
                setNewNote((prev) => ({ ...prev, title: value }))
              }
              isVisible
              required
              placeholder={strings.myNotePlaceHolder}
            />
          </div>
          <div className={s.newNoteInputBlock}>
            <span>{strings.newNoteDescriptionLable}</span>
            <textarea
              name=""
              id=""
              placeholder={strings.myDescPlaceholder}
              value={newNote.noteText}
              className={s.textAreaNewNote}
              onChange={(e) =>
                setNewNote((prev) => ({
                  ...prev,
                  noteText: e.target.value,
                }))
              }
            ></textarea>
          </div>
          <div className={s.createNoteBlock}>
            <StandartButton
              action={createNote}
              buttonTitle={strings.newNoteCreateBtn}
              width="200px"
              fontSize="20px"
              height="45px"
              disabled={newNote.noteText === "" || newNote.title === ""}
              tooltipTitle={
                newNote.noteText === "" || newNote.title === ""
                  ? strings.newNoteHeadTitle
                  : ""
              }
            />
          </div>
        </div>
      </ModalWindow>
    </div>
  );
};

export default Notes;
