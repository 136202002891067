import dayjs from "dayjs";
import strings from "../localization";

export const formatDateAndTime = (
  date: Date,
  locale: string
): { formattedDate: string; formattedTime: string } => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat(locale, optionsDate).format(
    date
  );
  const formattedTime = new Intl.DateTimeFormat(locale, optionsTime).format(
    date
  );

  return {
    formattedDate, //May 14, 2024
    formattedTime, //21:00
  };
};
export const formatDateTimeAgo = (date: Date, local: string): string => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const minutesAgo = Math.floor(diff / (1000 * 60));
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: now.getFullYear() !== date.getFullYear() ? "numeric" : undefined,
    hour: "numeric",
    minute: "numeric",
  };
  if (minutesAgo <= 0) return strings.justNow;
  if (minutesAgo < 15) {
    return `${minutesAgo} ${strings.minAgo}`;
  } else if (now.toDateString() !== date.toDateString()) {
    return date.toLocaleDateString(local, { day: "numeric", month: "short" });
  } else {
    return date.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "numeric",
    });
  }
};
export const daysUntilEnds = (endDate: Date): number => {
  const currentDate = new Date();
  const endSubscriptionDate = new Date(endDate);

  // Разница во времени в миллисекундах
  const timeDifference = endSubscriptionDate.getTime() - currentDate.getTime();

  // Переводим миллисекунды в дни
  const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysRemaining > 0 ? daysRemaining : 0;
};
export const convertDateForDaySlots = (dateString: string): string => {
  const currentYear = dayjs().year();
  const date = dayjs(dateString + `.${currentYear}`, "DD.MM.YYYY");
  return date.format("YYYY-MM-DD"); //2024-08-22
};
