import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as EditIcon } from "../../assets/Notes/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Notes/delete.svg";
import { ReactComponent as TripleDotIcon } from "../../assets/Notes/triple-dots.svg";
import s from "./NoteCard.module.css";
import NotesAPI from "../../api/notesApi";
import { Note } from "../../types/TypeNotes";
import {
  formatDateTimeWithOutTimezone,
  formateDate,
} from "../../utils/general";
import ArrowLinkIcon from "../../assets/Notes/link-arrow.svg";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import strings from "../../localization";
import { Tooltip } from "@mui/material";

type NoteCardProps = {
  title: string;
  noteId: string;
  date: string;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  noteData: Note;
  setNotesData: Dispatch<SetStateAction<Note[] | null>>;
  selectNote: Note | null;
  setSelectNote: Dispatch<SetStateAction<Note | null>>;
  isDashboardCard: boolean;
};

const NoteCard = ({
  title,
  noteId,
  date,
  setSelectNote,
  setIsEdit,
  setNotesData,
  noteData,
  selectNote,
  isDashboardCard,
}: NoteCardProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const noteActionMenuRef = useRef<HTMLDivElement | null>(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [actionMenuIsOpen, setActionMenuIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        noteActionMenuRef.current &&
        !noteActionMenuRef.current.contains(event.target as Node)
      ) {
        setActionMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [noteActionMenuRef]);

  const deleteNote = async (noteId: string) => {
    if (token) {
      const response = await NotesAPI.deleteNote(token, noteId);

      if (response.status) {
        setConfirmModalVisible(false);
        setSelectNote(null);
        setNotesData((prevNotes) => {
          if (!prevNotes) return prevNotes;
          return prevNotes.filter((note) => note._id !== noteId);
        });
      }
    }
  };

  const selectNoteHandler = () => {
    setSelectNote(noteData);
    setIsEdit(false);
  };

  const navigateToNotesPage = () => {
    navigate("/notes", {
      state: {
        noteId: noteId,
      },
    });
  };
  return (
    <div
      style={{ height: isDashboardCard ? "auto" : "180px" }}
      className={`${s.container} ${
        selectNote && selectNote._id === noteId ? s.selectedContainer : ""
      }`}
    >
      <div>
        <div className={s.headerBlock}>
          <div className={s.emptyGreenBlock}></div>{" "}
          {isDashboardCard ? (
            <div className={s.iconBlock}>
              <span
                className={s.navigateToNotesBtn}
                role="button"
                onClick={navigateToNotesPage}
              >
                <img src={ArrowLinkIcon} alt="" />
              </span>

              <span
                className={
                  isDashboardCard
                    ? s.dashboardDeleteButtonBlock
                    : s.deleteButtonBlock
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmModalVisible(true);
                }}
              >
                <DeleteIcon className={s.deletIcon} />
              </span>
            </div>
          ) : (
            <div className={s.iconBlock}>
              <span
                onClick={() => setActionMenuIsOpen((prev) => !prev)}
                className={s.actionMenuBtn}
              >
                <TripleDotIcon />
              </span>
              <div
                className={s.noteActionMenu}
                style={{ display: actionMenuIsOpen ? "flex" : "none" }}
                ref={noteActionMenuRef}
              >
                <span
                  className={s.editButtonBlock}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectNoteHandler();
                    setIsEdit(true);
                  }}
                >
                  <EditIcon className={s.editIcon} />
                  <span>{strings.editSocial}</span>
                </span>
                <span
                  className={s.deleteButtonBlock}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmModalVisible(true);
                  }}
                >
                  <DeleteIcon className={s.deletIcon} />
                  <span>{strings.deleteSocial}</span>
                </span>
              </div>
            </div>
          )}
        </div>
        <Tooltip title={title.length > 30 ? title : ""} arrow>
          <div className={s.headBlock}>
            {title.length > 30 ? title.slice(0, 30) + "..." : title}
          </div>
        </Tooltip>
        <div className={s.descriptionBlock}>{noteData.description}</div>
      </div>
      <div className={s.footerBlock}>
        <div className={s.dateText}>
          {
            //@ts-expect-error
            formatDateTimeWithOutTimezone(date).formattedTime +
              ", " +
              formateDate(new Date(date))
          }
        </div>
        <ConfirmationModal
          modalVisible={confirmModalVisible}
          setModalVisible={setConfirmModalVisible}
          confirmFunc={() => deleteNote(noteId)}
          confirmTitle={strings.confirmDeleteNoteTitle}
        />
      </div>
    </div>
  );
};

export default NoteCard;
