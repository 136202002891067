import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { NewNote, Note } from "../types/TypeNotes";
import { TypeUpdatedUserInfo } from "../types/TypeUsers";

const { notes } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "notes-api/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class NotesAPI {
  static async createNote(
    token: string,
    payload: { userId: string; newNote: NewNote }
  ): Promise<TypeDefaultResponse & { note: Note }> {
    return await ky
      .post(`${baseURL}${notes.createNote}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async updateNote(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { note: Note }> {
    return await ky
      .post(`${baseURL}${notes.update}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async deleteNote(
    token: string,
    noteId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${notes.deleteNote}/${noteId}`, {
        headers: getAuthHeader(token),
        json: { noteId },
      })
      .then((res) => res.json());
  }

  static async getUserNotes(
    token: string,
    userId: string,
    limit: number,
    page: number
  ): Promise<TypeDefaultResponse & { notes: Note[] }> {
    return await ky
      .get(`${baseURL}${notes.getUserNotes}/${userId}/${limit}/${page}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
