import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { Quiz, TypeNewQuiz, TypeQuizResult } from "../types/TypeQuieze";

const { quizes } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "quizes/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class QuizeApi {
  static async createQuize(
    newQuiz: TypeNewQuiz,
    token: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${quizes.createQuiz}`, {
        headers: getAuthHeader(token),
        json: { newQuiz },
      })
      .then((res) => res.json());
  }

  static async getQuizByTitle(
    title: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      quiz?: Quiz;
    }
  > {
    return await ky
      .get(`${baseURL}${quizes.getQuizByTitle}/${title}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async saveQuizResult(
    payload: TypeQuizResult
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${quizes.saveQuizResult}`, {
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getQuizResultByTitle(
    title: string,
    userId: string
  ): Promise<
    TypeDefaultResponse & {
      quizResult?: TypeQuizResult & { _id: string };
    }
  > {
    return await ky
      .get(`${baseURL}${quizes.getQuizResultByTitle}/${userId}/${title}`, {})
      .then((res) => res.json());
  }
}
