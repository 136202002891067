import { useContext, useEffect, useState } from "react";
import s from "./TreeQuiz.module.css";
import TreeQuizApi from "../../api/treeQuizesApi";
import { TreeQuizQuestion } from "../../types/TreeQuiz";
import AnswerElement from "./AnswerElement/AnswerElement";
import StandartButton from "../../components/StandartButton/StandartButton";
import StartImg from "../../assets/TreeQuiz/welcome.png";
import FinishImg from "../../assets/TreeQuiz/finish.png";
import strings from "../../localization";
import { TypeUserData } from "../../types/TypeUsers";
import SpecialistCard from "../../components/SpecialistCard/SpecialistCard";
import { ContextProvider } from "../../contextProvider";
import { OverLaps } from "../../types/TypeSpecialist";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import Replenish from "../../components/Replenish/Replenish";
import StripeProvider from "../../components/StripeProvider/StripeProvider";
import SubscriptionsAPI from "../../api/subscriptionsApi";
import Notify from "../../utils/toaster";
import { useLocation, useNavigate } from "react-router-dom";

const TreeQuiz = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeQuestion, setActiveQuestion] = useState<
    TreeQuizQuestion | undefined
  >();
  const [treeQuizId, setTreeQuizId] = useState<string | undefined>();
  const [answersResult, setAnswersResult] = useState<string[]>([]);
  const [isStartQuiz, setIsStartQuiz] = useState(false);
  const [recomendationSpecData, setRecomendationSpecData] = useState<
    TypeUserData[] | undefined
  >();
  const [recommendationPaggination, setRecommendationPaggination] = useState({
    page: 1,
    limit: 5,
    hasMore: false,
    laoder: false,
  });
  const [overLapsDays, setOverLapsDays] = useState<OverLaps[] | undefined>();
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionPayload, setSubscriptionPayload] = useState<
    | {
        subscriptionId: string;
        daysAmount: number;
        redirectUrl: string;
      }
    | undefined
  >();
  const [projectId, setProjectId] = useState<string | undefined>();
  const [actionLaoders, setActionLaoders] = useState({
    saveAnswerLoader: false,
  });

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !isStartQuiz) return;
      const response = await TreeQuizApi.getRecommendationQuiz(token);

      if (response.status && response.treeQuiz) {
        setTreeQuizId(response.treeQuiz._id);
        setActiveQuestion(response.treeQuiz.firstQuestion);
      }
    };
    makeAsync();
  }, [isStartQuiz]);

  const quizResult = async () => {
    if (!token || !treeQuizId) return;
    const response = await TreeQuizApi.getTreeQuizResult(token);
    if (response.status && response.treeQuizResult) {
      setProjectId(response.treeQuizResult.project);
    }

    const activeSubscriptionResponse =
      await SubscriptionsAPI.getMyActiveSubscription(token);
    if (
      response.treeQuizResult &&
      response.treeQuizResult.choosedSubscriptionDuration &&
      response.treeQuizResult.subscriptionId &&
      activeSubscriptionResponse.status
    ) {
      if (
        activeSubscriptionResponse.subscription &&
        activeSubscriptionResponse.subscription.subscription._id ===
          response.treeQuizResult.subscriptionId
      ) {
        return true;
      }
      if (
        activeSubscriptionResponse.subscription &&
        activeSubscriptionResponse.subscription.subscription._id !==
          response.treeQuizResult.subscriptionId
      ) {
        Notify("Unexpected error");
        navigate("home");
        return false;
      }

      setSubscriptionPayload({
        subscriptionId: response.treeQuizResult.subscriptionId,
        daysAmount: response.treeQuizResult.choosedSubscriptionDuration,
        redirectUrl:
          process.env.REACT_APP_BASE_URL + "tree-quiz?showRecomendetions=true",
      });
      setSubscriptionModal(true);
      return false;
    }
    return true;
  };

  const finishQuiz = async () => {
    const shouldRedirect = await quizResult();
    if (shouldRedirect) updateQueryParams();
    setActiveQuestion(undefined);
    setRecommendationPaggination({
      page: 1,
      limit: 5,
      hasMore: false,
      laoder: false,
    });
    setRecomendationSpecData(undefined);
    setAnswersResult([]);
  };

  const updateQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("showRecomendetions", "true");
    queryParams.delete("oldParam");
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const saveAnswerResult = async () => {
    if (!token || !activeQuestion) return;
    setActionLaoders((prev) => ({ ...prev, saveAnswerLoader: true }));
    const respnse = await TreeQuizApi.saveTreeQuizAnswerResult(
      token,
      answersResult,
      activeQuestion._id
    );
    setActionLaoders((prev) => ({ ...prev, saveAnswerLoader: false }));
    if (respnse.status && respnse.nextQuestion) {
      setActiveQuestion(respnse.nextQuestion);
      setAnswersResult([]);
    }

    if (!respnse.nextQuestion) {
      await finishQuiz();

      return;
    }
  };

  const getRecommendetionsFromQuiz = async () => {
    if (!token) return;
    setRecommendationPaggination((prev) => ({ ...prev, laoder: true }));
    const response = await TreeQuizApi.getRecommendationsFromTreeQuiz(
      token,
      recommendationPaggination.page,
      recommendationPaggination.limit
    );

    setRecommendationPaggination((prev) => ({ ...prev, laoder: false }));
    if (response.status && response.recommendations) {
      setRecommendationPaggination((prev) => ({
        ...prev,
        page: prev.page + 1,
        hasMore:
          response.recommendations!.length === recommendationPaggination.limit,
      }));
      setRecomendationSpecData((prev) =>
        prev
          ? [...prev, ...response.recommendations!]
          : response.recommendations
      );
      setOverLapsDays(response.overlapDays);
      setActiveQuestion(undefined);
    }
  };
  useEffect(() => {
    const checkProjectId = async () => {
      if (!token) return;
      const response = await TreeQuizApi.getTreeQuizResult(token);
      if (response.status && response.treeQuizResult) {
        setProjectId(response.treeQuizResult.project);
      }
    };
    if (location.search.includes("showRecomendetions=true")) {
      getRecommendetionsFromQuiz();
      checkProjectId();
      setIsStartQuiz(false);
    }
  }, [location]);

  return (
    <div className={s.container}>
      {!isStartQuiz && !location.search.includes("showRecomendetions=true") && (
        <div className={s.startBlock}>
          <img src={StartImg} alt="" className={s.startImg} />
          <h1 className={s.startTitle}>{strings.startQuizTitle}</h1>
          <span className={s.startDescription}>
            {strings.startQuizDescription}
          </span>
          <div className={s.buttonBlock}>
            <StandartButton
              action={() => setIsStartQuiz(true)}
              buttonTitle={strings.quizStartBtn}
              height="46px"
              width="100%"
            />
          </div>
        </div>
      )}
      {isStartQuiz && activeQuestion && (
        <div className={s.quizContent}>
          {activeQuestion && (
            <p className={s.questionTitle}>
              {
                activeQuestion.labels.find(
                  (el) => el.language === userData?.selectedLanguage
                )?.text
              }
            </p>
          )}
          <div className={s.answerList}>
            {activeQuestion &&
              activeQuestion.answers.map((answer: any) => (
                <AnswerElement
                  key={answer._id}
                  answer={answer}
                  isMultiSelect={activeQuestion.multiselect}
                  isSelectAnswer={answersResult.includes(answer._id)}
                  setAnswersResult={setAnswersResult}
                />
              ))}
          </div>
          <div className={s.buttonBlock}>
            <StandartButton
              action={saveAnswerResult}
              buttonTitle="Next"
              height="46px"
              width="100%"
              disabled={
                answersResult.length && !actionLaoders.saveAnswerLoader
                  ? false
                  : true
              }
            />
          </div>
        </div>
      )}
      {recomendationSpecData &&
        location.search.includes("showRecomendetions=true") && (
          <div className={s.recommendetionsContent}>
            <img src={FinishImg} alt="" className={s.finishImg} />
            <h1 className={s.startTitle}>{strings.great}</h1>
            <span className={s.startDescription}>{strings.specForYou}</span>
            <div className={s.specListBlock}>
              {recomendationSpecData.map((item, index) => (
                <div key={index} className={s.specCardBlock}>
                  <SpecialistCard
                    fullName={item.name + " " + item.surname}
                    languages={
                      item.preferredLanguages ? item.preferredLanguages : []
                    }
                    location={item.location ? item.location : ""}
                    mainSpeciality={
                      item.specialistId?.mainSpecializations[0]
                        ? item.specialistId?.mainSpecializations[0].labels.find(
                            (item) =>
                              item.language === userData?.selectedLanguage
                          )?.text!
                        : ""
                    }
                    price={
                      item.specialistId?.price ? item.specialistId?.price : 0
                    }
                    rating={item.specialistId?.rating}
                    userId={item._id}
                    yearExp={
                      item.specialistId?.yearsOfExperience
                        ? item.specialistId?.yearsOfExperience
                        : 0
                    }
                    realTimeSessions={
                      item.specialistId
                        ? item.specialistId.receiveRealTimeOffers
                        : false
                    }
                    discountPrice={item.specialistId!.discountPrice}
                    overlapDays={
                      overLapsDays
                        ? overLapsDays.filter(
                            (overlap) => overlap.specialist === item._id
                          )
                        : []
                    }
                    projectId={projectId}
                    isRecommendationCard={window.innerWidth >= 768}
                    avatarHeight="100%"
                    avatarBlockBorderRadius={
                      window.innerWidth >= 768 ? "20px 0 0 20px" : undefined
                    }
                    mainInfoBorderRadius={
                      window.innerWidth >= 768 ? "0 20px 20px 0" : undefined
                    }
                  />
                </div>
              ))}
              {recommendationPaggination.hasMore &&
              !recommendationPaggination.laoder ? (
                <div
                  className={s.showMoreBtn}
                  role="button"
                  onClick={getRecommendetionsFromQuiz}
                >
                  {strings.openMoreBtn}
                </div>
              ) : (
                recommendationPaggination.laoder && (
                  <div className={s.pagLoader}>
                    <Loader size={50} />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      <ModalWindow
        isOpen={subscriptionModal}
        setIsOpen={setSubscriptionModal}
        width="fit-content"
        maxWidth="fit-content"
        alternativeCloseFunction={() => navigate("/home")}
      >
        <div className={s.subscriptionModalContent}>
          <StripeProvider>
            <Replenish
              isPaymentSession
              hidePromocode
              subscriptionPayload={subscriptionPayload}
              setReplenishModal={setSubscriptionModal}
            />
          </StripeProvider>
        </div>
      </ModalWindow>
    </div>
  );
};
export default TreeQuiz;
