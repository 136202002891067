import React, { useContext, useEffect, useState } from "react";
import s from "./SelectRegMode.module.css";
import { useNavigate } from "react-router-dom";
import strings from "../../../localization";
import localizationJson from "../../../localization.json";
import { ContextProvider } from "../../../contextProvider";
import Logo from "../../../assets/logo.png";
import WelcomImg from "../../../assets/Auth/welcome.png";

const SelectRegMode = () => {
  const navigate = useNavigate();
  const { selectLang, setSelectLang } = useContext(ContextProvider);

  useEffect(() => {
    const browserLanguage = navigator.language.split("-")[0];
    const languageArray = Object.keys(localizationJson);

    if (languageArray.includes(browserLanguage)) {
      setSelectLang(browserLanguage);
    }

    setSelectLang((prev) => (prev ? prev : "en"));
  }, []);

  const changeLangHandler = async (value: string) => {
    strings.setLanguage(value);
    setSelectLang(value);
  };

  return (
    <div className={s.mainContainer}>
      <div className={s.headerBlock}>
        <div className={s.langSelectBlock}>
          <button
            onClick={() => changeLangHandler("en")}
            className={selectLang === "en" ? s.selectLang : s.unselectLang}
          >
            EN
          </button>
          <button
            onClick={() => changeLangHandler("uk")}
            className={selectLang === "uk" ? s.selectLang : s.unselectLang}
          >
            УКР
          </button>
        </div>
      </div>
      <div className={s.container}>
        <div className={s.welcomeBlock}>
          <img src={Logo} alt="" className={s.logo} />
          <h1 className={s.heading}>{strings.welcome}</h1>
          <p className={s.talziDescTitle}>{strings.talziDescription}</p>
        </div>
        <div className={s.roleContainer}>
          <div className={s.roleBlock}>
            <button
              className={s.roleButton}
              onClick={() => navigate("/login-partner")}
            >
              🌟 {strings.specModeBtn}
            </button>
            <p>{strings.specModeDescription}</p>
          </div>
          <div className={s.roleBlock}>
            <button className={s.roleButton} onClick={() => navigate("/login")}>
              🌱 {strings.clientModeBtn}
            </button>
            <p>{strings.clienModeDescription}</p>
          </div>
        </div>
      </div>
      <div className={s.footerBlock}></div>
      <img src={WelcomImg} alt="" className={s.welcomImg} />
    </div>
  );
};

export default SelectRegMode;
