import Compressor from "compressorjs";
export const compressFiles = (
  files: File[],
  setCompressedFiles: React.Dispatch<React.SetStateAction<File[]>>
): void => {
  const compressed: File[] = [];

  files.forEach((file) => {
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const compressedFile = new File([result], file.name, {
          type: result.type,
          lastModified: Date.now(),
        });

        compressed.push(compressedFile);

        if (compressed.length === files.length) {
          setCompressedFiles((prev) => [...prev, ...compressed]);
        }
      },
      error(err) {
        console.error(err.message);
      },
    });
  });
};
