import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./ClientProfileContent.module.css";
import { ContextProvider } from "../../../contextProvider";
import { formatDate } from "../../../utils/setupProfileInfo";

import {
  Roles,
  TypeUpdatedUserInfo,
  TypeUserData,
} from "../../../types/TypeUsers";

import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import countries from "i18n-iso-countries";
import strings from "../../../localization";

import EditClientProfile from "./EditProfile/EditClientProfile";
import SubscribeBtn from "../SubscribeBtn/SubscribeBtn";
import StandartButton from "../../../components/StandartButton/StandartButton";
import PublishedPosts from "../SpecialistProfileContent/PublishedPosts/PublishedPosts";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type ClientProfileContent = {
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  updateData: () => void;
  userUpdateData: TypeUpdatedUserInfo;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  profileUserData: TypeUserData | undefined | null;
  calendarIsConnected: boolean;
  setAvatar: Dispatch<SetStateAction<string | null>>;
  avatar: string | null;
  setAvatarFile: Dispatch<SetStateAction<File | undefined>>;
};

const ClientProfileContent = ({
  isEdit,
  userUpdateData,
  setUserUpdateData,
  profileUserData,
  calendarIsConnected,
  updateData,
  setIsEdit,
  setAvatar,
  avatar,
  setAvatarFile,
}: ClientProfileContent) => {
  const { userData } = useContext(ContextProvider);
  const [activeTab, setActiveTab] = useState<"general" | "posts">("general");

  return (
    <div className={s.container}>
      {isEdit ? (
        <EditClientProfile
          profileUserData={profileUserData}
          setUserUpdateData={setUserUpdateData}
          userUpdateData={userUpdateData}
          setAvatar={setAvatar}
          avatar={avatar}
          updateData={updateData}
          calendarIsConnected={calendarIsConnected}
          setAvatarFile={setAvatarFile}
        />
      ) : (
        <>
          <div className={s.headBlock}>
            <div className={s.headNameBlock}>
              <div className={s.mobileAvatarBlock}>
                <CircleAvatar
                  userId={profileUserData?._id || ""}
                  name={profileUserData?.name || ""}
                  surname={profileUserData?.surname || ""}
                  height="110px"
                  width="110px"
                  fontSize="52px"
                  marginRight="20px"
                  isModalAvatar
                />
              </div>
              <div className={s.nameBlock}>
                <span className={s.nameTitle}>
                  {profileUserData?.name} {profileUserData?.surname}
                </span>
                <span className={s.locationTitle}>
                  {profileUserData?.location}
                </span>
              </div>
            </div>
            <div className={s.bottomHeadBlock}>
              <div className={s.tabsBlock}>
                <div
                  className={
                    activeTab === "general" ? s.activeTab : s.notActiveTab
                  }
                  onClick={() => setActiveTab("general")}
                >
                  <span>{strings.generalInfo}</span>
                  <span
                    className={
                      activeTab === "general"
                        ? s.tabActiveIndicator
                        : s.indicatorHidden
                    }
                  ></span>
                </div>
                <div
                  className={
                    activeTab === "posts" ? s.activeTab : s.notActiveTab
                  }
                  onClick={() => setActiveTab("posts")}
                >
                  <span>{strings.publishePosts}</span>
                  <span
                    className={
                      activeTab === "posts"
                        ? s.tabActiveIndicator
                        : s.indicatorHidden
                    }
                  ></span>
                </div>
              </div>
              <div className={s.actionBlock}>
                {userData?._id === profileUserData?._id ||
                !profileUserData?._id ? (
                  <div className={s.myProfileActionBtn}>
                    <StandartButton
                      buttonTitle={strings.editProfileBtn}
                      action={() => setIsEdit(true)}
                      height="40px"
                    />
                  </div>
                ) : (
                  <SubscribeBtn userId={profileUserData?._id!} />
                )}
              </div>
            </div>
          </div>

          {activeTab === "general" ? (
            <div className={s.mainInfoBlock}>
              <div className={s.infoTitleBlock}>
                <span>{strings.profilePersonInfo}</span>
              </div>
              <div className={s.personInfoBlock}>
                <div className={s.personInfoLineBlock}>
                  <div className={s.personInfoElement}>
                    <span className={s.infoLable}>
                      {strings.firstNameProfileLable}
                    </span>

                    <span className={s.infoValue}>{profileUserData?.name}</span>
                  </div>
                  <div className={s.personInfoElement}>
                    <span className={s.infoLable}>
                      {strings.lastNameProfileLable}
                    </span>

                    <span className={s.infoValue}>
                      {profileUserData?.surname}
                    </span>
                  </div>
                </div>
              </div>
              <div className={s.personInfoBlock}>
                <div className={s.personInfoLineBlock}>
                  <div className={s.personInfoElement}>
                    <span className={s.infoLable}>
                      {strings.dateBirthProfileLable}
                    </span>

                    <span className={s.infoValue}>
                      {formatDate(
                        profileUserData?.dateOfBirth
                          ? new Date(profileUserData?.dateOfBirth)
                          : new Date()
                      )}
                    </span>
                  </div>
                  <div className={s.personInfoElement}>
                    <span className={s.infoLable}>
                      {strings.timezoneProfileLable}
                    </span>

                    <span className={s.infoValue}>
                      {profileUserData?.timezone}
                    </span>
                  </div>
                </div>
              </div>
              <div className={s.personInfoBlock}>
                <div className={s.personInfoLineBlock}>
                  <div className={s.personInfoElement}>
                    <span className={s.infoLable}>
                      {strings.fromProfileLable}
                    </span>

                    <span className={s.infoValue}>
                      {profileUserData?.location}
                    </span>
                  </div>
                  <div className={s.personInfoElement}>
                    <span className={s.infoLable}>
                      {strings.langProfileLable}
                    </span>

                    <div className={s.langBlock}>
                      {profileUserData &&
                        profileUserData.preferredLanguages &&
                        profileUserData?.preferredLanguages.map(
                          (item, index) => (
                            <span className={s.infoValue} key={index}>
                              {item}
                            </span>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PublishedPosts profileUserData={profileUserData} />
          )}
        </>
      )}
    </div>
  );
};

export default ClientProfileContent;
