import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import s from "./PaymentSession.module.css";
import Replenish from "../Replenish/Replenish";
import strings from "../../localization";
import StandartButton from "../StandartButton/StandartButton";
import StripeProvider from "../StripeProvider/StripeProvider";
import { PaymentFor } from "../../types/Balance";

type PaymentSessionProps = {
  hidePromocode?: boolean;
  paymentFor?: PaymentFor;
  setReplenishModal: Dispatch<SetStateAction<boolean>>;
  setPrevModalVisible?: Dispatch<SetStateAction<boolean>>;
  subscriptionPayload?: {
    subscriptionId: string;
    daysAmount: number;
  };
};

const PaymentSession = ({
  hidePromocode,
  paymentFor,
  setReplenishModal,
  setPrevModalVisible,
  subscriptionPayload,
}: PaymentSessionProps) => {
  const [displayContentStep, setDisplayContentStep] = useState<
    "chooseAction" | "replenish" | "payment"
  >("chooseAction");

  useEffect(() => {
    if (!subscriptionPayload) return;
    setDisplayContentStep("payment");
  }, [subscriptionPayload]);

  return (
    <div className={s.container}>
      {displayContentStep === "chooseAction" ? (
        <div className={s.shooseActionBlock}>
          <h2>{strings.enoughBalanceModalTitle}</h2>
          <span className={s.descriptionText}>
            {strings.enoughBalanceModalDescription}
          </span>
          <div className={s.btnBlock}>
            <StandartButton
              action={() => setDisplayContentStep("replenish")}
              buttonTitle={strings.topUpBalanceBtn}
              width="50%"
              height="40px"
            />
            <StandartButton
              action={() => setDisplayContentStep("payment")}
              buttonTitle={strings.payWithCardBtn}
              width="50%"
              height="40px"
            />
          </div>
        </div>
      ) : (
        <div className={s.cardBlock}>
          <StripeProvider>
            <Replenish
              isPaymentSession={displayContentStep === "payment"}
              hidePromocode={hidePromocode}
              paymentFor={paymentFor}
              setPrevModalVisible={setPrevModalVisible}
              setReplenishModal={setReplenishModal}
              subscriptionPayload={subscriptionPayload}
            />
          </StripeProvider>
        </div>
      )}
    </div>
  );
};

export default PaymentSession;
