import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { ContextProvider } from "../../contextProvider";
import UsersApi from "../../api/usersApi";
import s from "./Profile.module.css";
import SpecialistProfileContent from "./SpecialistProfileContent/SpecialistProfileContent";
import {
  Roles,
  TypeUpdatedUserInfo,
  TypeUserData,
} from "../../types/TypeUsers";
import SpecialistApi from "../../api/specialistApi";
import {
  addSpecialistCertificates,
  updateSpecialistIntroVideo,
  updateUserAvatar,
} from "../../utils/setupProfileInfo";
import ClientProfileContent from "./ClientProfileContent/ClientProfileContent";
import { SpecialistData } from "../../types/TypeSpecialist";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import Notify, { SuccesNotify } from "../../utils/toaster";
import { ToastContainer } from "react-toastify";
import NotificationsApi from "../../api/notifications";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import SelectSessionDate from "../../components/SelectSessionDate/SelectSessionDate";
import { areUserDataEqual, createChat } from "../../utils/general";
import SessionsApi from "../../api/sessionApi";
import strings from "../../localization";
import { useDispatch } from "react-redux";
import { setHeaderAvatar } from "../../redux-toolkit/slices/userSlice";
import { AppDispatch } from "../../redux-toolkit/store";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const token = localStorage.getItem("token");
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { userData, setUserData } = useContext(ContextProvider);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [avatarFile, setAvatarFile] = useState<File>();
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isUpdateLoader, setIsUpdateLoader] = useState(false);
  const [calendarIsConnected, setCalendarIsConnected] = useState(false);
  const [profileUserData, setProfileUserData] = useState<
    (TypeUserData & { specialistId?: SpecialistData }) | undefined | null
  >();
  const [specialistUpdateData, setSpecialistUpdateData] =
    useState<TypeUpdatedUserInfo>({
      _id:
        userData?.specialistId && userData?.specialistId?._id
          ? userData?.specialistId?._id
          : "",
    });
  const [newCertificates, setNewCertificates] = useState<File[]>([]);
  const [video, setVideo] = useState<File | null>(null);
  const [profileUserId, setProfileUserId] = useState<string | null>(null);
  const [userUpdateData, setUserUpdateData] = useState<TypeUpdatedUserInfo>({
    _id: userData?._id ? userData?._id : "",
  });
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  const [overlapTime, setOverlapTime] = useState<{ start: Date; end: Date }[]>(
    []
  );
  const [realTimeSessionsChecked, setRealTimeSessionsChecked] = useState<
    boolean | null
  >(true);

  const [isSpecialistProfile, setIsSpecialistProfile] = useState(false);

  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const userName = pathParts[pathParts.length - 1];

  const updateData = async () => {
    setIsUpdateLoader(true);
    const specialistId = userData?.specialistId?._id;
    let responseSpecUpdateStatus = false;
    let responseUserUpdateStatus = false;
    const keysSpecialistDataUpdate = Object.keys(specialistUpdateData);

    if (newCertificates.length && token && specialistId) {
      const response = await addSpecialistCertificates(
        newCertificates,
        specialistId,
        token
      );
      if (!response.status && response.message) {
        setIsUpdateLoader(false);
        return Notify(response.message);
      }
      setNewCertificates([]);
    }
    if (video && token && specialistId) {
      await updateSpecialistIntroVideo(video, specialistId, token);
    }

    if (token && specialistId && keysSpecialistDataUpdate.length > 1) {
      const responseSpecUpdate = await SpecialistApi.update(
        specialistUpdateData,
        token
      );

      //@ts-expect-error
      setProfileUserData((prev) => ({
        ...prev,
        specialistId: responseSpecUpdate.specialistData,
      }));

      responseSpecUpdateStatus = responseSpecUpdate.status;
      const updatedObject = userData;
      Object.keys(responseSpecUpdate.specialistData).forEach((key) => {
        if (updatedObject.specialistId)
          //@ts-expect-error
          updatedObject.specialistId[key] =
            //@ts-expect-error
            responseSpecUpdate.specialistData[key];
      });
      setUserData(updatedObject);
    }

    const keysUserDataUpdate = Object.keys(userUpdateData);
    if (token && userData && userData._id && keysUserDataUpdate.length > 1) {
      const updatedObject = userData;
      Object.keys(userUpdateData).forEach((key) => {
        //@ts-expect-error
        updatedObject[key] = userUpdateData[key];
      });

      setUserData(updatedObject);

      const responseUserUpdate = await UsersApi.updateUserData(token, {
        ...userUpdateData,
        avatar: undefined,
      });
      responseUserUpdateStatus = responseUserUpdate.status;

      if (avatarFile) {
        const avatarUpdateRes = await updateUserAvatar(
          avatarFile,
          userData._id,
          token
        );
        if (avatarUpdateRes && avatarUpdateRes.status)
          dispatch(setHeaderAvatar(userUpdateData.avatar));
      }
    }

    if (responseUserUpdateStatus || responseSpecUpdateStatus) {
      setUserUpdateData({
        _id: userData?._id ? userData?._id : "",
      });
      SuccesNotify(strings.profileHasUpdate);
    }
    setIsEditProfile(false);
    setIsUpdateLoader(false);
  };
  useEffect(() => {
    if (JSON.stringify(avatar) === JSON.stringify(userData?.avatar)) return;
    if (avatar) setUserUpdateData((prev) => ({ ...prev, avatar: avatar }));
  }, [avatar]);

  useEffect(() => {
    if (userData && userData.specialistId) {
      setRealTimeSessionsChecked(userData.specialistId?.receiveRealTimeOffers);
    }
    /*   if (!userName || !userName.length) {
      setIsLoader(false);
      navigate("/home");
    } */
    if (token && userName && userData) {
      const makeAsync = async () => {
        setIsLoader(true);

        const checkCalendarConnectResponse =
          await NotificationsApi.checkGoogleRefreshToken(token);

        setCalendarIsConnected(checkCalendarConnectResponse.status);

        const userDataPublicResponse = await UsersApi.getUserPublicInfo(
          userName,
          userData.selectedLanguage,
          token
        );

        const userDataResponse = await UsersApi.getUserProfileInfoById(
          token,
          userData._id
        );

        const availableUserData =
          userName === userData.username
            ? userDataResponse.user
            : userDataPublicResponse.user;
        if (!userDataResponse.status || !userDataPublicResponse.status) {
          navigate("/home");
        }

        if (userDataResponse.status && availableUserData) {
          setProfileUserId(availableUserData._id);
          /*   setIsSpecialist(availableUserData.specialistId ? true : false); */
          setProfileUserData(availableUserData);
          const profileSpec =
            userName === userData.username
              ? isSpecialist
              : availableUserData.specialistId
              ? true
              : false;
          setIsSpecialistProfile(profileSpec);
        }
        setIsLoader(false);
      };
      makeAsync();
    }
  }, [userData, isUpdateLoader, userName]);

  const bookingHandler = async (
    sessionBookingData: { date: Date | string; duration: number }[]
  ) => {
    if (
      token &&
      userData &&
      userData._id &&
      profileUserData &&
      profileUserData._id &&
      sessionBookingData.length
    ) {
      const response = await SessionsApi.bookSession(
        {
          datesAndTime: sessionBookingData,
          specialistUserId: profileUserData._id,
          userId: userData._id,
        },
        token
      );
      if (!response.status && !response.notEnoughBalance) {
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (!response.status && response.notEnoughBalance && response.message) {
        Notify(response.message);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (response.status && profileUserData) {
        createChat(userData, profileUserData?._id);
        setIsLoader(false);
        setBookingModalIsOpen(false);
        SuccesNotify(strings.bookingSucces);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
    }
    return { status: false, notEnoughBalance: false };
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !profileUserId || !profileUserData) return;
      const avatarResponse = await UsersApi.getUserAvatarAndUsername(
        token,
        profileUserId
      );
      if (avatarResponse.avatar) {
        setAvatar(avatarResponse.avatar);
      }
      if (!profileUserData.specialistId) return;
      const response = await SpecialistApi.getSpecialistFreeTime(
        token,
        profileUserId
      );

      if (response.status) {
        const allOverlaps: { start: Date; end: Date }[] =
          response.overlapDays.flatMap((overlap) =>
            overlap.overlaps.flatMap((dayOverlap) => dayOverlap.overlapTime)
          );

        setOverlapTime(allOverlaps);
      }
    };
    makeAsync();
  }, [profileUserId, bookingModalIsOpen]);

  if (isLoader || isUpdateLoader)
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );

  return (
    <div
      className={s.container}
      /*   style={{ backgroundColor: isEditProfile ? "transparent" : "white" }} */
    >
      <div className={s.mainContentBlock} style={{ width: "100%" }}>
        {isSpecialistProfile ? (
          <SpecialistProfileContent
            isEdit={isEditProfile}
            setNewCertificates={setNewCertificates}
            setSpecialistUpdateData={setSpecialistUpdateData}
            specialistUpdateData={specialistUpdateData}
            profileUserData={profileUserData}
            specialistData={profileUserData?.specialistId}
            setUserUpdateData={setUserUpdateData}
            userUpdateData={userUpdateData}
            setVideo={setVideo}
            video={video}
            calendarIsConnected={calendarIsConnected}
            setIsEdit={setIsEditProfile}
            updateData={updateData}
            setBookingModalIsOpen={setBookingModalIsOpen}
            realTimeSessionsChecked={realTimeSessionsChecked}
            setRealTimeSessionsChecked={setRealTimeSessionsChecked}
            setAvatar={setAvatar}
            avatar={avatar}
            setAvatarFile={setAvatarFile}
            newCertificates={newCertificates}
            isUpdateLoader={isUpdateLoader}
          />
        ) : (
          <ClientProfileContent
            isEdit={isEditProfile}
            setUserUpdateData={setUserUpdateData}
            userUpdateData={userUpdateData}
            profileUserData={profileUserData}
            calendarIsConnected={calendarIsConnected}
            setIsEdit={setIsEditProfile}
            updateData={updateData}
            setAvatar={setAvatar}
            avatar={avatar}
            setAvatarFile={setAvatarFile}
          />
        )}
      </div>

      <ModalWindow
        isOpen={bookingModalIsOpen}
        setIsOpen={setBookingModalIsOpen}
        width="fit-content"
        bgColor={overlapTime.length ? "White" : "transparent"}
      >
        {overlapTime.length ? (
          <SelectSessionDate
            specialization={
              profileUserData &&
              profileUserData.specialistId &&
              Array.isArray(profileUserData.specialistId.mainSpecializations) &&
              profileUserData.specialistId.mainSpecializations.length > 0
                ? profileUserData.specialistId.mainSpecializations[0]
                    .specialization
                : ""
            }
            showDateInputs={false}
            bookingHandler={bookingHandler}
            overlapTime={overlapTime}
            userId={profileUserData ? profileUserData._id : ""}
            fullname={profileUserData?.name + " " + profileUserData?.surname}
            price={
              profileUserData && profileUserData.specialistId
                ? profileUserData.specialistId.price! * 100
                : 0
            }
            setBookingModal={setBookingModalIsOpen}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <CircularProgress size={150} color="primary" />
          </ThemeProvider>
        )}
      </ModalWindow>
      {/*  <ToastContainer /> */}
    </div>
  );
};

export default Profile;
